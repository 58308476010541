import { useState, useEffect } from 'react';
import { Grid, Card, Checkbox, Typography } from '@mui/material';
import { TextInput, Button, Label } from 'app/components';
import styles from './login.module.scss';
import { useNavigate } from 'react-router-dom';
import { SIGN_UP } from 'app/libs/constants/tab-routes';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from '../slice/auth.slice';
import { selectErrors } from '../slice/auth.selector';

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actions = useAuthSlice().actions;
  const errors = useSelector(selectErrors);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    return () => {
      dispatch(actions.updateErrors({}));
    };
  }, []);
  const onLogin = () => {
    dispatch(actions.postLogin({ email, password, navigate }));
  };
  return (
    <div className={styles['main-container']}>
      <Card sx={{ display: 'flex' }} style={{ width: '1167px' }}>
        <Grid container>
          <Grid item md={6}>
            <div className={styles['container']}>
              <Label center style={{ marginBottom: '20px' }} isHeading>
                Login
              </Label>
              <TextInput
                type={'email'}
                label={'Login Id'}
                placeholder='Enter Login Id'
                errorMessage={errors?.email || ''}
                error={!!errors?.email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                type={showPassword ? 'text' : 'password'}
                label={'Password'}
                iconName={`visibility${showPassword ? '_off' : ''}`}
                placeholder='Enter Password'
                onIconClick={() =>
                  setShowPassword((showPassword) => !showPassword)
                }
                errorMessage={errors?.password || ''}
                error={!!errors?.password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className={styles['checkbox-container']}>
                <Checkbox
                  checked={isChecked}
                  style={{ color: '#FF9801' }}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                  }}
                />
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  component='div'
                  mt={1}
                  style={{
                    flex: 1,
                    fontFamily: 'Roboto Condensed',
                    fontSize: '16px',
                  }}>
                  Remember me
                </Typography>
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  component='div'
                  mt={1}
                  onClick={() => navigate('/forgot-password')}
                  style={{
                    color: '#FF9801',
                    cursor: 'pointer',
                    fontFamily: 'Roboto Condensed',
                    fontSize: '16px',
                  }}>
                  Forgot password?
                </Typography>
              </div>
              <Button fullWidth onClick={onLogin}>
                Login Now
              </Button>
              <Typography
                variant='subtitle1'
                gutterBottom
                component='div'
                mt={2}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: '16px',
                }}>
                Not a registered user?{' '}
                <span
                  style={{ color: '#FF9801', cursor: 'pointer' }}
                  onClick={() => navigate(SIGN_UP)}>
                  Sign Up
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={styles['left-container']}>
              <div className={styles['image-overlay']}></div>
              <section className={styles['text-content']}>
                <h3>UrjaCity</h3>
                <p>
                  The passage experienced a surge in popularity during the 1960s
                  when Letraset used it on their dry-transfer sheets, and again
                  during the 90s as desktop publishers bundled
                </p>
              </section>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Login;
