import * as React from 'react';
import { ClickAwayListener } from '@mui/material';
import styles from './auto-complete-text.module.scss';
import { TextError, Label } from '../';
import Delete from 'app/libs/assests/delete.svg';
import { useState } from 'react';

export function AutoCompleteText({
  options = [],
  label,
  errorMessage,
  error = false,
  placeholder = 'Please enter the field',
  iconName,
  onIconClick,
  disabled = false,
  Component,
  required = false,
  onDelete,
  subContent,
  itemValue,
  itemName,
  onSelect,
  value,
  onChange,
  onBlur,
  onFocus,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const Item = ({ option }) => {
    const id = option?.[itemValue] || option;
    const name = option?.[itemName] || option;
    return (
      <li
        onClick={() => {
          console.log(id, name);
          onSelect?.({ value: name, id });
          setOpen(false);
        }}
        data-value={id}
        data-display={value}
        className={`${styles['option']}`}>
        {name}
      </li>
    );
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) setOpen(false);
      }}>
      <div className={`${styles['container']}`}>
        {label && (
          <Label textlabel subContent={subContent}>
            {label}
            {required && <span>*</span>}
          </Label>
        )}

        <div
          className={`${styles['input-container']}  ${
            disabled ? styles['container-disabled'] : ''
          }`}>
          <input
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e);
              if (!open) setOpen(true);
            }}
            value={value}
            disabled={disabled}
            onFocus={() => {
              setOpen(true);
              onFocus?.();
            }}
            onBlur={onBlur}
            {...props}
          />
          {iconName && (
            <i className='material-icons' onClick={onIconClick}>
              {iconName}
            </i>
          )}
          {Component && <Component />}
          {onDelete && !disabled && (
            <div
              className={styles['delete-icon']}
              onClick={() => {
                onDelete?.();
              }}>
              <img src={Delete} alt='delete' />
            </div>
          )}
          {open && value.length > 4 && options.length !== 0 && (
            <ul className={styles['list']}>
              {options.map((option, index) => {
                const key = option?.[itemValue] || option;
                return <Item option={option} key={key} />;
              })}
            </ul>
          )}
        </div>

        <TextError errorMessage={errorMessage} error={error} />
      </div>
    </ClickAwayListener>
  );
}
