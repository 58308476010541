import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../models';
import { useInjectReducer } from 'redux-injectors';

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    loadSnackbar(state, action) {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.open = true;
    },
    loadedSnackbar(state) {
      state.open = false;
    },
  },
});
export const { actions: snackbarActions, reducer } = slice;

export const useSnackbarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
