import React from 'react';
import { Snackbar } from '@mui/material';
import { useSnackbarSlice } from './slice/feature-snackbar.slice';
import {
  selectMessage,
  selectOpen,
  selectType,
} from './slice/feature-snackbar.selector';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const FeatureSnackbar = () => {
  const dispatch = useDispatch();
  const actions = useSnackbarSlice().actions;
  const message = useSelector(selectMessage);
  const open = useSelector(selectOpen);
  const type = useSelector(selectType);
  const handleClose = () => {
    dispatch(actions.loadedSnackbar());
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
