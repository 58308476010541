import styles from './directory-card.module.scss';
import { ReactComponent as StarIcon } from 'app/libs/assests/star-icon-empty.svg';
import { ReactComponent as LocationIcon } from 'app/libs/assests/location-icon.svg';
import { ReactComponent as UserEdit } from 'app/libs/assests/user-edit.svg';
import { ReactComponent as UserSearch } from 'app/libs/assests/user-search.svg';
import { ReactComponent as SettingIcon } from 'app/libs/assests/setting-icon.svg';
import { ReactComponent as ProductIcon } from 'app/libs/assests/product-icon.svg';
import { ReactComponent as SaleTag } from 'app/libs/assests/sale-tag-icon.svg';

export const DirectoryCard = () => {
  const iconList = [
    { Icon: UserSearch, count: 3 },
    { Icon: SaleTag, count: 2 },
    { Icon: SettingIcon, count: 4 },
    { Icon: ProductIcon, count: 5 },
    { Icon: UserEdit, count: 3 },
  ];
  return (
    <div className={styles['main-container']}>
      <section className={styles['header-container']}>
        <span className={styles['directory-type']} data-directory={'Business'}>
          Business
        </span>
        <aside>
          <span className={styles['rating-container']}>
            <StarIcon className={styles['star-icon']} />
            5.0
          </span>
          <span>1.2 Kms Away</span>
          <span className={styles['map-content']}>
            <LocationIcon className={styles['location-icon']} />
            Open in Map
          </span>
        </aside>
      </section>
      <h3 className={styles['heading']}>Organic Mandya Farmers Market</h3>
      <p className={styles['sub-content']}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.
      </p>
      <section className={styles['icon-container']}>
        {iconList.map((icon) => {
          return (
            <div className={styles['icon']}>
              <icon.Icon />
              <span>{icon.count}</span>
            </div>
          );
        })}
      </section>
    </div>
  );
};
