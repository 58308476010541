import styles from './enquiry-modal.module.scss';
import { Modal, TextArea, Button, TextInput } from 'app/components';

export const EnquiryModal = ({ open, openModal }) => {
  return (
    <Modal
      label={'Enquiry'}
      open={open}
      handleClose={() => {
        openModal((prev) => !prev);
      }}>
      <div className={styles['enquiry-to']}>
        <span>To</span> <span>Organic Mandya Farmers Market</span>
      </div>
      <TextInput label={'Subject'} />
      <TextArea label={'Message'} />
      <div className={styles['btn-container']}>
        <Button
          btnType='secondary'
          onClick={() => {
            openModal(false);
          }}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </div>
    </Modal>
  );
};
