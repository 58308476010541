import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../models';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { addProductFromSaga } from './product.saga';

const slice = createSlice({
  name: 'addProduct',
  initialState,
  reducers: {
    addProduct(state, action) {
      state.error = {};
    },
    updateErrors(state, action) {
      state.error = action.payload;
    },
  },
});
export const { actions: addProductActions, reducer } = slice;

export const useAddProductSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addProductFromSaga });
  return { actions: slice.actions };
};
