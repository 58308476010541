import { useState } from 'react';
import styles from './image-carousel.module.scss';

export const ImageCarousel = ({ images = [], ...props }) => {
  const [selectedImage, setImage] = useState(images[0]);
  return (
    <div className={styles['container']}>
      {selectedImage ? (
        <img
          src={selectedImage}
          className={styles['selected-image']}
          alt='activeImage'
        />
      ) : (
        <div
          className={styles['selected-image']}
          style={{
            background: 'rgb(38, 144, 70)',
          }}></div>
      )}
      <section className={styles['image-container']}>
        {/* <span>&lt;</span> */}
        {images.map((image) => {
          return (
            <img
              src={image}
              alt={`${image}`}
              onClick={() => {
                setImage(image);
              }}
            />
          );
        })}
        {/* <span>&gt;</span> */}
      </section>
    </div>
  );
};
