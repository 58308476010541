import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../model';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { addServiceFromSaga } from './add-service.saga';

const slice = createSlice({
  name: 'addService',
  initialState,
  reducers: {
    loadSubcategory(state, action) {},
    loadedSubcategory(state, action) {
      state.directoryNames = action.payload.name;
      state.directories = action.payload.directory;
    },
    postDirectory(state, action) {},
    addServices(state, action) {},
    loadCategories(state, action) {},
    loadedCategories(state, action) {
      state.directories = [action.payload];
    },
    updateErrors(state, action) {
      state.error = action.payload;
    },
  },
});
export const { actions: addServiceActions, reducer } = slice;

export const useAddServiceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addServiceFromSaga });
  return { actions: slice.actions };
};
