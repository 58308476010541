import { useState } from 'react';
import styles from './article.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  RichTextEditor,
  CardHeader,
  ImageUpload,
  StepperList,
} from 'app/components';
export const ManageArticle = ({ disabled, label }) => {
  const [files, setFiles] = useState([]);
  const [services, setServices] = useState([1]);
  const [isDisabled, setIsDisabled] = useState(true);
  const list = ['Services', 'Environmetal', 'climate change', 'outdoor-plant'];
  const onDelete = (index) => {
    setFiles((prev) => {
      const newFiles = [
        ...[...prev].slice(0, index),
        ...[...prev].slice(index + 1),
      ];
      return newFiles;
    });
  };
  const AddMore = ({ addMore, label }) => {
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          addMore((prev) => {
            return [...prev, prev.length + 1];
          });
        }}>
        + {label}
      </p>
    );
  };
  return (
    <div>
      <CardHeader
        heading={'Manage Article'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <div className={styles['message-container']}>
            <Button btnType='green'>Approved</Button>
            <div className={styles['status-style']}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's
            </div>
          </div>
          <div className={styles['stepper-container']}>
            <StepperList list={list} />
          </div>

          <section style={{ paddingRight: isDisabled ? '0' : '45px' }}>
            <TextInput
              label={'Title'}
              disabled={isDisabled}
              subContent='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              placeholder='Here can be Article Name'></TextInput>
            <RichTextEditor
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              label={'Content'}
              disabled={isDisabled}
              placeholder='Here can be Service Name'></RichTextEditor>
            <RichTextEditor
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              label={'Expert'}
              disabled={isDisabled}
              value={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}
              placeholder='Enter Specifications'
            />

            <ImageUpload
              label={'Media'}
              onDelete={onDelete}
              imageList={files}
              disabled={isDisabled}
              hideUpload={files.length >= 1}
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const file = e.target.files[0];
                  console.log(file, JSON.stringify(file));
                  setFiles((prev) => [...prev, file]);
                }
              }}
            />
            <section>
              <TextInput
                disabled={isDisabled}
                label={'Search Tags'}
                placeholder='Here can be Search Tag'
              />
              {services.map((el, index) => {
                return (
                  <TextInput
                    disabled={isDisabled}
                    placeholder='Here can be Search Tag'
                    onDelete={() => {}}
                  />
                );
              })}
              {!isDisabled && services.length < 2 && (
                <AddMore label={'Add More'} addMore={setServices} />
              )}
            </section>
            <TextInput
              label={'Transfer To'}
              disabled={isDisabled}
              placeholder='Here can be Transfer To'
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
            />
          </section>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            onClick={() => {
              setIsDisabled((prev) => !prev);
            }}
            Component={EditIcon}
            style={{ marginRight: '14px' }}>
            Edit
          </Button>
          <Button
            btnType='secondary'
            Component={DeleteIcon}
            style={{ marginRight: '14px' }}>
            Delete
          </Button>
          <Button btnType='secondary' Component={ReviewIcon}>
            Submit For Review
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default ManageArticle;
