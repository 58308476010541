import styles from './label.module.scss';
import { ReactComponent as InfoIcon } from 'app/libs/assests/info.svg';

export const Label = ({
  children,
  center,
  textlabel = false,
  noMargin,
  subContent,
  isHeading,
  ...props
}) => {
  return (
    <section
      className={styles['label-style']}
      center={`${center}`}
      textlabel={`${textlabel}`}
      data-heading={`${isHeading}`}
      {...props}>
      <span className={styles['main-label']}>{children}</span>
      {subContent && (
        <section>
          <InfoIcon />
        </section>
      )}
      {subContent && (
        <span className={styles['sub-content']}>{subContent}</span>
      )}
    </section>
  );
};
