import {
  ActivitiesCard,
  ProductHeader,
  InitiativeCard,
  AnnouncementCard,
  DescriptionCard,
  ServiceProductCard,
  Button,
  CarouselContainer,
  Modal,
  ImageUpload,
} from 'app/components';
import { useState, useRef } from 'react';
import garden from 'app/libs/assests/gardening-pic.png';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './directory.module.scss';
import {
  ADD_ACTIVITY,
  ADD_PRODUCT,
  ADD_INITIATIVE,
  ADD_SERVICE,
  ADD_ANNOUNCEMENT,
  ADD_SERVICE_MAIN,
} from 'app/libs/constants/tab-routes';
import {
  DirectoryBanner,
  ProductModal,
  EnquiryModal,
  LocationModal,
} from './component';
import herdBasket from 'app/libs/assests/herb-basket.jpg';
import nicheproducts from 'app/libs/assests/niche-products.png';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { Grid } from '@mui/material';
import { ManageInfoContainer } from '../basic-information/components/manage-info-container';
import { ManageServiceForm } from '../manage-service/components/manage-service-form';
import { ManageProductForm } from '../product/component';
import { ManageActivityForm } from '../activity/components';
import { ManageInitiativeForm } from '../initiative/components';
import { ManageAnnouncementForm } from '../manage-announcement/components';
import { useEffect } from 'react';
import { useDirectorySlice } from './slice/directory.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDirectoryController,
  selectDirectoryBasic,
  selectDirectoryImage,
  selectDirectoryImageController,
  selectDirectoryServices,
  selectDirectoryProducts,
} from './slice/directory.selector';

export const Directory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { directory_id } = useParams();
  const directoryController = useSelector(selectDirectoryController);
  const directoryBasic = useSelector(selectDirectoryBasic);
  const directoryImage = useSelector(selectDirectoryImage);
  const directoryImageController = useSelector(selectDirectoryImageController);
  const directoryServices = useSelector(selectDirectoryServices);
  const directoryProducts = useSelector(selectDirectoryProducts);

  const actions = useDirectorySlice().actions;
  const dummyData = [1, 2, 3];
  const dummyData1 = [1, 2, 3];
  const productsList = [
    { imageSrc: nicheproducts, name: 'Niche Products' },
    { imageSrc: herdBasket, name: 'Fruit Basket' },
    { imageSrc: nicheproducts, name: 'Niche Products' },
    { imageSrc: herdBasket, name: 'Herbs Basket' },
    { imageSrc: nicheproducts, name: 'Fruit Basket' },
    { imageSrc: herdBasket, name: 'Niche Products' },
  ];
  const list = ['Services', 'Environmetal', 'climate change', 'outdoor-plant'];
  const [productModal, setProductModal] = useState(false);
  const [enquiryModal, setEnquiryModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [directoryModal, setDirectoryModal] = useState(false);
  const [manageServices, setManageServices] = useState(false);
  const [productServices, setProductServices] = useState(false);
  const [activityServices, setActivityServices] = useState(false);
  const [initiativeServices, setInitiativeServices] = useState(false);
  const [announcementServices, setAnnouncementServices] = useState(false);
  const [BannerModal, setBannerModal] = useState(false);
  const [showSingle, setShowSingle] = useState(false);
  const [selectedProduct, setSelectedproduct] = useState([]);

  const descriptionRef = useRef(null);
  const serviceRef = useRef(null);
  const productRef = useRef(null);
  const activitieRef = useRef(null);
  const articleRef = useRef(null);
  useEffect(() => {
    dispatch(actions.loadDirectoryBasic(directory_id));
  }, [actions, directory_id, dispatch]);

  const navigateToService = (path) => {
    navigate(`${ADD_SERVICE}?directory_id=${directory_id}&path=${path}`);
  };

  if (Object.keys(directoryBasic || {}).length === 0) return <></>;

  return (
    <div className={styles['container']}>
      <Modal
        label={'Manage Directory'}
        open={directoryModal}
        handleClose={() => {
          setDirectoryModal((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageInfoContainer />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageInfoContainer />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Manage Service'}
        open={manageServices}
        handleClose={() => {
          setManageServices((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageServiceForm />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageServiceForm />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Product Service'}
        open={productServices}
        handleClose={() => {
          setProductServices((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageProductForm />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageProductForm />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Activity'}
        open={activityServices}
        handleClose={() => {
          setActivityServices((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageActivityForm />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageActivityForm />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Initiative Services'}
        open={initiativeServices}
        handleClose={() => {
          setInitiativeServices((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageInitiativeForm />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageInitiativeForm />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Announcement Services'}
        open={announcementServices}
        handleClose={() => {
          setAnnouncementServices((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <Button
          onClick={() => setShowSingle((prev) => !prev)}
          style={{ margin: '10px 0' }}>
          Show {!showSingle ? 'Single' : 'double'} Form
        </Button>
        <Grid container>
          <Grid item md={showSingle ? 12 : 6}>
            <ManageAnnouncementForm />
          </Grid>
          {!showSingle && (
            <Grid item md={6}>
              <div className={styles['modal-content-container']}>
                <ManageAnnouncementForm />
              </div>
            </Grid>
          )}
        </Grid>
      </Modal>
      <Modal
        label={'Update Banner'}
        open={BannerModal}
        handleClose={() => {
          setBannerModal((prev) => !prev);
        }}
        maxWidth={'xl'}>
        <ImageUpload
          label={'Media'}
          imageList={[]}
          keyName='files'
          hideUpload={false}
          fileType='image/*'
          gridSize={1}
        />
        <div className={styles['btn-container']}>
          <Button
            btnType='secondary'
            onClick={() => {
              setBannerModal((prev) => !prev);
            }}>
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      </Modal>
      <DirectoryBanner
        onLocation={() => {
          setLocationModal((prev) => !prev);
        }}
        serviceList={[
          { name: 'Description', ref: descriptionRef, count: 0 },
          {
            name: 'Services',
            ref: serviceRef,
            count: directoryServices?.serviceCounter || '0',
          },
          { name: 'Products', ref: productRef, count: 3 },
          { name: 'Activities', ref: activitieRef, count: 3 },
          { name: 'Articles', ref: articleRef, count: 3 },
        ]}
        OnEnquiry={() => setEnquiryModal((prev) => !prev)}
        onManageDirectory={() => {
          setDirectoryModal((prev) => !prev);
        }}
        directoryController={directoryController}
        directoryBasic={directoryBasic}
        directoryImage={directoryImage}
        directoryImageController={directoryImageController}
        updateBannerModal={setBannerModal}
      />
      <section className={styles['section-container']}>
        <div className={styles['announcement-header']}>
          <ProductHeader
            label={'Announcements'}
            buttonName={'Add Announcements'}
            onClick={() => {
              navigate(ADD_ANNOUNCEMENT);
            }}
            isCarousel
          />
          <div>
            <Button
              Component={EditIcon}
              btnType='red'
              onClick={() => {
                setAnnouncementServices((prev) => !prev);
              }}>
              Manage Announcement
            </Button>
          </div>
        </div>
        <CarouselContainer items={3}>
          {dummyData1.map((el) => {
            return <AnnouncementCard active={el === 2} />;
          })}
        </CarouselContainer>
      </section>
      <DescriptionCard ref={descriptionRef} />
      <section
        ref={serviceRef}
        className={styles['section-container']}
        data-no-background='true'>
        <ProductHeader
          label={'Services we are offering'}
          count={directoryServices?.serviceCounter || '0'}
          buttonName={'Add Services'}
          onClick={() => {
            navigateToService(ADD_SERVICE_MAIN);
          }}
        />
        {directoryServices?.data.map((service) => {
          return (
            <InitiativeCard
              heading={service?.value?.service_name || ''}
              specifications={
                service?.value?.specifications?.map(
                  (specification) => specification.specification
                ) || []
              }
              categoryPathName={
                service?.value?.catsubcat_path_name.split('->') || []
              }
              onEnquiry={() => {
                setEnquiryModal(true);
              }}
              onManage={() => {
                setManageServices((prev) => !prev);
              }}
              media={service?.value?.media?.map((media) => media.media_path)}
              control={service.control}
            />
          );
        })}
      </section>
      <section className={styles['section-container']} ref={productRef}>
        <ProductHeader
          label={'Products We are offering'}
          count={directoryProducts?.serviceCounter || 0}
          buttonName={'add product'}
          onClick={() => {
            navigateToService(ADD_PRODUCT);
          }}
          // isCarousel
        />
        <CarouselContainer items={6}>
          {directoryProducts.data.map((product) => {
            return (
              <ServiceProductCard
                key={product.value.product_name}
                imageSrc={product.value?.media_path}
                onManage={() => {
                  setProductServices((prev) => !prev);
                }}
                label={product.value.product_name}
                onProductClick={() => {
                  setSelectedproduct({
                    subproducts: product.value.subproducts,
                    pathName: product.value.catsubcat_path_name.split('->'),
                  });
                  setProductModal(true);
                }}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <section
        className={styles['section-container']}
        data-no-background='true'>
        <ProductHeader
          label={'Our Initiative'}
          count={2}
          buttonName={'Add Initiative'}
          onClick={() => {
            navigateToService(ADD_INITIATIVE);
          }}
        />
        <InitiativeCard
          heading={'Sollar Panel Roofing Services'}
          address={`23, 3rd Main St, R.J.Gardens, Anand Nagar, Aswath Nagar,
              Chinnappanna Halli, Bengaluru,Karnatake,India-560037`}
          onLocation={() => {}}
          list={[
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          ]}
          onVolunteer={() => {}}
          onEnquiry={() => {
            setEnquiryModal(true);
          }}
          onManage={() => {
            setInitiativeServices((prev) => !prev);
          }}
        />
      </section>
      <section
        className={styles['section-container']}
        data-no-background='true'
        ref={activitieRef}>
        <ProductHeader
          label={'Other Activities: Events & Courses '}
          count={3}
          buttonName={'add Activity'}
          onClick={() => {
            navigateToService(ADD_ACTIVITY);
          }}
          isCarousel
        />
        <CarouselContainer items={3}>
          {dummyData.map((el) => {
            return (
              <ActivitiesCard
                imgSrc={garden}
                onManage={() => {
                  setActivityServices((prev) => !prev);
                }}
                heading={'Sutainability Development Goal'}
                label={`Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem`}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <section className={styles['section-container']} ref={articleRef}>
        <ProductHeader
          label={'Our Latest Blogs & Articles'}
          count={3}
          isCarousel
        />
        <CarouselContainer items={3}>
          {dummyData.map((el) => {
            return (
              <ActivitiesCard
                isBlog
                imgSrc={garden}
                commentCounts={3}
                author={'Shivani Thakur'}
                date={'17 Aug, 2021'}
                heading='The passage experienced a surge in popularity during the 1960s.'
                label='Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
              />
            );
          })}
        </CarouselContainer>
      </section>
      {/* Directory Page Models */}
      <ProductModal
        open={productModal}
        list={selectedProduct?.pathName}
        data={selectedProduct?.subproducts}
        openModal={setProductModal}
      />
      <EnquiryModal open={enquiryModal} openModal={setEnquiryModal} />
      <LocationModal
        open={locationModal}
        openModal={setLocationModal}
        list={directoryBasic?.address}
      />
    </div>
  );
};
