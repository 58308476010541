import { CardHeader, CardBody, RichTextEditor } from 'app/components';
export const Description = ({
  description,
  setDescription,
  excerpt,
  setExcerpt,
  error = {},
}) => {
  return (
    <>
      <CardHeader
        heading={'Description'}
        hideHelperText
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
        }
      />
      <CardBody>
        <RichTextEditor
          label={'Description'}
          editorState={description}
          setEditorState={setDescription}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          error={!!error?.description}
          errorMessage={error?.description}
        />
        <RichTextEditor
          label={'Excerpt'}
          editorState={excerpt}
          setEditorState={setExcerpt}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          error={!!error?.excerpt}
          errorMessage={error?.excerpt}
        />
      </CardBody>
    </>
  );
};
export default Description;
