import { useCallback, useState, useEffect } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { TextInput, Button, Select, Label } from 'app/components';
import styles from './sign-up.module.scss';
import { useNavigate } from 'react-router-dom';
import { TERMS } from 'app/libs/constants/tab-routes';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from '../slice/auth.slice';
import { selectWardList } from '../slice/auth.selector';
import { selectErrors } from '../slice/auth.selector';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobile, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [ward, setWardName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actions = useAuthSlice().actions;
  const wardList = useSelector(selectWardList);
  const errors = useSelector(selectErrors);
  const reset = () => {
    setFirstName('');
    setLastName('');
    setPincode('');
    setMobileNumber('');
    setEmail('');
    setWardName('');
    dispatch(actions.loadedWardList([]));
  };
  const onSignUp = () => {
    const args = {
      firstname: firstName,
      lastname: lastName,
      pincode,
      email,
      ward_code: ward,
      mobile,
      city_code: wardList[0]?.city_code || '',
      state_code: wardList[0]?.state_code || '',
      country_code: wardList[0]?.country_code || '',
    };
    const signUpFormData = new FormData();
    Object.entries(args).forEach(([key, value]) => {
      signUpFormData.append(key, value);
    });
    dispatch(actions.postSignUp({ data: signUpFormData, navigate, reset }));
  };
  const isValidPincode = useCallback(() => {
    const isValid = !!pincode && pincode.length === 6;
    return isValid;
  }, [pincode]);

  const fetchAreaInfo = useCallback(() => {
    const isValid = isValidPincode();
    if (isValid) {
      setWardName('');
      dispatch(actions.loadWardList(pincode));
    }
  }, [actions, dispatch, isValidPincode, pincode]);

  useEffect(() => {
    fetchAreaInfo();
  }, [fetchAreaInfo, pincode]);
  useEffect(() => {
    if (wardList.length === 1) {
      setWardName(wardList[0].ward_code);
    }
  }, [wardList]);
  const updateErrors = (key) => {
    const updatedErrors = { ...errors };
    delete updatedErrors[key];
    dispatch(actions.updateErrors({ ...updatedErrors }));
  };

  return (
    <div className={styles['main-container']}>
      <Card
        sx={{ display: 'flex' }}
        style={{ width: '1167px', minHeight: '500px' }}>
        <Grid container>
          <Grid item md={6}>
            <div className={styles['left-container']}>
              <div className={styles['image-overlay']}></div>
              <section className={styles['text-content']}>
                <h3>Urjacity</h3>
                <p>
                  The passage experienced a surge in popularity during the 1960s
                  when Letraset used it on their dry-transfer sheets, and again
                  during the 90s as desktop publishers bundled
                </p>
              </section>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={styles['container']}>
              <Label center style={{ margin: '0 0 20px 0' }} isHeading>
                Sign Up
              </Label>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <TextInput
                    label={'First Name'}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      if (e.target.value) {
                        updateErrors('firstname');
                      }
                    }}
                    placeholder={'Enter first name'}
                    errorMessage={errors?.firstname}
                    error={!!errors?.firstname}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextInput
                    label={'Last Name'}
                    value={lastName}
                    placeholder={'Enter last name'}
                    errorMessage={errors?.lastname}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      if (e.target.value) {
                        updateErrors('lastname');
                      }
                    }}
                    error={!!errors?.lastname}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <TextInput
                    label={'Mobile Number'}
                    placeholder={'Enter mobile number'}
                    value={mobile}
                    errorMessage={errors?.mobile}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^(\s*|\d+)$/.test(value) && value.length <= 10)
                        setMobileNumber(value);

                      if (e.target.value) {
                        updateErrors('mobile');
                      }
                    }}
                    iconName={'smartphone'}
                    error={!!errors?.mobile}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <TextInput
                    label={'Email Address'}
                    placeholder={'Enter email address'}
                    value={email}
                    errorMessage={errors?.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value) {
                        updateErrors('email');
                      }
                    }}
                    iconName={'email'}
                    error={!!errors?.email}
                  />
                </Grid>
              </Grid>
              <TextInput
                label={'Pincode'}
                placeholder={'Enter 6 digit PIN code'}
                value={pincode}
                errorMessage={errors?.pincode}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^(\s*|\d+)$/.test(value) && value.length <= 6) {
                    setPincode(e.target.value);
                    if (e.target.value) {
                      updateErrors('pincode');
                    }
                  }
                }}
                error={!!errors?.pincode}
              />
              {wardList.length !== 0 && (
                <>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextInput
                        label={'Country'}
                        placeholder={'Enter country'}
                        value={wardList[0]?.country || ''}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item sm={12} md={6}>
                      <TextInput
                        label={'City'}
                        placeholder={'Enter city'}
                        value={wardList[0]?.city || ''}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextInput
                        label={'State'}
                        placeholder={'Enter state'}
                        value={wardList[0]?.state || ''}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Select
                        label={'Ward'}
                        options={wardList}
                        placeholder={'Enter ward'}
                        value={ward}
                        errorMessage={errors?.ward_code}
                        onChange={(e) => {
                          setWardName(e.target.value);
                          if (e.target.value) {
                            updateErrors('ward_code');
                          }
                        }}
                        error={!!errors?.ward_code}
                        itemKey='ward_code'
                        itemName='ward'
                        disable={wardList.length === 1}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Button fullWidth onClick={onSignUp}>
                Sign Up Now
              </Button>
              <Typography
                variant='subtitle1'
                gutterBottom
                component='div'
                mt={2}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: '16px',
                }}>
                By clicking sign up, you agree to our{' '}
                <span
                  style={{ color: '#FF9801', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(TERMS);
                  }}>
                  Terms and Conditions{' '}
                </span>
                &
                <span style={{ color: '#FF9801', cursor: 'pointer' }}>
                  {' '}
                  Privacy Policy
                </span>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default SignUp;
