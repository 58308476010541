import styles from './directory-card.module.scss';
import { ReactComponent as StarIcon } from 'app/libs/assests/star-icon-empty.svg';
import { ReactComponent as LocationIcon } from 'app/libs/assests/location-icon.svg';
import { useNavigate } from 'react-router-dom';
import { DIRECTORY, DIRECTORY_MAP } from 'app/libs/constants/tab-routes';

export const DirectoryCard = ({ directoryType }) => {
  const list = [
    'Services(3)',
    'Products(4)',
    'Activities(5)',
    'Initiatives(6)',
    'Articles(3)',
    'Announcements(12)',
  ];
  const announcementList = [
    {
      type: 'hiring',
      content:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
    },
    {
      type: 'sales',
      content:
        'Duis aute dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    },
    {
      type: 'sales',
      content:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip Lorem ipsum',
    },
  ];
  const navigate = useNavigate();
  return (
    <div
      className={styles['main-container']}
      onClick={() =>
        navigate(`${DIRECTORY}/2565dd14b669e77f1ab0a8e6ee331756b01e2651`)
      }>
      <section className={styles['header-container']}>
        <span
          className={styles['directory-type']}
          data-directory={directoryType}>
          {directoryType}
        </span>
        <aside>
          <span className={styles['rating-container']}>
            <StarIcon className={styles['star-icon']} />
            5.0
          </span>
          <span>1.2 Kms Away</span>
          <span
            className={styles['map-content']}
            onClick={(event) => {
              event.stopPropagation();
              navigate(DIRECTORY_MAP);
            }}>
            <LocationIcon className={styles['location-icon']} />
            Open in map
          </span>
        </aside>
      </section>
      <h3 className={styles['heading']}>Organic Mandya Farmers Market</h3>
      <p className={styles['sub-content']}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.
      </p>
      <section className={styles['tag-container']}>
        {list.map((el) => {
          return <span>{el}</span>;
        })}
      </section>
      <h3 className={styles['heading']}>Popular Announcements</h3>
      <section className={styles['announcement-container']}>
        {announcementList.map((announcement, index) => (
          <div>
            <span className={styles['numbering-style']}>{index + 1}</span>
            <span
              className={styles['announcement-type']}
              data-type={announcement.type}>
              {announcement.type}
            </span>
            <p>{announcement.content}</p>
          </div>
        ))}
        <span>More &gt;</span>
      </section>
    </div>
  );
};
