import { useState } from 'react';
import Chip from '@mui/material/Chip';
import { TextError, Label } from '../';
import Delete from 'app/libs/assests/delete.svg';
import styles from './text-input-chip.module.scss';

export const TextInputChip = ({
  label,
  errorMessage,
  error = false,
  placeholder = 'Please enter the field',
  iconName,
  onIconClick,
  disabled = false,
  Component,
  required = false,
  onDelete,
  subContent,
  list,
  onChange,
  deleteRow,
  ...props
}) => {
  const [value, setValue] = useState('');
  const onTextDelete = (index) => {
    const newList = [...list];
    const filteredList = newList.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    onDelete?.(filteredList);
  };

  return (
    <div className={`${styles['container']}`}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <div
        className={`${styles['input-container']}  ${
          disabled ? styles['container-disabled'] : ''
        }`}>
        {list.map((el, index) => (
          <Chip
            label={el}
            onDelete={
              !disabled && !!onDelete ? () => onTextDelete(index) : undefined
            }
            sx={{ height: '28px' }}
          />
        ))}

        <input
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            const value = e?.target?.value || '';
            if (!value.includes(',')) setValue(e.target.value);
          }}
          onBlur={() => {
            setValue('');
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onChange([...list, value]);
              setValue('');
            }
          }}
        />
        {deleteRow && !disabled && (
          <div
            className={styles['delete-icon']}
            onClick={() => {
              deleteRow?.();
            }}>
            <img src={Delete} alt='delete' />
          </div>
        )}
      </div>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
};
