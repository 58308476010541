import GlobalKidHands from 'app/libs/assests/global-kids-hands.png';
import styles from './global-kid-banner.module.scss';
import UrjacityVision from 'app/libs/assests/urjacity-vision.png';

export const GlobalKidBanner = () => {
  const list = [
    'Urjacity  invites you to connect with  greener activities of your city.',
    'If you have an earth-friendly solution list it with us and be discoverable.',
    'Add announcements and help others to join you in your journey to sustainability.',
  ];
  return (
    <div className={styles['container']}>
      <section>
        <div className={styles['head-container']}>
          <p>why choose urja city </p>
          <h3>Urjacity vision is to connect initiatives in your cities</h3>
          <ul>
            {list.map((el, index) => {
              return (
                <li>
                  <span>{index + 1}</span>
                  {el}
                </li>
              );
            })}
          </ul>
          <span>Learn More</span>
        </div>
      </section>
      <section>
        <div className={styles['directives-card']}>
          <p>4</p>
          Indian Cities
        </div>
        <img src={GlobalKidHands} alt='globalKidHands' />
        <div className={styles['images-footer']}>
          <span>Urja City</span>
          <img src={UrjacityVision} alt='vision' />
          <p>Login to get personalized information for sustainable living.</p>
        </div>
      </section>
    </div>
  );
};
