import styles from './initiative-detail.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as EnquiryIcon } from 'app/libs/assests/enquiry-icon.svg';
import { ReactComponent as VolunterIcon } from 'app/libs/assests/volunter-icon.svg';
import CheckIconCircle from 'app/libs/assests/check-icon-circle.svg';
import { Button, StepperList } from '../..';
import LocationIcon from 'app/libs/assests/location-icon.svg';
import { AttentionIcon } from 'app/components/attention-icon/attention-icon';

export const InitiativeDetail = ({
  specifications = [],
  onVolunteer,
  address,
  heading,
  onManage,
  onEnquiry,
  onLocation,
  control,
  categoryPathName = [],
  ...props
}) => {
  return (
    <div className={styles['container']}>
      <section className={styles['header-container']}>
        <div>
          <h3>{heading}</h3>
          {control?.show_manage_service && (
            <Button btnType='red' onClick={onManage} Component={EditIcon}>
              Manage <AttentionIcon isAdmin={control?.admin_attention_needed} />{' '}
              <AttentionIcon isAuthor={control?.author_attention_needed} />
            </Button>
          )}
        </div>
        <StepperList list={categoryPathName} />
        {address && (
          <div className={styles['divider']}>
            <section>
              <img src={LocationIcon} alt='location' />
              <span>{address}</span>
            </section>
            <span onClick={onLocation}>More Location &gt;</span>
          </div>
        )}
      </section>
      <ul>
        {specifications.map((specification) => {
          return (
            <li>
              <img src={CheckIconCircle} alt='check' />
              {specification}
            </li>
          );
        })}
      </ul>
      <section className={styles['btn-container']}>
        <Button Component={EnquiryIcon} onClick={onEnquiry}>
          Enquiry
        </Button>
        {onVolunteer && (
          <Button Component={VolunterIcon} onClick={onVolunteer}>
            Volunteer
          </Button>
        )}
      </section>
    </div>
  );
};
