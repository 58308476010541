import { Grid } from '@mui/material';
import styles from './support-banner.module.scss';
export const SupportBanner = () => {
  return (
    <div className={styles['container']}>
      <section>
        <p>NUMBERS SPEAK</p>
        <h3>We are Growing Day by day with your support</h3>
      </section>
      <Grid container>
        <Grid item md={4}>
          <div className={styles['grid-container']}>
            120<p>Dirctories</p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles['grid-container']}>
            30<p>Cities</p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles['grid-container']}>
            2000+<p>happy People</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
