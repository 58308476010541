import {
  MenuItem,
  InputLabel,
  Select as DropDown,
  FormControl,
} from '@mui/material';
import styles from './select.module.scss';
import { Label, TextError } from '../';

export const Select = ({
  options = [],
  value = '',
  placeholder,
  disable = false,
  error,
  errorMessage,
  onChange,
  label = 'ward',
  required = false,
  subContent,
  itemKey = 'id',
  itemName = 'name',
}) => {
  return (
    <div className={styles['container']}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <FormControl
        sx={{
          width: '100%',
          height: '36px',
          marginBottom: '5px',
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0,0,0,0.23) !important',
          },
          '& .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0,0,0,0.23) !important',
          },
          '& .Mui-disabled': {
            background: 'rgba(19, 29, 59, 0.05)',
            color: 'rgba(102, 109, 129, 0.5)',
          },
          '& .Mui-disabled svg': {
            background: 'transparent',
          },
          '& .MuiOutlinedInput-input': {
            height: '36px',
            padding: '8px 14px',
            fontFamily: 'Roboto Condensed',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
          },
        }}>
        <InputLabel
          id='demo-simple-select-autowidth-label'
          placeholder={placeholder}></InputLabel>
        <DropDown
          value={value || ''}
          labelId='demo-simple-select-autowidth-label'
          id='demo-simple-select-autowidth'
          displayEmpty
          disabled={disable}
          onChange={onChange}>
          <MenuItem value={''}>
            <span className={styles['option-style-disable']}>
              {placeholder}
            </span>
          </MenuItem>
          {options.map((option) => {
            const key = option?.[itemKey] || option;
            return (
              <MenuItem value={key} key={key}>
                <span className={styles['option-style']}>
                  {option?.[itemName] || option}
                </span>
              </MenuItem>
            );
          })}
        </DropDown>
      </FormControl>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
};
