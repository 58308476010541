import { useEffect, useRef, useState } from 'react';
import { Card, Stepper } from 'app/components';
import styles from './basic-info.module.scss';
import { InfoContainer } from './components/info-container';
import { useDispatch } from 'react-redux';
import { useBasicInfoSlice } from './slice/basic-info.slice';
export const FeatureInfo = () => {
  const [activeTab, setActiveTab] = useState(0);
  const actions = useBasicInfoSlice().actions;
  const dispatch = useDispatch();
  const basicInfoRef = useRef();
  const addressRef = useRef();
  const contactRef = useRef();
  const descriptionRef = useRef();
  const list = [
    'Basic Information',
    'Location Information',
    'Contact Information',
    'Description about Entity',
  ];
  useEffect(() => {
    let ref = '';
    switch (activeTab) {
      case 0:
        ref = basicInfoRef;
        break;
      case 1:
        ref = addressRef;
        break;
      case 2:
        ref = contactRef;
        break;
      case 3:
        ref = descriptionRef;
        break;

      default:
        ref = basicInfoRef;
        break;
    }
    if (ref && ref.current) {
      ref?.current?.scrollIntoView();
    }
  }, [activeTab]);
  useEffect(() => {
    return () => {
      dispatch(actions.updateErrors({}));
    };
  }, [actions, dispatch]);
  return (
    <div className={styles['container']}>
      <h2 className='page-heading'>Create Directory</h2>
      <Stepper
        list={list}
        activeTab={activeTab}
        onClick={(tab) => setActiveTab(tab)}
      />
      <Card style={{ marginTop: '40px' }}>
        <InfoContainer
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          containerRefs={{
            basic: basicInfoRef,
            address: addressRef,
            contact: contactRef,
            description: descriptionRef,
          }}
        />
      </Card>
    </div>
  );
};
export default FeatureInfo;
