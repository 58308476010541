import { Grid } from '@mui/material';
import { Button, Label, TextInput } from 'app/components';
import HomeBanner from 'app/libs/assests/home-banner.png';
import styles from './banner.module.scss';

export const Banner = () => {
  return (
    <div className={styles['container']}>
      <section style={{ backgroundImage: `url(${HomeBanner})` }}>
        <p>We Are Bringing a chance</p>
        <h1>Make your Cities</h1>
        <h1>Green and Sustainable!</h1>
        <Button>Submit Green Business</Button>
        <div className={styles['indicator']}>
          <span data-active='true'></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <div className={styles['search-container']}>
        <Label>Find earth friendly alternatives</Label>
        <section>
          <Grid
            container
            spacing={2}
            justifyContent={'center'}
            alignContent={'center'}>
            <Grid item md={7} lg={8}>
              <TextInput placeholder='Enter location name' />
            </Grid>
            <Grid item md={5} lg={4}>
              <Button
                iconName={'my_location'}
                fullWidth
                style={{ height: '36px' }}>
                Search
              </Button>
            </Grid>
          </Grid>
        </section>
      </div>
    </div>
  );
};
