import axios from 'axios';
import { snackbarActions } from 'app/shared/feature-snackbar/slice/feature-snackbar.slice';
import { store } from '../../';

const ApiService = axios.create({
  headers: {
    // Referer: "http://localhost:3000/",
    // "Referrer-Policy": "no-referrer"
  },
});

let authToken = '';
export const setAuthorizationToken = (token) => {
  authToken = token ? `Bearer ${token}` : token;
};
const showSnackBar = (type, message) => {
  store.dispatch(
    snackbarActions.loadSnackbar({
      type,
      open: true,
      message,
    })
  );
};
const onResponse = (response) => {
  console.log(response);
  const { control_block = {}, message_block = {} } = response.data;
  if (
    control_block?.message_block_status &&
    message_block?.message_type === 'MAIN_ERR'
  ) {
    console.log("message_block?.message?.main_message", message_block?.message?.main_message  )
    const type = message_block?.message?.message_type == "MAIN_MSG"  ? 'success' : 'error';
    const message = message_block?.message?.main_message;
    showSnackBar(type, message);
    // Object.entries(message_block?.message || {}).forEach(([key, value]) => {
    //   console.log(value);
    //   const type = message_block?.message?.message_type == "MAIN_MSG"  ? 'success' : 'error';
    //   const message = value || 'Something Went Wrong';
    //   showSnackBar(type, message);
    // });
  }
  // console.log(response);
  return response;
};

const onResponseError = (error) => {
  return Promise.reject(error);
};
const onRequestError = (error) => {
  return Promise.reject(error);
};
const onRequest = (config) => {
  if (!config.headers.Authorization && authToken) {
    // console.log(authToken);
    config.headers.Authorization = authToken;
    // config.headers['access-control-allow-credentials'] = true;
  }
  return config;
};

ApiService.interceptors.response.use(onResponse, onResponseError);
ApiService.interceptors.request.use(onRequest, onRequestError);

export default ApiService;
