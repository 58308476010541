import { useState } from 'react';

import styles from './manage-activity-form.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  CardBody,
  TextArea,
  ImageUpload,
  ServiceHeader,
  TextInputChip,
  CardHeader,
} from 'app/components';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const ManageActivityForm = ({ hideHider = false }) => {
  const { search } = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [form, setForm] = useState({
    activityName: { value: '', required: true, error: false },
    activityType: { value: '', required: true, error: false },
    activityUrl: { value: '', required: true, error: false },
    activityDescription: { value: '', required: true, error: false },
    files: [],
    services: [],
  });
  const list = useSelector(selectDirectoryNames);
  const onDelete = (key, id) => {
    setForm((prev) => {
      const form = { ...prev };
      const List = [...form[key]];
      const newList = List.filter((item) => {
        return item.id !== id;
      });
      form[key] = newList;
      return form;
    });
  };
  const validate = () => {
    let isValid = true;
    const newForm = { ...form };
    Object.entries(newForm).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newData = [...value].map((value) => {
          const data = { ...value };
          if (data.required) {
            data.error = !data.value;
            isValid &&= !data.error;
          }
          return data;
        });
        newForm[key] = newData;
      } else {
        const data = { ...value };
        if (data.required) {
          data.error = !data.value;
          console.log(data.error);
          newForm[key] = data;
          isValid &&= !newForm[key].error;
        }
      }
    });
    setForm(newForm);
    return isValid;
  };
  const onSubmit = () => {
    const isValid = validate();
    if (isValid) {
      const params = new URLSearchParams(search);
      const formData = new FormData();
      formData.append('activity_name', form.activityName.value);
      formData.append('activity_description', form.activityDescription.value);
      formData.append('activity_url', form.activityUrl.value);
      formData.append('activity_type', form.activityType.value);
      formData.append('category_path_id', params.get('category_id'));
      formData.append('search_tag', JSON.stringify(form.services));
    }
  };

  return (
    <>
      {!hideHider && (
        <CardHeader
          heading={'Manage Activity'}
          subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
          helperText={''}
          mainHeader
        />
      )}
      <div>
        <CardBody>
          <div className={styles['message-container']}>
            <Button btnType='green'>Approved</Button>
            <div className={styles['status-style']}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's
            </div>
          </div>
          <ServiceHeader list={list} disabled={isDisabled} />
          <section>
            <TextInput
              value={form.activityName.value}
              error={form.activityName.error}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityName';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              disabled={isDisabled}
              errorMessage={'Please enter activity name'}
              label={'Activity Name'}
              placeholder='Here can be Activity Name'
            />
            <TextInput
              value={form.activityType.value}
              error={form.activityType.error}
              disabled={isDisabled}
              required
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityType';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              errorMessage={'Please enter activity type'}
              label={'Activity Type'}
              placeholder='Here can be Activity Type'
            />
            <TextInput
              value={form.activityUrl.value}
              error={form.activityUrl.error}
              disabled={isDisabled}
              required
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityUrl';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              errorMessage={'Please enter activity url'}
              label={'Activity Url'}
              placeholder='Here can be Activity Url'
            />
            <TextArea
              value={form.activityDescription.value}
              error={form.activityDescription.error}
              errorMessage={'Please enter description'}
              disabled={isDisabled}
              required
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityDescription';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              label={'Activity Description'}
              placeholder='Enter Description'
            />
            <ImageUpload
              label={'Media'}
              onDelete={onDelete}
              imageList={form.files}
              disabled={isDisabled}
              keyName='files'
              hideUpload={form.files.length >= 4}
              fileType='image/*'
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const key = 'files';
                  const file = e.target.files[0];
                  const id =
                    (form?.files?.[form.files.length - 1]?.id || 0) + 1;
                  setForm((prev) => {
                    const newData = { ...prev };
                    const fileData = { value: file, id };
                    const newFlies = [...newData[key]];
                    newFlies.push(fileData);
                    newData[key] = newFlies;
                    return newData;
                  });
                }
              }}
            />

            <TextInputChip
              placeholder='Here can be Search Tag'
              label={'Search Tags'}
              disabled={isDisabled}
              list={form.services}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              }
              onChange={(value) => {
                setForm((prev) => {
                  const key = 'services';
                  return {
                    ...prev,
                    [key]: value,
                  };
                });
              }}
              onDelete={(value) => {
                setForm((prev) => {
                  const key = 'services';
                  return {
                    ...prev,
                    [key]: value,
                  };
                });
              }}
            />
          </section>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            onClick={() => {
              setIsDisabled((prev) => !prev);
            }}
            Component={EditIcon}
            style={{ marginRight: '14px' }}>
            Edit
          </Button>
          <Button
            btnType='secondary'
            Component={DeleteIcon}
            style={{ marginRight: '14px' }}>
            Delete
          </Button>
          <Button
            btnType='secondary'
            Component={ReviewIcon}
            onClick={() => {
              onSubmit();
            }}>
            Submit For Review
          </Button>
        </CardActions>
      </div>
    </>
  );
};
