import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './add-service.module.scss';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  TextArea,
  CardHeader,
  ImageUpload,
  ServiceHeader,
  TextInputChip,
} from 'app/components';
import {
  selectDirectoryNames,
  selectError,
} from './slice/add-service.selector';
import { useAddServiceSlice } from './slice/add-service.slice';
import { useNavigate, useLocation } from 'react-router-dom';

export const AddServiceMain = () => {
  const navigate = useNavigate();
  const actions = useAddServiceSlice().actions;
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [form, setForm] = useState({
    serviceName: { value: '', required: true, error: false },
    specifications: [{ value: '', id: 1, required: true, error: false }],
    files: [],
    services: [],
  });
  const list = useSelector(selectDirectoryNames);
  const onDelete = (key, id) => {
    setForm((prev) => {
      const form = { ...prev };
      const List = [...form[key]];
      const newList = List.filter((item) => {
        return item.id !== id;
      });
      form[key] = newList;
      return form;
    });
  };

  useEffect(() => {
    return () => {
      dispatch(actions.updateErrors({}));
    };
  }, [actions, dispatch]);
  const handleChange = (value, id, key) => {
    setForm((prev) => {
      const form = { ...prev };
      const specificationList = [...form[key]];
      const newList = specificationList.map((item) => {
        if (item.id === id) {
          return { ...item, value, error: !value };
        }
        return item;
      });
      form[key] = newList;
      return form;
    });
  };
  const AddMore = ({ label, keyName }) => {
    const requiredValues = ['specifications'];
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          setForm((prev) => {
            const form = { ...prev };
            const newSpecification = {
              value: '',
              id: (form[keyName]?.[form[keyName].length - 1]?.id || 1) + 1,
              required: requiredValues.includes(keyName),
              error: false,
            };
            const List = [...form[keyName], newSpecification];
            form[keyName] = List;
            return form;
          });
        }}>
        + {label}
      </p>
    );
  };
  const validate = () => {
    let isValid = true;
    const newForm = { ...form };
    Object.entries(newForm).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newData = [...value].map((value) => {
          const data = { ...value };
          if (data.required) {
            data.error = !data.value || data.value.split(' ').length < 5;
            isValid &&= !data.error;
          }
          return data;
        });
        newForm[key] = newData;
      } else {
        const data = { ...value };
        if (data.required) {
          data.error = !data.value || data.value.split(' ').length < 5;
          console.log(data.error);
          newForm[key] = data;
          isValid &&= !newForm[key].error;
        }
      }
    });
    setForm(newForm);
    return isValid;
  };
  const getArrayValue = (
    data,
    key = 'specification_text',
    id = 'spec_sequence_no'
  ) => {
    return data.map((el) => ({ [key]: el.value, [id]: el.id }));
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onSubmit = async () => {
    // const isValid = validate();
    // if (isValid) {
    const media_box = await Promise.all(
      form.files.map(async (file) => {
        const media = await toBase64(file.value);
        return { media, media_sequence_no: file.id };
      })
    );

    const params = new URLSearchParams(search);
    const formData = new FormData();
    formData.append('service_name', form.serviceName.value);
    formData.append(
      'specification_text',
      JSON.stringify(getArrayValue(form.specifications))
    );
    formData.append('media_box', JSON.stringify(media_box));
    formData.append('catsubcat_path_id', params.get('category_id'));
    formData.append('directory_id', params.get('directory_id'));
    formData.append('search_tag', JSON.stringify(form.services));
    dispatch(
      actions.addServices({
        data: formData,
        navigate,
        directory_id: params.get('directory_id'),
      })
    );
    // }
  };
  return (
    <div>
      <CardHeader
        heading={'Add Service'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <ServiceHeader list={list} />

          <section style={{ paddingRight: '45px' }}>
            <TextInput
              label={'Service Name'}
              value={form.serviceName.value}
              error={!!error?.service_name || ''}
              errorMessage={error?.service_name || ''}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'serviceName';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              placeholder='Here can be Service Name'></TextInput>
            <section>
              {form.specifications.map((specification, index) => {
                const key = 'specifications';
                const findIndex = error?.specification_text?.map(
                  (error) => error.spec_sequence_no === specification.id
                );

                const props = {
                  value: specification.value,
                  placeholder: 'Enter Specifications',
                  errorMessage:
                    error?.specification?.[findIndex]?.specification_text,
                  error:
                    !!error?.specification?.[findIndex]?.specification_text,
                  onChange: (event) => {
                    handleChange(event.target.value, specification.id, key);
                  },
                };
                if (specification.id === 1) {
                  props.label = 'Specifications';
                } else {
                  props.onDelete = () => {
                    onDelete(key, specification.id);
                  };
                }
                return (
                  <TextArea
                    key={specification.id}
                    {...props}
                    subContent={
                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                    }
                  />
                );
              })}
              {form.specifications.length < 9 && (
                <AddMore
                  label={'Add Specifications'}
                  keyName='specifications'
                />
              )}
            </section>
            <ImageUpload
              label={'Media'}
              onDelete={onDelete}
              imageList={form.files}
              keyName='files'
              hideUpload={form.files.length >= 4}
              fileType='image/*'
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const key = 'files';
                  const file = e.target.files[0];
                  const id =
                    (form?.files?.[form.files.length - 1]?.id || 0) + 1;
                  setForm((prev) => {
                    const newData = { ...prev };
                    const fileData = { value: file, id };
                    const newFlies = [...newData[key]];
                    newFlies.push(fileData);
                    newData[key] = newFlies;
                    return newData;
                  });
                }
              }}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
            />
            <section>
              <TextInputChip
                placeholder='Here can be Search Tag'
                label={'Search Tags'}
                list={form.services}
                onChange={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
                subContent={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                }
                errorMessage={error?.search_tag}
                error={!!error?.search_tag}
                onDelete={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
              />
            </section>
          </section>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            style={{ marginRight: '14px' }}
            onClick={() => {
              navigate(-1);
            }}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Save</Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddServiceMain;
