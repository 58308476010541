import { useRef, useState } from 'react';
import styles from './caurosel-container.module.scss';
import CarouselArrow from 'app/libs/assests/carousel-arrow.svg';
import React from 'react';

export const CarouselContainer = ({ children, items = 3 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselContainer = useRef();
  return (
    <div
      style={{
        position: 'relative',
      }}>
      <div className={styles['carousel-container']}>
        <span
          className={styles['caurousel-btn-left']}
          onClick={() => {
            if (activeIndex > 0) setActiveIndex((prev) => --prev);
          }}>
          <img src={CarouselArrow} alt='carousel arrow' />
        </span>
      </div>
      <div
        style={{
          overflow: 'hidden',
        }}>
        <section
          ref={carouselContainer}
          className={styles['cards-container']}
          style={{ '--slider-index': activeIndex, '--items': items }}>
          {React.Children.map(children, (children) => {
            return <div className={styles['slider-card']}>{children}</div>;
          })}
        </section>
      </div>
      <div className={styles['carousel-container1']}>
        <span
          className={styles['caurousel-btn-right']}
          onClick={() => {
            let value = 1;
            if (carouselContainer.current) {
              value = window
                .getComputedStyle(carouselContainer.current)
                .getPropertyValue('--items-per-screen');
            }
            const totalIndex = Math.ceil(children.length / value);
            setActiveIndex((prev) => {
              const count = ++prev;
              if (count + 1 <= totalIndex) return count;
              else return 0;
            });
          }}>
          <img src={CarouselArrow} alt='carousel arrow' />
        </span>
      </div>
    </div>
  );
};
