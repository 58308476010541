import { authActions as actions } from './auth.slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { snackbarActions } from 'app/shared/feature-snackbar/slice/feature-snackbar.slice';
import {
  postLogin,
  fetchWardList,
  postSignUp,
  postResetPassword,
  postForgotPassword,
  postValidateEmail,
} from '../services/auth-service';
import { HOME, DASHBOARD } from 'app/libs/constants/tab-routes';
import { setAuthorizationToken } from 'app/api/api-service';

export function* submitLogin(action) {
  try {
    const data = new FormData();
    data.append('email', action.payload.email);
    data.append('password', action.payload.password);
    const response = yield call(postLogin, data);

    //Checking for Message block status. if it's true it will check for inline.
    //if message type is inline then we are showing the inline errors.
    // if message type is main then we are showing snackbar at api interceptor level.
    //if message block status is true and there's no message type then we are throwing an error
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));

        return;
      } else if (
        response.message_block.message_type === 'MAIN_ERR' || response.message_block.message_type === 'MAIN_MSG'
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }

    //checking for status in control block, if it's true then executing data and control logic

    //checking for data block status in control block, if it's true then executing data block  logic and flow will continue.
    console.log("response in saga", response.control_block.data_block_status);
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      console.log(response.data_block.data[0]);
      const token = response.data_block?.data[0]?.value?.token || '';
      if (token) {
        setAuthorizationToken(token);
        yield put(actions.updateToken(token));
      } else {
        throw new Error('something went wrong (token)');
      }
    }
    //executing control block logic irrespective of data block status
    //checking for directory owner key. if it's not available then we are throwing an error
    if ('directory_owner' in response.control_block) {
      // based on directory owner condition we are navigating to respective routes
      const route = response.control_block?.directory_owner ? DASHBOARD : HOME;
      action.payload.navigate(route);
    } else {
      setAuthorizationToken('');
      yield put(actions.updateToken(''));
      throw new Error('Something went wrong (directory)');
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* submitSignUp(action) {
  try {
    const response = yield call(postSignUp, action.payload.data);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
    }
    action.payload.reset();
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* getWardList(action) {
  try {
    const args = { pincode: action.payload };
    const response = yield call(fetchWardList, args);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      const data = response.data_block.map((ward) => ward.data);
      if (data) {
        yield put(actions.loadedWardList(data));
      } else {
        throw new Error('Something Went Wrong');
      }
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* submitResetPassword(action) {
  try {
    const response = yield call(postResetPassword, action.payload.form);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
    }
    // action.payload.navigate(LOGIN);
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* submitForgotPassword(action) {
  try {
    const response = yield call(postForgotPassword, action.payload);
    if (response.response_type === 'inline') {
      yield put(actions.updateErrors(response.data));
    }
  } catch (error) {}
}
export function* validateEmail(action) {
  try {
    const response = yield call(postValidateEmail, action.payload.form);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));

        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if ('email_validation' in response.control_block) {
      // based on directory owner condition we are navigating to respective routes
      if (response.control_block.email_validation) {
        yield put(actions.successValidatedEmail(true));
      } else {
        throw new Error('Something went wrong (email validation)');
      }
    } else {
      throw new Error('Something went wrong (email validation)');
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* authFromSaga() {
  yield takeLatest(actions.postLogin.type, submitLogin);
  yield takeLatest(actions.postSignUp.type, submitSignUp);
  yield takeLatest(actions.loadWardList.type, getWardList);
  yield takeLatest(actions.postResetPassword.type, submitResetPassword);
  yield takeLatest(actions.postForgotPassword.type, submitForgotPassword);
  yield takeLatest(actions.validateEmail.type, validateEmail);
}
