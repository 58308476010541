import { TextInput, CardHeader, CardBody } from 'app/components';
import { Grid } from '@mui/material';

export const ContactInformation = ({ form, setForm, error = {} }) => {
  const onHandleChange = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  return (
    <>
      <CardHeader
        heading={'Social Information'}
        hideHelperText
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
        }
      />
      <CardBody>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TextInput
              label={'Website'}
              placeholder={'Enter Website'}
              value={form.org_url.value}
              onChange={(e) => {
                onHandleChange(e.target.value, 'org_url');
              }}
              error={!!error.org_url}
              errorMessage={error.org_url}
            />
          </Grid>
        </Grid>
        <TextInput
          label={'Facebook Profile'}
          placeholder={'Enter Facebook Profile'}
          value={form.facebook_profile.value}
          onChange={(e) => {
            onHandleChange(e.target.value, 'facebook');
          }}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          error={!!error.facebook_profile}
          errorMessage={error.facebook_profile}
        />
        <TextInput
          label={'Likedin Profile'}
          placeholder={'Enter Likedin Profile'}
          value={form.linkedin_profile.value}
          onChange={(e) => {
            onHandleChange(e.target.value, 'linkedin');
          }}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          error={!!error.linkedin_profile}
          errorMessage={error.linkedin_profile}
        />
        <TextInput
          label={'YouTube Link'}
          placeholder={'Enter YouTube Link'}
          value={form.youtube_profile.value}
          onChange={(e) => {
            onHandleChange(e.target.value, 'youtube');
          }}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          error={!!error.youtube_profile}
          errorMessage={error.youtube_profile}
        />
      </CardBody>
    </>
  );
};
export default ContactInformation;
