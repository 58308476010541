import { useMemo, useState } from 'react';
import DirectoryBannerImage from 'app/libs/assests/directory-banner.png';
import styles from './banner.module.scss';
import { Grid } from '@mui/material';
import { Button } from 'app/components';
import { ReactComponent as StarIcon } from 'app/libs/assests/star-icon-empty.svg';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete-red.svg';
import { ReactComponent as LocationIcon } from 'app/libs/assests/location-icon.svg';
import UserImage from 'app/libs/assests/shivani-thakur.png';
import { ReactComponent as CallIcon } from 'app/libs/assests/call-icon.svg';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AttentionIcon } from 'app/components/attention-icon/attention-icon';

export const DirectoryBanner = ({
  onLocation,
  serviceList = [],
  OnEnquiry,
  onManageDirectory,
  directoryController = {},
  directoryBasic = {},
  directoryImageController = {},
  directoryImage = {},
  updateBannerModal,
}) => {
  const [user, setUser] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const services = useMemo(() => {
    const servicesItem = [];
    const mobileNumber = directoryBasic?.address?.[0]?.mobile || '';
    if (mobileNumber) {
      servicesItem.push({
        icon: CallIcon,
        name: 'Call',
        value: mobileNumber,
        href: `tel:${mobileNumber}`,
      });
    }
    if (directoryBasic.org_url) {
      servicesItem.push({
        icon: LocationIcon,
        name: 'Show in map',
        onClick: () => {
          navigate(directoryBasic.org_url);
        },
      });
    }
    return servicesItem;
  }, [directoryBasic]);
  const navigate = useNavigate();

  const DirectoryMenu = ({ item }) => {
    const Icon = item.icon;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <div>
          <span>
            <Icon onClick={handleMenu} />
          </span>
          {item.name}
        </div>
        {item.value && (
          <Menu
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: '40%',
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            id='basic-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            transformOrigin={{ horizontal: 'center' }}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              onClick={() => {
                item?.onClick?.();
                handleClose();
              }}>
              {item.href ? <a href={item.href}>{item?.value}</a> : item?.value}
            </MenuItem>
          </Menu>
        )}
      </>
    );
  };

  return (
    <section
      className={styles['image-container']}
      style={{
        backgroundImage: user ? '' : `url(${directoryImage?.dir_image})`,
      }}>
      <div className={styles['image-overlay']} data-user={`${user}`}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <section className={styles['content-container']}>
              {user && (
                <img
                  src={UserImage}
                  alt='shivani-thakur'
                  className={styles['user-image']}
                />
              )}
              <div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {user ? (
                    <>
                      {directoryImageController?.show_update_banner && (
                        <Button
                          btnType='orange'
                          Component={EditIcon}
                          onClick={() => {
                            setUser((prev) => !prev);
                          }}>
                          Update Photo
                        </Button>
                      )}
                      {directoryImageController?.show_delete_Button && (
                        <Button btnType='red' Component={DeleteIcon}>
                          Delete Photo
                        </Button>
                      )}
                    </>
                  ) : (
                    directoryController?.show_manage_directory && (
                      <>
                        <span
                          className={styles['directory-type']}
                          data-directory={directoryBasic?.directory_type}>
                          {directoryBasic?.directory_type}
                        </span>
                        <Button
                          btnType='tertiary'
                          style={{ color: '#FF5555' }}
                          component={EditIcon}
                          onClick={() => {
                            onManageDirectory();
                          }}>
                          Manage Directory{' '}
                          <AttentionIcon
                            isAdmin={directoryController.admin_attention_needed}
                          />
                          <AttentionIcon
                            isAuthor={
                              directoryController.author_attention_needed
                            }
                          />
                        </Button>
                      </>
                    )
                  )}
                </div>
                <h3 style={{ color: user ? '#131d3b' : '#ffffff' }}>
                  {user ? 'Shivani Thakur' : directoryBasic?.directory_name}
                </h3>
              </div>
            </section>

            <p>{directoryBasic?.address?.[0]?.address_line_1 || ''}</p>
            {!!directoryBasic?.address?.[0]?.landmark && (
              <div className={styles['location-container']}>
                <section>LandMark</section>
                <section>{directoryBasic?.address?.[0]?.landmark}</section>
              </div>
            )}
            <div className={styles['location-container']}>
              <section>Ward Name</section>
              <section>{directoryBasic?.address?.[0]?.ward || ''}</section>
            </div>
            <div className={styles['location-container3']}>
              <section></section>
              <section onClick={onLocation}>More Locations</section>
            </div>

            <div className={styles['btn-container']}>
              <Button fullWidth style={{ height: '29px' }} onClick={OnEnquiry}>
                Enquiry
              </Button>
            </div>
          </Grid>
          <Grid item md={8}>
            <div className={styles['update-banner-header']}>
              {user ? (
                directoryController?.show_manage_directory && (
                  <Button
                    btnType='red'
                    component={EditIcon}
                    onClick={onManageDirectory}>
                    Manage Directory
                  </Button>
                )
              ) : (
                <>
                  {directoryImageController?.show_update_banner && (
                    <Button
                      btnType='tertiary'
                      style={{ color: '#FF5555' }}
                      component={EditIcon}
                      onClick={() => {
                        updateBannerModal(true);
                      }}>
                        
                      Update Banner
                    </Button>
                  )}
                  {directoryImageController?.show_delete_Button && (
                    <Button
                      btnType='tertiary'
                      style={{ color: '#FF5555' }}
                      component={DeleteIcon}>
                      Delete Banner
                    </Button>
                  )}
                  {directoryImageController?.show_approve_Button && (
                    <Button btnType='tertiary' style={{ color: '#FF5555' }}>
                      Approve Banner
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className={styles['review-container']}>
              <section className={styles['chip-container']}>
                {serviceList.map((service) => {
                  return (
                    <div
                      className={styles['chip']}
                      key={service.name}
                      data-active={`${service.name === selectedService}`}
                      onClick={() => {
                        setSelectedService(service.name);
                        service?.ref?.current?.scrollIntoView();
                      }}>
                      {service.name}
                      {!!service.count && ` (${service.count})`}
                    </div>
                  );
                })}
              </section>
              <section className={styles['rating-container']}>
                <span>{directoryBasic?.rating}</span>
                {Array.from(Array(5)).map((el, index) => {
                  return (
                    <StarIcon
                      className={styles['svg-icon']}
                      data-active={`${index < directoryBasic?.rating}`}
                    />
                  );
                })}
              </section>
              <section className={styles['icon-container']}>
                {services.map((item) => {
                  return <DirectoryMenu item={item} />;
                })}
              </section>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};
