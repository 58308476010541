import { useState } from 'react';
import { Grid, Card } from '@mui/material';
import { TextInput, Button, Label } from 'app/components';
import styles from './set-password.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import SideImage from 'app/libs/assests/forgot-password-image.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from '../slice/auth.slice';
import { selectEmailValidated, selectErrors } from '../slice/auth.selector';

const SetPassword = (props) => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const actions = useAuthSlice().actions;
  const errors = useSelector(selectErrors);
  const emailValidated = useSelector(selectEmailValidated);
  const onSubmit = () => {
    const form = new FormData();
    if (emailValidated) {
      form.append('password', password);
      form.append('re_password', newPassword);
    } else {
      form.append('email', email);
    }
    form.append('token', params.get('q') || '');
    const action = emailValidated
      ? actions.postResetPassword
      : actions.validateEmail;
    dispatch(action({ form, navigate }));
  };
  return (
    <div className={styles['main-container']}>
      <Card
        sx={{ display: 'flex' }}
        style={{ width: '1167px', minHeight: '500px' }}>
        <Grid container>
          <Grid item md={6}>
            <div className={styles['forgot-password-container']}>
              <div className={styles['container']}>
                <Label center style={{ marginBottom: '30px' }} isHeading>
                  Reset Your Password
                </Label>
                {emailValidated ? (
                  <>
                    <TextInput
                      type={'password'}
                      label={'New Password'}
                      placeholder='Enter Your New Password'
                      errorMessage={errors?.password}
                      error={!!errors?.password}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextInput
                      type={'password'}
                      label={'Re-Enter New Password'}
                      placeholder={'Enter Your New Password Again'}
                      errorMessage={errors?.re_password}
                      error={!!errors?.re_password}
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                    />
                  </>
                ) : (
                  <TextInput
                    type={'email'}
                    label={'Enter your Email'}
                    placeholder={'Enter Email'}
                    errorMessage={errors?.email}
                    error={!!errors?.email}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                )}
                <Button fullWidth onClick={onSubmit}>
                  {emailValidated ? 'Reset Password' : 'Submit'}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={styles['left-container']}>
              <div className={styles['image-overlay']}>
                <img src={SideImage} alt='sideimage' />
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default SetPassword;
