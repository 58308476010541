import { useState } from 'react';
import { ProfileCard, PropertyContainer, EditProfileModal } from './components';
import { PageHeader } from 'app/components';
import styles from './profile.module.scss';

export const Profile = () => {
  const [editProfile, SetEditProfile] = useState(false);
  const propertyList = [
    {
      name: 'Initiatives',
      list: [
        {
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab',
          list: ['view(5)'],
        },
        {
          content:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
          list: ['view(5)'],
        },
      ],
    },
    {
      name: 'Activity: Events',
      list: [
        {
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab',
          list: ['Leads(13)'],
        },
        {
          content:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
          list: ['Leads(13)'],
        },
      ],
    },
    {
      name: 'Activity: Courses',
      list: [
        {
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab',
          list: ['Leads(13)'],
        },
        {
          content:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
          list: ['Leads(13)'],
        },
      ],
    },
    {
      name: 'Articles',
      list: [
        {
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab',
          list: ['Views(13)', 'Comments(13)'],
        },
        {
          content:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
          list: ['Views(13)', 'Comments(13)'],
        },
      ],
    },
  ];

  return (
    <div className={styles['container']}>
      <PageHeader
        heading={'My Profile'}
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
        }
      />
      <ProfileCard
        onEditProfile={() => {
          SetEditProfile((prev) => !prev);
        }}
      />
      <PageHeader
        heading={'My Directories'}
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
        }
      />
      <PropertyContainer list={propertyList} />
      <EditProfileModal
        open={editProfile}
        handleClose={() => {
          SetEditProfile((prev) => !prev);
        }}
      />
    </div>
  );
};
