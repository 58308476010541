import styles from './radio-group.module.scss';
import { Button, RadioButton, TextError } from '../';

export const RadioGroup = ({
  label,
  list = [],
  selected,
  onChange,
  required,
  error,
  errorMessage,
  isRadioButton = true,
}) => {
  return (
    <div className={styles['container']}>
      {label && (
        <label className={styles['lable-style']}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <section className={styles['check-box-group']}>
        {list.map((option) => {
          if (isRadioButton) {
            return (
              <RadioButton
                key={option.id}
                label={option.name}
                checked={option.id === selected}
                onChange={() => {
                  onChange(option.id);
                }}
                {...option}
              />
            );
          } else {
            return (
              <Button
                key={option.id}
                btnType={option.id === selected ? 'primary' : 'secondary'}
                onClick={() => {
                  onChange(option.id);
                }}
                rounded>
                {option.name}
              </Button>
            );
          }
        })}
      </section>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
};
