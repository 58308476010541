import { useState } from 'react';
import { Grid, Card } from '@mui/material';
import { TextInput, Button, Label } from 'app/components';
import styles from './forgot-password.module.scss';
import { validateEmail } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from 'app/libs/constants/tab-routes';
import SideImage from 'app/libs/assests/forgot-password-image.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from '../slice/auth.slice';
import { selectErrors } from '../slice/auth.selector';
const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const actions = useAuthSlice().actions;
  const errors = useSelector(selectErrors);
  const onSendLink = () => {
    const form = new FormData();
    form.append('email', email);
    dispatch(actions.postForgotPassword(form));
  };
  return (
    <div className={styles['main-container']}>
      <Card
        sx={{ display: 'flex' }}
        style={{ width: '1167px', minHeight: '500px' }}>
        <Grid container>
          <Grid item md={6}>
            <div className={styles['forgot-password-container']}>
              <div className={styles['container']}>
                <Label center style={{ marginBottom: '30px' }} isHeading>
                  Forgot Password
                </Label>
                <h4
                  style={{
                    color: '#666D81',
                    textAlign: 'center',
                    marginBottom: '15px',
                  }}>
                  Enter your registered mail address, we will send password
                  reset link to verify.
                </h4>
                <TextInput
                  type={'email'}
                  label={'Email'}
                  placeholder='Enter Email'
                  errorMessage={errors.email || 'you have entered wrong Email'}
                  error={!!errors.email}
                  onChange={(e) => setEmail(e.target.value)}
                  iconName={'email'}
                />
                <Button fullWidth onClick={onSendLink}>
                  Send Reset Password Link
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={styles['left-container']}>
              <div className={styles['image-overlay']}>
                <img src={SideImage} alt='sideimage' />
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default ForgotPassword;
