import { Radio } from '@mui/material';
import styles from './radio-button.module.scss';
import { ReactComponent as InfoIcon } from 'app/libs/assests/info.svg';

export const RadioButton = ({
  label,
  checked,
  onChange,
  sublabel,
  textType,
  ...props
}) => {
  return (
    <div className={styles['container']}>
      <Radio
        value={label}
        checked={checked}
        onChange={onChange}
        sx={{
          color: '#c4c4c4',
          '&.Mui-checked': { color: '#FF9801' },
          '&.MuiRadio-root': {
            padding: 0,
            marginRight: '5px',
          },
        }}
      />
      <span className={styles['checkbox-label']} data-text-type={textType}>
        {label}
      </span>
      {sublabel && (
        <>
          <InfoIcon />
          <span className={styles['sub-label']}>{sublabel}</span>
        </>
      )}
    </div>
  );
};
