import { createSelector } from '@reduxjs/toolkit';
import { initialState } from '../models';
const selectDomain = (state) => state.auth || initialState;

export const selectWardList = createSelector(
  [selectDomain],
  (auth) => auth.wardList
);
export const selectErrors = createSelector(
  [selectDomain],
  (auth) => auth.errors
);

export const selectToken = createSelector([selectDomain], (auth) => auth.token);
export const selectEmailValidated = createSelector(
  [selectDomain],
  (auth) => auth.emailValidated
);
