import { Grid } from '@mui/material';
import { Button, Label } from 'app/components';
import ProfilePic from 'app/libs/assests/shivani-thakur.png';
import styles from './profile-card.module.scss';
import { ReactComponent as EmailIcon } from 'app/libs/assests/email@.svg';
import { ReactComponent as PhoneIcon } from 'app/libs/assests/mobile.svg';

export const ProfileCard = ({ onEditProfile }) => {
  return (
    <main className={styles['container']}>
      <Grid container spacing={4} justifyContent='center'>
        <Grid item md={3}>
          <img
            src={ProfilePic}
            alt={'Profile Pic'}
            className={styles['profile-image']}
          />
        </Grid>
        <Grid item md={9}>
          <Label>Gopalkrishna Kumar Vishwanath</Label>
          <section className={styles['icon-container']}>
            <p>
              <PhoneIcon /> +91-9876543210
            </p>
            <p>
              <EmailIcon />
              gopalkrishna12345.Kumar@gmail.com
            </p>
          </section>
          <p className={styles['location-style']}>
            Ward : <span>Indranagar</span>
          </p>
          <p className={styles['address-style']}>
            Ground Floor, No 2984, 12th Main Rd, HAL 2nd Stage, Indiranagar,
            Bengaluru, Karnataka 560008
          </p>
          <Button btnType='red' onClick={onEditProfile}>
            Edit Profile
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};
