import { DirectoryCard } from './components/directory-card/directory-card';
import { Grid } from '@mui/material';
import { Pagination, Label, Button } from 'app/components';
import styles from './directory-map.module.scss';
import { ReactComponent as LocationIcon } from 'app/libs/assests/location-icon.svg';
import { ReactComponent as FilterIcon } from 'app/libs/assests/filter-icon.svg';
import MapContainer from './components/map/map';

export const DirectoryMap = (props) => {
  const dummyData = [1, 2, 3, 4];

  return (
    <div
      style={{
        position: 'relative',
      }}>
      <Grid container>
        <Grid md={12}>
          <section className={styles['left-container']}>
            <div className={styles['header-container']}>
              <Label>32 Entites</Label>
              <Label>10 Kms</Label>
              <div>
                <Button Component={FilterIcon}>Filter</Button>
                <Button Component={LocationIcon}>Map View</Button>
              </div>
            </div>
            <div className={styles['list-container']}>
              {dummyData.map((el) => {
                return <DirectoryCard />;
              })}
            </div>
            <Pagination count={3} />
          </section>
          <div
            style={{
              width: '60%',
              height: 'calc(100vh - 123px)',
              position: 'relative',
              float: 'right',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}>
            <MapContainer />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
