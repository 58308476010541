import { emailRegex, numberRegex } from 'app/libs/constants/regex';

export const initialState = {
  locationForm: [
    {
      address_sequence_no: 1,
      address_line_1: { value: '', required: true, error: false },
      pincode: { value: '', required: true, error: false },
      country_name: { value: '', required: true, error: false },
      city_name: { value: '', required: true, error: false },
      state_name: { value: '', required: true, error: false },
      Ward: { value: '', required: true, error: false },
      latitude: { value: '', required: true, error: false },
      longitude: { value: '', required: true, error: false },
      mobile: { value: '', required: true, error: false, regex: numberRegex },
      email: { value: '', required: true, error: false, pattern: emailRegex },
      landmark: { value: '' },
      city: { value: '' },
      state: { value: '' },
      country: { value: '' },
      hideAddressDetails: { value: true },
      wardOptions: [],
    },
  ],
  placeSuggestionlist: [],
  error: {},
};
export const addressForm1Schema = {
  address_sequence_no: 1,
  address_line_1: { value: '', required: true, error: false },
  pincode: { value: '', required: true, error: false },
  country_name: { value: '', required: true, error: false },
  city_name: { value: '', required: true, error: false },
  state_name: { value: '', required: true, error: false },
  ward: { value: '', required: true, error: false },
  mobile: { value: '', required: true, error: false, regex: numberRegex },
  email: { value: '', required: true, error: false, pattern: emailRegex },
  latitude: { value: '', required: true, error: false },
  longitude: { value: '', required: true, error: false },
  countryCode: { value: '+91', required: true, error: false },
  landmark: { value: '' },
  city: { value: '' },
  state: { value: '' },
  country: { value: '' },
  hideAddressDetails: { value: true },
  wardOptions: [],
};
export const addressFormSchema = {
  address_sequence_no: 1,
  address_line_1: { value: '', required: true, error: false },
  address_line_2: { value: '', required: true, error: false },
  pincode: { value: '', required: true, error: false },
  country_name: { value: '', required: true, error: false },
  city_name: { value: '', required: true, error: false },
  state_name: { value: '', required: true, error: false },
  ward: { value: '', required: true, error: false },
  mobile: { value: '', required: true, error: false, regex: numberRegex },
  email: { value: '', required: true, error: false, pattern: emailRegex },
  latitude: { value: '', required: true, error: false },
  longitude: { value: '', required: true, error: false },
  landmark: { value: '' },
  city: { value: '' },
  state: { value: '' },
  country: { value: '' },
  wardOptions: [],
  countryCode: { value: '+91', required: true, error: false },
};
