import { ModalHeader } from './modal-header/modal-header';
import { Dialog } from '@mui/material';
import styles from './modal.module.scss';

export const Modal = ({
  open = true,
  handleClose,
  children,
  label,
  maxWidth = 'md',
  ...props
}) => {
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth={maxWidth}>
      <div
        style={{
          minWidth: '600px',
          padding: '0 15px 15px',
          position: 'relative',
        }}>
        <ModalHeader
          label={label}
          onClose={handleClose}
          className={styles['header']}
        />
        {children}
      </div>
    </Dialog>
  );
};
