import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import styles from './pagination.module.scss';
import { Button } from '../';
const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  gap: '10px',
});
export const Pagination = ({ count }) => {
  const { items } = usePagination({
    count,
  });
  return (
    <div className={styles['container']}>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <Button btnType={selected ? 'primary' : 'secondary'}>
                {page}
              </Button>
            );
          } else {
            children = <Button>{type}</Button>;
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </div>
  );
};
