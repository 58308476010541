import styles from './date-picker.module.scss';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { Label, TextError } from '../';
export function DateTextInput({
  label,
  errorMessage,
  error = false,
  placeholder = '',
  iconName,
  onIconClick,
  disabled = false,
  Component,
  required = false,
  value,
  onChange,
  subContent,
  ...props
}) {
  return (
    <div className={`${styles['container']}`}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: '100%',
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid rgba(0,0,0,0.23) !important',
                },
                '& .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline':
                  {
                    border: '1px solid rgba(0,0,0,0.23) !important',
                  },
                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  background: 'rgba(19, 29, 59, 0.05)',
                  color: 'rgba(102, 109, 129, 0.5)',
                },
                '& .Mui-disabled svg': {
                  background: 'transparent',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '10px 10px',
                  fontFamily: 'Roboto Condensed',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '21px',
                  maxHeight: '40px !important',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '5px',
                },
                '& .MuiOutlinedInput-notchedOutline legend': {
                  float: 'left',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  top: '0',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
}
