import styles from './attention-icon.module.scss';
import { ReactComponent as DropDownArrow } from 'app/libs/assests/drop-down-arrow.svg';
import Tooltip from '@mui/material/Tooltip';

export const AttentionIcon = ({
  tooltip = '“Manage Directory” with info text: “Your attention is needed. Please click on ‘Manage Directory',
  isAdmin = false,
  isAuthor = false,
}) => {
  const adminClass = isAdmin ? styles['attention-admin'] : '';
  const authorClass = isAuthor ? styles['attention-author'] : '';
  if (isAdmin || isAuthor)
    return (
      <Tooltip title={tooltip}>
        <DropDownArrow
          className={`${styles['attention']} ${authorClass} ${adminClass}`}
        />
      </Tooltip>
    );

  return <></>;
};
