import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import styles from './service-product-card.module.scss';
import { Button } from '../';

export const ServiceProductCard = ({
  label,
  imageSrc,
  onEnquiry,
  onManage,
  onProductClick,
  ...props
}) => {
  return (
    <div className={styles['container1']}>
      <div className={styles['image-container']} onClick={onProductClick}>
        <img src={imageSrc} alt={label} />
      </div>
      <p>{label}</p>
      <Button Component={EditIcon} onClick={onManage} btnType='red'>
        Manage
      </Button>
    </div>
  );
  return (
    <div className={styles['container']}>
      <section
        onClick={onProductClick}
        className={styles['service-image']}
        data-size={'25px'}
        style={{ backgroundImage: `url(${imageSrc})` }}></section>
      <div className={styles['btn-container']}>
        <div className={styles['img-overlay']}>
          <p>{label}</p>
        </div>
        <Button imageSrc={EditIcon} onClick={onManage} btnType='red'>
          Manage
        </Button>
      </div>
    </div>
  );
};
