import ApiService from 'app/api/api-service';

export const getDirectoryData = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/directory/basic/view/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};

export const getDirectoryImage = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/directory/priimg/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};

export const getDirectoryProductList = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/directory/product/view/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};

export const getDirectoryServices = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/getlist/directory/serv_product/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};
