import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CardHeader, CardBody } from 'app/components';
import { LocationForm } from '../location-form/location-form';
import styles from './location-info.module.scss';
import { useBasicInfoSlice } from '../../slice/basic-info.slice';
import { addressFormSchema } from '../../model';

export const LocationInformation = ({
  onlyLocation = false,
  disabled = false,
  addressForm,
  setAddressForm,
  error,
}) => {
  const dispatch = useDispatch();
  const actions = useBasicInfoSlice().actions;

  const handleDelete = (id) => {
    const addressData = [...addressForm];
    const filterData = addressData.filter((address) => {
      return address.address_sequence_no !== id;
    });
    setAddressForm(filterData);
  };

  const addLocation = () => {
    const newSchema = { ...addressFormSchema };
    const lastAddressId =
      [...addressForm][addressForm.length - 1]?.address_sequence_no || 1;
    newSchema.address_sequence_no = lastAddressId + 1;
    setAddressForm((prev) => [...prev, newSchema]);
  };

  const getWardList = useCallback(
    (value, id) => {
      dispatch(
        actions.loadWardList({ value, id, addressForm, setAddressForm })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, dispatch]
  );

  const onAddressBlur = useCallback(
    async (selectedItem, id) => {
      try {
        await setAddressForm((prev) => {
          const dataIndex = prev.findIndex(
            (data) => data.address_sequence_no === id
          );
          const key = 'address_line_1';
          const data = [...prev];
          const form = { ...data[dataIndex] };
          form[key] = {
            ...form[key],
            value: selectedItem.value,
            error: !selectedItem.value,
          };
          if (form.hasOwnProperty('hideAddressDetails')) {
            form['hideAddressDetails'] = {
              ...form['hideAddressDetails'],
              value: !selectedItem.value,
            };
          }

          data[dataIndex] = form;
          return data;
        });
        dispatch(
          actions.loadLocationDetails({
            value: selectedItem.id,
            id,
            setAddressForm,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    [actions, addressForm, dispatch]
  );

  const handleChange = (value, key, id) => {
    try {
      const index = addressForm.findIndex(
        (data) => data.address_sequence_no === id
      );
      const data = [...addressForm];
      const form = { ...data[index] };
      form[key] = { ...form[key], value, error: !value };
      if (key === 'pincode') {
        form['Ward'] = { ...form['Ward'], value: '' };
      }
      data[index] = form;
      setAddressForm(data);
    } catch (error) {}
  };
  const Location = (
    <>
      {addressForm.map((addressForm, index) => {
        const props = { formData: { ...addressForm } };
        if (index !== 0) {
          props.onDelete = handleDelete;
        }
        props.onAddressBlur = (selectedItem) => {
          onAddressBlur(selectedItem, addressForm.address_sequence_no);
        };
        const addressError = error.findIndex((err) => {
          return err.address_sequence_no === addressForm.address_sequence_no;
        });
        return (
          <LocationForm
            id={addressForm.address_sequence_no}
            key={`${addressForm.address_sequence_no}`}
            {...props}
            onChange={(value, key) =>
              handleChange(value, key, addressForm.address_sequence_no)
            }
            getWardList={getWardList}
            disabled={disabled}
            error={error[addressError] || {}}
          />
        );
      })}
      <p
        className={styles['helper-style']}
        style={{
          opacity: disabled ? 0 : 1,
          cursor: disabled ? 'default' : 'pointer',
        }}
        onClick={addLocation}>
        + Add Location
      </p>
    </>
  );
  if (onlyLocation) return Location;
  return (
    <>
      <CardHeader
        heading={'Location Information'}
        hideHelperText
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
        }
      />
      <CardBody>
        <div className={styles['container']}>
          <div
            style={{
              marginRight: addressForm.length !== 1 ? '60px' : '0',
            }}>
            {Location}
          </div>
        </div>
      </CardBody>
    </>
  );
};
export default LocationInformation;
