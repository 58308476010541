import styles from './card-header.module.scss';
import info from 'app/libs/assests/info.svg';

export const CardHeader = ({
  heading,
  subHeading,
  hideHelperText = true,
  helperText = '* Mandatory fields',
  mainHeader,
  ...props
}) => {
  return (
    <section
      className={styles['card-header']}
      data-mainheader={`${mainHeader}`}>
      <h3>{heading}</h3>
      {subHeading && <p>{subHeading}</p>}
      {hideHelperText && helperText && (
        <p className={styles['helper-style']}>
          <img src={info} alt='info' /> {helperText}
        </p>
      )}
    </section>
  );
};
