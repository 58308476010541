import { useState, useEffect } from 'react';
import {
  NutritionalCard,
  ResourceCard,
  ProductHeader,
  CarouselContainer,
} from 'app/components';
import {
  GlobalKidBanner,
  SupportBanner,
  Banner,
  AnnouncementContainer,
  CustomerReview,
} from './component';
import styles from './home.module.scss';
import { selectCategory } from './slice/home.selector';
import { useHomeSlice } from './slice/home.slice';
import { useDispatch, useSelector } from 'react-redux';

export const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const category = useSelector(selectCategory);
  const actions = useHomeSlice().actions;

  useEffect(() => {
    dispatch(actions.loadCategory());
  }, [actions, dispatch]);

  return (
    <div>
      <Banner />
      <AnnouncementContainer />
      <GlobalKidBanner />

      <section className={styles['section-container']}>
        <ProductHeader
          label={'Invest in Nutritional Diet !'}
          subHeader={'Chemical Free Food'}
          isCarousel
        />
        <CarouselContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}>
          {category.map((category, index) => {
            return (
              <ResourceCard
                isEven={(index + 1) % 2 === 0}
                title={category.title}
                shortDesc={category.short_desc}
                cataColor={category.cata_color}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <section className={styles['section-container']}>
        <ProductHeader
          label={'Turn waste into resource !'}
          subHeader={'Waste'}
          isCarousel
        />
        <CarouselContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}>
          {category.map((category, index) => {
            return (
              <ResourceCard
                isEven={(index + 1) % 2 === 0}
                title={category.title}
                shortDesc={category.short_desc}
                cataColor={category.cata_color}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <section
        className={styles['section-container']}
        data-no-background={`${true}`}
        style={{ padding: '20px 280px' }}>
        <ProductHeader
          label={'Let’s together solve water woes.'}
          subHeader={'Save water'}
          align='right'
          isCarousel
        />
        <CarouselContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}>
          {category.map((category, index) => {
            return (
              <ResourceCard
                isEven={(index + 1) % 2 === 0}
                title={category.title}
                shortDesc={category.short_desc}
                cataColor={category.cata_color}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <section className={styles['section-container']}>
        <ProductHeader
          label={'Find alternatives for green living'}
          subHeader={'Products'}
          isCarousel
        />
        <CarouselContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}>
          {category.map((category, index) => {
            return (
              <ResourceCard
                isEven={(index + 1) % 2 === 0}
                title={category.title}
                shortDesc={category.short_desc}
                cataColor={category.cata_color}
              />
            );
          })}
        </CarouselContainer>
      </section>
      <CustomerReview />
      <SupportBanner />
    </div>
  );
};
