import { Modal, StepperList } from 'app/components';
import styles from './product-modal.module.scss';

export const ProductModal = ({ data = [], open, list = [], openModal }) => {
  return (
    <Modal
      label={'Products'}
      open={open}
      handleClose={() => {
        openModal((prev) => !prev);
      }}>
      <StepperList list={list} />
      <div className={styles['product-modal-list']}>
        {data.map((el, index) => {
          return (
            <div>
              <span>{index + 1}</span>
              {el.subproduct_name}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
