import { Header, Footer } from 'app/components';
import { Outlet } from 'react-router-dom';
import styles from './app-layout.module.scss';
import { FeatureSnackbar } from 'app/shared/feature-snackbar/feature-snackbar';

export const AppLayout = ({ removesideMargin = false }) => {
  return (
    <div className={styles['main-container']}>
      <Header />
      <section
        className={styles['container']}
        data-no-side-margin={`${removesideMargin}`}>
        <Outlet />
        <FeatureSnackbar />
      </section>
      <Footer />
    </div>
  );
};
