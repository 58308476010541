import { createSelector } from '@reduxjs/toolkit';
import { initialState } from '../models';

const selectDomain = (state) => state.snackbar || initialState;

export const selectMessage = createSelector(
  [selectDomain],
  (snackbar) => snackbar.message
);
export const selectOpen = createSelector(
  [selectDomain],
  (snackbar) => snackbar.open
);
export const selectType = createSelector(
  [selectDomain],
  (snackbar) => snackbar.type
);
