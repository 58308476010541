import ApiService from 'app/api/api-service';

export const postSaveDirectory = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/directory/save`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const fetchWardList = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get_pincode_details/${args.pincode}`;
  const response = await ApiService.get(url);
  return response.data;
};

export const fetchGeocodeLocation = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/google/address/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};
export const fetchPlaceSuggestion = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/google/address_suggestion_block/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};
