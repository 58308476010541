import { useState } from 'react';
import styles from './product.module.scss';
import { Card, CardHeader } from 'app/components';
import { ManageProductForm } from './component';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector } from 'react-redux';
export const ManageProduct = () => {
  return (
    <div>
      <CardHeader
        heading={'Manage Product'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        mainHeader
        hideHelperText={false}
      />
      <Card style={{ marginTop: '40px' }}>
        <ManageProductForm hideHeader />
      </Card>
    </div>
  );
};
export default ManageProduct;
