import { Modal } from 'app/components';
import styles from './location-modal.module.scss';
const dummyData = [
  {
    address_line_1:
      '23, 3rd Main St, R.J.Gardens, Anand Nagar, Aswath Nagar,Chinnapanna Halli',
    state: 'Karnataka',
    city: 'Bengaluru',
    ward: 'Indranagar',
    pincode: 560056,
  },
  {
    address_line_1: 'Taki Rd, Purbba Ichapur, West Bengal 743294',
    state: 'West Bengal',
    city: 'Shanti Pore',
    ward: 'Ashoknagar Road',
    pincode: 560056,
  },
  {
    address_line_1:
      'Byataranyanapura, 104/6, Kempapura Main Rd, Chiranjeevi Layout, Hebbal',
    state: 'Karnataka',
    city: 'Bengaluru',
    ward: 'Kempapura Main Rd',
    pincode: 560056,
  },
];

export const LocationModal = ({ open, openModal, list = dummyData }) => {
  return (
    <Modal
      label={'Locations'}
      open={open}
      handleClose={() => {
        openModal((prev) => !prev);
      }}>
      <table className={styles['table-container']}>
        <thead>
          <tr>
            <th>Address</th>
            <th>State</th>
            <th>City</th>
            <th>Ward</th>
          </tr>
        </thead>
        <tbody>
          {list.map((el) => {
            return (
              <tr>
                <td>
                  {el?.address_line_1} - {el?.pincode}
                </td>
                <td>{el?.state}</td>
                <td>{el?.city}</td>
                <td>{el?.ward}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Modal>
  );
};
