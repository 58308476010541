import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './product.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import {
  Button,
  CardActions,
  Card,
  CardBody,
  CardHeader,
  StepperList,
} from 'app/components';
import { AddProductInput } from './component';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useAddProductSlice } from './slice/product.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectError } from './slice/product.selector';
const initialValue = {
  id: 1,
  product_name: { value: '', required: true, error: false },
  services_tag: { value: [] },
};
export const AddProduct = () => {
  const [products, setProduct] = useState([initialValue]);
  const list = useSelector(selectDirectoryNames);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const actions = useAddProductSlice().actions;
  const error = useSelector(selectError);
  const navigate = useNavigate();

  const AddMore = ({ addMore, label }) => {
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          addMore((prev) => {
            const newProduct = { ...initialValue };
            newProduct.id = (prev[prev.length - 1]?.id || 0) + 1;
            return [...prev, newProduct];
          });
        }}>
        + {label}
      </p>
    );
  };

  const handleChange = (value, key, id) => {
    setProduct((prev) => {
      const products = [...prev];
      return products.map((product) => {
        const data = { ...product };
        if (id === data.id) data[key] = { ...data[key], value, error: !value };
        return data;
      });
    });
  };
  const validate = () => {
    let isValid = true;
    const newProducts = [...products].map((product) => {
      const newProduct = { ...product };
      Object.entries(newProduct).forEach(([key, value]) => {
        if (typeof value === 'object') {
          const data = { ...value };
          if (data?.required) {
            data.error = !data.value;
            isValid &&= !data.error;
          }
          newProduct[key] = data;
        }
      });
      return newProduct;
    });
    setProduct(newProducts);
    return isValid;
  };

  const getproductValue = () => {
    return products.map((product) => {
      return {
        subproduct_name: product.product_name.value,
        search_tag: product.services_tag.value.toString(','),
        subproduct_seq_no: product.id,
      };
    });
  };

  const onSubmit = () => {
    const params = new URLSearchParams(search);
    const formData = new FormData();
    const product = getproductValue();
    const product_name = list[list.length - 1]['name'];
    const directory_id = params.get('directory_id');
    formData.append('subproducts', JSON.stringify(product));
    formData.append('catsubcat_path_id', params.get('category_id'));
    formData.append('directory_id', directory_id);
    formData.append('product_name', product_name);
    dispatch(
      actions.addProduct({
        data: formData,
        navigate,
        directory_id,
      })
    );
  };

  return (
    <div>
      <CardHeader
        heading={'Add Product'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <div className={styles['stepper-container']}>
            <StepperList list={list} itemName='name' itemKey='id' />
            <section>
              <Button btnType='red' Component={EditIcon}>
                Edit
              </Button>
            </section>
          </div>

          <section style={{ paddingRight: '45px' }}>
            {products?.map((product, index) => {
              const productError = error?.subproducts?.findIndex((err) => {
                return err?.subproduct_seq_no === product.id;
              });
              const props = {
                
                productValue: product.product_name.value,
                serviceValue: product.services_tag.value,
                productError:
                  error?.subproducts?.[productError]?.subproduct_name,
                searchTagError: error?.subproducts?.[productError]?.search_tag,
              };
              if (index === 0) {
                props.label1 = 'Product Name';
                props.label2 = 'Search Tag';
              } else {
                props.onDelete = () => {
                  setProduct((prev) => {
                    const products = [...prev];
                    return products.filter((data) => {
                      if (data.id !== product.id) {
                        return data;
                      }
                    });
                  });
                };
              }
              return (
                <AddProductInput
                  key={product.id}
                  {...props}
                  onChange={(value, key, id = product.id) => {
                    handleChange(value, key, id);
                  }}
                />
              );
            })}
            <AddMore label={'Add More'} addMore={setProduct} />
          </section>
        </CardBody>
        <CardActions>
          <Button btnType='secondary' style={{ marginRight: '14px' }}>
            Cancel
          </Button>
          <Button style={{ marginRight: '14px' }} onClick={onSubmit}>
            Save
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddProduct;
