import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import { CardBody, CardActions, Button } from 'app/components';
import { LocationInformation } from '../location-info';
import { BasicInformation } from '../basic-info';
import { ContactInformation } from '../contact-info';
import { Description } from '../description';
import { emailRegex, numberRegex } from 'app/libs/constants/regex';
import { useDispatch } from 'react-redux';
import { useBasicInfoSlice } from '../../slice/basic-info.slice';
import { addressForm1Schema } from '../../model';

const initialValue = '<p></p><p></p><p></p><p></p><p></p>';
export const ManageInfoContainer = () => {
  const navigate = useNavigate();
  const [basicForm, setBasicForm] = useState({
    directory_type: { value: '', required: true, error: false },
    directory_name: { value: '', required: true, error: false },
  });
  const [description, setDescription] = useState(initialValue);
  const [excerpt, setExcerpt] = useState(initialValue);
  const [contactForm, setContactForm] = useState({
    countryCode: { value: '+91' },
    mobile: { value: '', error: false, required: true, regex: numberRegex },
    email: {
      value: '',
      error: false,
      required: true,
      pattern: emailRegex,
    },
    org_url: { value: '' },
    facebook_profile: { value: '' },
    linkedin_profile: { value: '' },
    youtube_profile: { value: '' },
  });
  const [addressForm, setAddressForm] = useState([{ ...addressForm1Schema }]);
  const containerRef = useRef();
  const actions = useBasicInfoSlice().actions;
  const dispatch = useDispatch();

  const onSubmit = () => {
    const isBasicFormValid = validateBasicForm();
    const isContactFormValid = contactFormValidate();
    const isAddressFormVald = validateAddressForm();
    if (isBasicFormValid && isContactFormValid && isAddressFormVald) {
      const addressFormKeys = [
        'address_line_1',
        'address_line_2',
        'country',
        'state',
        'city',
        'ward',
        'landmark',
        'pincode',
        'latitude',
        'longitude',
      ];
      const address = addressForm.map((data) => {
        const form = {};
        addressFormKeys.forEach((key) => {
          form[key] = data[key]?.value || '';
        });
        return form;
      });
      const form = new FormData();
      form.append('directory_name', basicForm.directoryName.value);
      form.append('email', contactForm.email.value);
      form.append('mobile', contactForm.mobile.value);
      form.append('org_url', contactForm.website.value);
      form.append('discription', description);
      form.append('excerpt', excerpt);
      form.append('establishhed', basicForm.directoryName.value);
      form.append('searchtag', basicForm.directoryName.value);
      form.append('directory_type', basicForm.directoryType.value);
      form.append('linkedin_profile', contactForm.linkedin_profile.value);
      form.append('facebook_profile', contactForm.facebook_profile.value);
      form.append('youtube_profile', contactForm.youtube_profile.value);
      form.append('address', JSON.stringify(address));
      dispatch(actions.submitDirectory({ form, navigate }));
    } else {
      if (containerRef?.current) {
        containerRef?.current?.scrollIntoView();
      }
    }
  };
  const contactFormValidate = () => {
    const newForm = { ...contactForm };
    let validated = true;
    Object.entries(contactForm).forEach(([key, value]) => {
      newForm[key].error = !(
        !value.required ||
        (!!value.value && (!value.pattern || value.pattern.test(value.value)))
      );

      validated &&= !newForm[key].error;
    });
    setContactForm(newForm);
    return validated;
  };
  const validateBasicForm = () => {
    const newForm = { ...basicForm };
    let validated = true;
    Object.entries(basicForm).forEach(([key, value]) => {
      newForm[key].error = !value.required || !value.value;
      validated &&= !newForm[key].error;
    });
    setBasicForm(newForm);
    return validated;
  };
  const validateAddressForm = () => {
    let isValid = true;
    const form = [...addressForm];
    const newForm = form.map((data) => {
      const form = { ...data };
      Object.entries(form).forEach(([key, value]) => {
        const subFields = { ...value };
        if (
          typeof subFields === 'object' &&
          subFields.hasOwnProperty('required')
        ) {
          subFields.error = !subFields?.required || !subFields.value;
          if (key === 'pincode') {
            subFields.error = subFields.value.length !== 6;
          }
          form[key] = subFields;
          isValid &&= !subFields.error;
        }
      });
      return form;
    });
    setAddressForm(newForm);
    return isValid;
  };

  return (
    <div ref={containerRef}>
      <CardBody>
        <BasicInformation form={basicForm} setForm={setBasicForm} />
        <LocationInformation
          addressForm={addressForm}
          setAddressForm={setAddressForm}
          error={[]}
        />

        <ContactInformation form={contactForm} setForm={setContactForm} />

        <Description
          description={description}
          setDescription={setDescription}
          excerpt={excerpt}
          setExcerpt={setExcerpt}
          onSubmit={onSubmit}
        />
      </CardBody>
      <CardActions>
        <Button
          btnType='secondary'
          style={{ marginRight: '14px' }}
          onClick={() => {
            navigate(-1);
          }}>
          Back
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </CardActions>
    </div>
  );
};
