import { addServiceActions as actions } from './add-service.slice';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  addServiceApi,
  fetchCategories,
  fetchSubCategories,
} from '../services';
import { selectDirectories } from './add-service.selector';
import { DIRECTORY, LOGIN } from 'app/libs/constants/tab-routes';

export function* addServices(action) {
  try {
    const response = yield call(addServiceApi, {
      data: action.payload.data,
      directory_id: action.payload.directory_id,
    });
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    const controlKeysAvailable =
      'status' in response.control_block &&
      'login_needed' in response.control_block;

    if (controlKeysAvailable) {
      if (response?.control_block?.status) {
        //navigate to directory
        action.payload.navigate(`${DIRECTORY}/${action.payload.directory_id}`);
      } else if (response.control_block.login_needed) {
        //navigate to login
        console.log('login');
        action.payload.navigate(LOGIN);
      } else {
        throw new Error('Something Went wrong');
      }
    } else if (!controlKeysAvailable) {
      throw new Error('Something went wrong');
    }
  } catch (error) {}
}

export function* getCategories() {
  try {
    const response = yield call(fetchCategories);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.

        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      yield put(actions.loadedCategories(response?.data_block?.data || []));
    }
  } catch (error) {}
}

export function* getSubCategory(action) {
  try {
    const response = yield call(fetchSubCategories, action.payload.categoryid);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.

        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      const subCategoryData = response?.data_block?.data;
      const data = Array.isArray(subCategoryData) ? subCategoryData : [];
      const directories = yield select(selectDirectories);
      const newDirectory = [...directories].slice(0, action.payload.index + 1);
      yield put(
        actions.loadedSubcategory({
          directory: [...newDirectory, data],
          name: action.payload.name,
        })
      );
    }
  } catch (error) {}
}

export function* addServiceFromSaga() {
  yield takeLatest(actions.addServices.type, addServices);
  yield takeLatest(actions.loadCategories.type, getCategories);
  yield takeLatest(actions.loadSubcategory.type, getSubCategory);
}
