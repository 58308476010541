import { TextInput, TextInputChip } from 'app/components';
import styles from './add-product-input.module.scss';
import { Grid } from '@mui/material';

export const AddProductInput = ({
  onDelete,
  label1,
  label2,
  disabled,
  serviceValue,
  productValue,
  productError,
  searchTagError,
  onChange,
  id,
}) => {
  return (
    <div className={styles['container']}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput
            label={label1}
            value={productValue}
            onChange={(event) => {
              const value = event.target.value;
              onChange(value, 'product_name', id);
            }}
            required
            error={!!productError}
            errorMessage={productError}
            placeholder='Here can be Product Name'
            subContent={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do '
            }
            disabled={disabled}></TextInput>
        </Grid>
        <Grid item md={6}>
          <TextInputChip
            label={label2}
            list={serviceValue}
            onChange={(value) => {
              onChange([...value], 'services_tag', id);
            }}
            placeholder='Here can be Service Tag'
            disabled={disabled}
            deleteRow={onDelete}
            error={!!searchTagError}
            errorMessage={searchTagError}
            subContent={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do '
            }
            onDelete={(value) => {
              onChange([...value], 'services_tag', id);
            }}></TextInputChip>
        </Grid>
      </Grid>
    </div>
  );
};