import styles from './comments.module.scss';

export const Comments = ({ name, date, content }) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['commenter-name']}>{name}</h3>
      <p className={styles['comment-date']}>{date}</p>
      <p className={styles['comment-content']}>{content}</p>
    </div>
  );
};
