import * as React from 'react';
import { Button } from '../button/button';
import { TextInput } from '../index';
import Logo from 'app/libs/assests/logo.png';
import Phone from 'app/libs/assests/phone.svg';
import Email from 'app/libs/assests/email.svg';
import userPic from 'app/libs/assests/user-pic.jpeg';
import styles from './header.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ARTICLE_LISTING,
  BASIC_INFORMATION,
  DASHBOARD,
  DIRECTORY_LIST,
  HOME,
  LOGIN,
  PROFILE,
} from 'app/libs/constants/tab-routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from 'app/pages/auth/slice/auth.selector';
import { useAuthSlice } from 'app/pages/auth/slice/auth.slice';
import { setAuthorizationToken } from 'app/api/api-service';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ReactComponent as DropDownArrow } from 'app/libs/assests/drop-down-arrow.svg';

export function Header({
  label,
  placeholder = 'Please enter the field',
  iconName,
  ...props
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = useSelector(selectToken);
  const actions = useAuthSlice().actions;
  const dispatch = useDispatch();
  const navList = [
    { label: 'Home', navigate: HOME },
    { label: 'About', navigate: PROFILE },
    { label: 'Directory', navigate: DIRECTORY_LIST },
    { label: 'Dashboard', navigate: DASHBOARD },
    { label: 'Enquiry' },
    { label: 'Testimonials' },
    { label: 'Blogs', navigate: ARTICLE_LISTING },
  ];
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles['container']}>
      <section className={styles['header-banner']}></section>
      <section className={styles['header-section']}>
        <img src={Logo} alt='Logo' />
        <section className={styles['input-container']}>
          <div style={{ display: 'flex' }}>
            <section style={{ width: '70%' }}>
              <TextInput
                style={{ height: '36px' }}
                Component={() => (
                  <Button
                    iconName={'my_location'}
                    btnType='tertiary'
                    style={{ height: '36px' }}>
                    Use Current Location
                  </Button>
                )}
              />
            </section>
            <section
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <div className={styles['support-container']}>
                <img src={Phone} alt={'phone'} />
              </div>
              <div className={styles['support-container']}>
                <img src={Email} alt={'email'} />
              </div>
            </section>
          </div>

          <div className={styles['list-container']}>
            <ul>
              {navList.map((nav) => {
                return (
                  <li
                    key={nav.label}
                    onClick={() => {
                      nav.navigate && navigate(nav.navigate);
                    }}
                    data-active={`${nav.navigate === pathname}`}>
                    {nav.label}
                  </li>
                );
              })}
            </ul>
            <section onClick={() => navigate(BASIC_INFORMATION)}>
              Submit a Directory <span>+</span>
            </section>
          </div>
        </section>

        {token ? (
          <div className={styles['user-container']}>
            <img src={userPic} alt={'user'} className={styles['user-icon']} />
            <section>
              <p className={styles['user-name']} onClick={handleMenu}>
                Ramesh Agarwal <DropDownArrow className={styles['drop-down']} />
              </p>
              <Menu
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: '40%',
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                id='basic-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                transformOrigin={{ horizontal: 'center' }}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem
                  onClick={() => {
                    setAuthorizationToken('');
                    dispatch(actions.updateToken(''));
                    setAnchorEl(null);
                    navigate(LOGIN);
                  }}>
                  Logout
                </MenuItem>
              </Menu>

              <p
                onClick={() => {
                  navigate(PROFILE);
                }}>
                Edit Profile
              </p>
            </section>
          </div>
        ) : (
          <Button
            iconName={'person'}
            onClick={() => {
              navigate(LOGIN);
            }}>
            Login / Sign Up
          </Button>
        )}
      </section>
    </header>
  );
}
