import { PageBanner, Label, ActivitiesCard, StepperList } from 'app/components';
import styles from './article-detail.module.scss';
import ArticleBackground from 'app/libs/assests/article-background.png';
import { Grid } from '@mui/material';
import GlobalKidsHand from 'app/libs/assests/global-kids-hands.png';
import ArticlePic from 'app/libs/assests/article-pic.png';
import { ArticleContainer, CommentSection } from './components';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector } from 'react-redux';

export const ArticleDetail = () => {
  const stepper = ['PAGES', 'ARTICLES', 'ARTICLES DETAILS'];
  const dummyData1 = [1, 2];
  const list = useSelector(selectDirectoryNames);
  return (
    <div className={styles['container']}>
      <PageBanner
        imageSrc={ArticleBackground}
        heading={'Articles'}
        stepperList={stepper}
      />
      <div className={styles['section-container']}>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <div className={styles['left-container']}>
              <div
                className={styles['image-container']}
                style={{ backgroundImage: `url(${ArticlePic})` }}>
                <div className={styles['image-overlay']}></div>
              </div>
              <div className={styles['stepper-container']}>
                <StepperList list={list} itemName='name' itemKey='id' />
              </div>
              <ArticleContainer />
            </div>
            <CommentSection />
          </Grid>
          <Grid item md={3}>
            <div className={styles['header-container']}>
              <Label>Recent Article</Label>
            </div>
            <Grid container rowGap={2}>
              {dummyData1.map((el) => {
                return (
                  <Grid item md={12}>
                    <ActivitiesCard
                      imgSrc={GlobalKidsHand}
                      author={'Shivani Thakur'}
                      isBlog
                      date={'17 Aug, 2021'}
                      commentCounts={'3'}
                      heading={
                        'The passage experienced a surge in popularity during the 1960s.'
                      }
                      label={
                        'Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
