import React, { useState } from 'react';
import {
  Button,
  CardActions,
  CardBody,
  StepperList,
  CardHeader,
} from 'app/components';
import styles from './manage-product.form.module.scss';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import { AddProductInput } from '../add-product-input/add-product-input';
const initialValue = {
  id: 1,
  product_name: { value: '', error: false, required: true },
  services_tag: { value: [], error: false },
};

export const ManageProductForm = ({ hideHeader = false }) => {
  const [product, setProduct] = useState([{ ...initialValue }]);
  const [disabled, setDisabled] = useState(true);
  const list = useSelector(selectDirectoryNames);
  const AddMore = ({ addMore, label }) => {
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          addMore((prev) => {
            return [
              ...prev,
              { ...initialValue, id: prev[prev.length - 1]?.id + 1 || 1 },
            ];
          });
        }}>
        + {label}
      </p>
    );
  };
  const handleChange = (value, key, id) => {
    setProduct((prev) => {
      const form = [...prev];
      form.forEach((data) => {
        if (data.id === id) {
          data[key] = { ...data[key], value, error: !value };
        }
      });
      return form;
    });
  };
  return (
    <>
      <div className={styles['product-container']}>
        {!hideHeader && (
          <CardHeader
            heading={'Manage Product'}
            subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
            mainHeader
            hideHelperText={false}
          />
        )}
        <CardBody>
          <div className={styles['message-container']}>
            <Button btnType='green'>Approved</Button>
            <div className={styles['status-style']}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's
            </div>
          </div>
          <div className={styles['stepper-container']}>
            <StepperList list={list} itemName='name' itemKey='id' />
            <section>
              <Button btnType='red' Component={EditIcon}>
                Edit
              </Button>
            </section>
          </div>

          <section style={{ paddingRight: disabled ? '0' : '45px' }}>
            {product.map((data, index) => {
              const props = { disabled: disabled, id: data.id };
              if (index === 0) {
                props.label1 = 'Product Name';
                props.label2 = 'Search Tag';
              } else {
                props.onDelete = () => {
                  setProduct((prev) => {
                    return [...prev].filter(
                      (product) => product.id !== data.id
                    );
                  });
                };
              }
              return (
                <AddProductInput
                  key={`${data.id}`}
                  productValue={data.product_name.value}
                  productError={data.product_name.error}
                  serviceValue={data.services_tag.value}
                  onChange={handleChange}
                  ser={data.product_name.error}
                  {...props}
                />
              );
            })}
            {!disabled && <AddMore label={'Add More'} addMore={setProduct} />}
          </section>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            onClick={() => {
              setDisabled((prev) => !prev);
            }}
            Component={EditIcon}
            style={{ marginRight: '14px' }}>
            Edit
          </Button>
          <Button
            btnType='secondary'
            Component={DeleteIcon}
            style={{ marginRight: '14px' }}>
            Delete
          </Button>
          <Button btnType='secondary' Component={ReviewIcon}>
            Submit For Review
          </Button>
        </CardActions>
      </div>
    </>
  );
};
