import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/log-in/login';
import SetPassword from './pages/auth/set-password/set-password';
import ForgotPassword from './pages/auth/forgot-password/forgot-password';
import SignUp from './pages/auth/sign-up/sign-up';
import FeatureInfo from './pages/basic-information/basic-info';
import ManageAnnouncement from './pages/manage-announcement/manage-announcement';
import AddAnnouncement from './pages/add-announcement/add-announcement';
import { AddService } from './pages/add-service/add-service';
import { ManageService } from './pages/manage-service/manage-service';
import { AddServiceMain } from './pages/add-service/add-service-main';
import { AddActivity } from './pages/activity/add-activity';
import { ManageActivity } from './pages/activity/manage-activity';
import { AddProduct } from './pages/product/add-product';
import { AppLayout } from './layout';
import { ManageProduct } from './pages/product/manage-product';
import { AddInitiative } from './pages/initiative/add-initiative';
import { ManageInitiative } from './pages/initiative/manage-initiative';
import { Home } from './pages/home/home';
import { Directory } from './pages/directory/directory';
import { DirectoryList } from './pages/directory-list/directory-list';
import { DirectoryMap } from './pages/directory-map/directory-map';
import { Profile } from './pages/profile/profile';
import { Dashboard } from './pages/dashboard/dashboard';
import { AddArticle } from './pages/article/add-article';
import { ManageArticle } from './pages/article/manage-article';
import { ArticleListing } from './pages/article-listing/article-listing';
import { AnnouncementList } from './pages/announcement-list/announcement-list';
import { ArticleDetail } from './pages/article-detail/article-detail';
import { Terms } from './pages/terms-conditions/terms';

import {
  ADD_SERVICE,
  ADD_ANNOUNCEMENT,
  MANAGE_ANNOUNCEMENT,
  BASIC_INFORMATION,
  FORGOT_PASSWORD,
  SIGN_UP,
  LOGIN,
  ADD_SERVICE_MAIN,
  MANAGE_SERVICE,
  ADD_ACTIVITY,
  MANAGE_ACTIVITY,
  ADD_PRODUCT,
  MANAGE_PRODUCT,
  ADD_INITIATIVE,
  MANAGE_INITIATIVE,
  DIRECTORY,
  DIRECTORY_LIST,
  DIRECTORY_MAP,
  PROFILE,
  DASHBOARD,
  ADD_ARTICLE,
  MANAGE_ARTICLE,
  ARTICLE_LISTING,
  ANNOUNCEMENT_LIST,
  ARTICLE_DETAIL,
  TERMS,
  HOME,
  SET_PASSWORD,
} from 'app/libs/constants/tab-routes';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<AppLayout removesideMargin />}>
        <Route path={HOME} element={<Home />} />
        <Route path={`${DIRECTORY}/:directory_id`} element={<Directory />} />
        <Route path={DIRECTORY_LIST} element={<DirectoryList />} />
        <Route path={DIRECTORY_MAP} element={<DirectoryMap />} />
        <Route path={ARTICLE_LISTING} element={<ArticleListing />} />
        <Route path={ANNOUNCEMENT_LIST} element={<AnnouncementList />} />
        <Route path={ARTICLE_DETAIL} element={<ArticleDetail />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<SignUp />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={SET_PASSWORD} element={<SetPassword />} />
      </Route>

      <Route path='/' element={<AppLayout />}>
        <Route path={BASIC_INFORMATION} element={<FeatureInfo />} />
        <Route path={MANAGE_ANNOUNCEMENT} element={<ManageAnnouncement />} />
        <Route path={ADD_ANNOUNCEMENT} element={<AddAnnouncement />} />
        <Route path={ADD_SERVICE} element={<AddService />} />
        <Route path={ADD_SERVICE_MAIN} element={<AddServiceMain />} />
        <Route path={MANAGE_SERVICE} element={<ManageService />} />
        <Route path={ADD_ACTIVITY} element={<AddActivity />} />
        <Route path={MANAGE_ACTIVITY} element={<ManageActivity />} />
        <Route path={ADD_PRODUCT} element={<AddProduct />} />
        <Route path={MANAGE_PRODUCT} element={<ManageProduct />} />
        <Route path={ADD_INITIATIVE} element={<AddInitiative />} />
        <Route path={MANAGE_INITIATIVE} element={<ManageInitiative />} />
        <Route path={PROFILE} element={<Profile />} />
        <Route path={DASHBOARD} element={<Dashboard />} />
        <Route path={ADD_ARTICLE} element={<AddArticle />} />
        <Route path={MANAGE_ARTICLE} element={<ManageArticle />} />
        <Route path={TERMS} element={<Terms />} />
      </Route>
    </Routes>
  );
}
