// import styles from './add.module.scss';
import { Grid } from '@mui/material';
import {
  Button,
  TextArea,
  Card,
  CardHeader,
  CardBody,
  CardActions,
  DateTextInput,
  Select,
} from 'app/components';
import { useNavigate } from 'react-router-dom';
import { ANNOUNCEMENT_LIST } from 'app/libs/constants/tab-routes';

export const AddAnnouncement = () => {
  const navigate = useNavigate();
  return (
    <div>
      <CardHeader
        heading={'Add Announcement'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardHeader heading={'Organic Mandiya'} helperText={''} />
        <CardBody>
          <Select
            label={'Type'}
            placeholder={'Select announcement type'}
            subContent={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
            }
          />
          <TextArea
            label={'Announcement'}
            value='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            subContent={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
            }
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateTextInput
                label={'Start Date'}
                subContent={'Lorem ipsum dolor sit amet'}
              />
            </Grid>
            <Grid item md={6}>
              <DateTextInput
                label={'End Date'}
                subContent={'Lorem ipsum dolor sit amet'}
              />
            </Grid>
          </Grid>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            style={{ marginRight: '14px' }}
            onClick={() => {
              navigate(-1);
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              navigate(ANNOUNCEMENT_LIST);
            }}>
            Save
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddAnnouncement;
