import { TextInput, RadioGroup, CardBody, CardHeader } from 'app/components';

export const BasicInformation = ({ form, setForm, error = {} }) => {
  const onHandleChange = (value, key) => {
    setForm((prev) => ({ ...prev, [key]: { ...prev[key], value } }));
  };

  return (
    <>
      <CardHeader
        heading={'Basic Information'}
        hideHelperText
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
        }
      />
      <CardBody>
        <RadioGroup
          list={[
            { name: 'Business', id: 'Business' },
            { name: 'NGO', id: 'NGO' },
            { name: 'Consultant', id: 'Consultant' },
          ]}
          label={'Directory Type'}
          required
          selected={form.directory_type.value}
          error={!!error.directory_type}
          errorMessage={error.directory_type}
          onChange={(value) => {
            onHandleChange(value, 'directory_type');
          }}
        />
        <div style={{ marginTop: '5px' }}></div>
        <TextInput
          label={'Directory Name'}
          required
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          placeholder='Here can be directory Name'
          value={form.directory_name.value}
          onChange={(e) => {
            onHandleChange(e.target.value, 'directory_name');
          }}
          error={!!error.directory_name}
          errorMessage={error.directory_name}
        />
      </CardBody>
    </>
  );
};
export default BasicInformation;
