import { createSlice } from '@reduxjs/toolkit';
import { initialState, addressForm1Schema } from '../model';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { basicInfoFromSaga } from './basic-info.saga';

const slice = createSlice({
  name: 'basicInfo',
  initialState,
  reducers: {
    submitDirectory(state, action) {},
    loadLocationDetails(state, action) {},
    loadedLocationDetails(state, action) {},
    loadWardList(state, action) {},
    updateLocationForm(state, action) {
      state.locationForm = [...action.payload];
    },
    deleteLocationForm(state, action) {
      state.locationForm = [...action.payload];
    },
    addLocationForm(state, action) {
      const prev = [...state.locationForm];
      state.locationForm = [...prev, action.payload];
    },
    resetLocationForm(state) {
      state.locationForm = [{ ...addressForm1Schema, id: 1 }];
    },
    loadPlaceSuggestion(state, action) {},
    loadedPlaceSuggestion(state, action) {
      state.placeSuggestionlist = action.payload;
    },
    resetOnAddressChange(state, action) {},
    updateErrors(state, action) {
      state.error = action.payload;
    },
  },
});
export const { actions: basicInfoActions, reducer } = slice;

export const useBasicInfoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: basicInfoFromSaga });
  return { actions: slice.actions };
};
