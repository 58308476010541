import * as React from 'react';
import styles from './footer.module.scss';
import { Grid } from '@mui/material';
import { Button } from '../button/button';
import { TextInput } from '../index';
import facebook from 'app/libs/assests/facebook.svg';
import instagram from 'app/libs/assests/instagram.svg';
import twitter from 'app/libs/assests/twitter.svg';

export function Footer({
  label,
  placeholder = 'Please enter the field',
  iconName,
  ...props
}) {
  return (
    <>
      <section className={styles['footer-banner']}>
        <Grid container>
          <Grid item md={4}>
            <div className={styles['About-section']}>
              <h6>About us</h6>
              <p>
                Urjacity vision is to connect green and sustainable initiatives
                to catalyze urban sustainable development.
              </p>
              <Grid
                container
                alignItems={'flex-start'}
                spacing={2}
                style={{ marginTop: '20px' }}>
                <Grid item>
                  <TextInput placeholder={'Enter email address'} />
                </Grid>
                <Grid item>
                  <Button style={{ height: '36px' }} fullWidth>
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles['social-section']}>
              <h6>Follow us on social media</h6>
              <span id={styles['img-section']}>
                <img src={facebook} alt={'facebook'} />
              </span>
              <span id={styles['img-section']}>
                <img src={instagram} alt={'instagram'} />
              </span>
              <span id={styles['img-section']}>
                <img src={twitter} alt={'twitter'} />
              </span>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles['contact-section']}>
              <h6>Contact</h6>
              <p>contact us : http://urjacity.logistack.com/</p>
              <p>contact@urjacity.com</p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles['link-section']}>
              <h6>Quick Links</h6>
              <ul>
                <li>About Us</li>
                <li>Webinar Live</li>
                <li>Sitemap</li>
                <li>Contact Us</li>
                <li>Privacy Policy</li>
                <li>Terms & Conditions</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
