import styles from './activities-card.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { Button } from '../';

export const ActivitiesCard = ({
  onCourse,
  OnEnroll,
  onEnquiry,
  onManage,
  heading,
  label,
  imgSrc,
  isBlog = false,
  author,
  commentCounts,
  date,
  onClick,
  ...props
}) => {
  return (
    <div
      className={styles['container']}
      data-card={`${isBlog}`}
      onClick={onClick}>
      <section className={styles['image-container']}>
        <img src={imgSrc} alt='leleo' />
        {onCourse && (
          <section className={styles['image-btn']}>
            <Button onClick={onCourse}>Course</Button>
          </section>
        )}
      </section>
      {isBlog && (
        <section className={styles['comment-section']}>
          <span>By: {author}</span> <span>{date}</span>
          <span>{commentCounts} Comments</span>
        </section>
      )}
      <h1>{heading}</h1>
      <p>{label}</p>
      {(onManage || OnEnroll) && (
        <section className={styles['btn-container']}>
          {!isBlog && (
            <Button style={{ marginRight: '10px' }} onClick={OnEnroll}>
              Enroll
            </Button>
          )}
          <Button Component={EditIcon} btnType='red' onClick={onManage}>
            Manage
          </Button>
        </section>
      )}
    </div>
  );
};
