import { DirectoryRow, StatusRow } from '../components';
import Data from 'data/dashboard.json';

export const userNormalHeader = [
  { key: 'userName', label: 'User Name' },
  { key: 'city', label: 'City' },
  { key: 'ward', label: 'Ward' },
  { key: 'mobileNumber', label: 'Mobile Number' },
  { key: 'emailId', label: 'Email Id' },
];
export const userDirectoryHeader = [
  { key: 'userName', label: 'User Name' },
  { key: 'city', label: 'City' },
  { key: 'ward', label: 'Ward' },
  { key: 'mobileNumber', label: 'Mobile Number' },
  { key: 'emailId', label: 'Email Id' },
  { key: 'directory', label: 'Directory', Component: DirectoryRow },
  { key: 'status', label: 'Status', Component: DirectoryRow },
];
export const DirectoryNormalHeader = [
  { key: 'directoryName', label: 'Directory Name' },
  { key: 'userName', label: 'User Name' },
  { key: 'mobileNumber', label: 'Mobile Number' },
  { key: 'emailId', label: 'Email Id' },
  { key: 'view', label: 'View' },
  { key: 'call', label: 'Call' },
  { key: 'email', label: 'Email' },
  { key: 'vol', label: 'Volunteers' },
  { key: 'activity', label: 'Activity' },
  { key: 'artView', label: 'Article View' },
  { key: 'artComm', label: 'Article Comments' },
  { key: 'status', label: 'Status', Component: StatusRow },
];
export const DirectoryDirectoryHeader = [
  { key: 'directoryName', label: 'Directory Name' },
  { key: 'userName', label: 'User Name' },
  { key: 'mobileNumber', label: 'Mobile Number' },
  { key: 'emailId', label: 'Email Id' },
  { key: 'status', label: 'Status', Component: StatusRow },
];

export const normalUserList = [
  {
    name: 'Normal Users',
    id: 'normalUsers',
    textType: 'bold',
    sublabel: 'Non active users',
  },
  {
    name: 'Directory users',
    id: 'directoryUsers',
    textType: 'bold',
    sublabel: 'Active users',
  },
];
export const DirectoryUserList = [
  {
    name: 'Normal View',
    id: 'normalView',
    textType: 'bold',
    sublabel: 'Leads',
  },
  {
    name: 'Directory Status View',
    id: 'directoryView',
    textType: 'bold',
    sublabel: 'lorem ipsum',
  },
];

export const getHeaders = (dashboardType, userType) => {
  if (dashboardType === 'user') {
    switch (userType) {
      case 'normalUsers':
        return userNormalHeader;
      case 'directoryUsers':
        return userDirectoryHeader;
      default:
        return [];
    }
  } else {
    switch (userType) {
      case 'normalView':
        return DirectoryNormalHeader;
      case 'directoryView':
        return DirectoryDirectoryHeader;
      default:
        return [];
    }
  }
};

export const getData = (dashboardType, userType, showStatus, status) => {
  const result = Data?.[dashboardType]?.[userType];
  let data = result?.data || [];
  console.log(data, status);
  if (showStatus) {
    data = [...data].filter((el) => el.status === status);
  }
  return { data, total: result?.total };
};

export const getUserList = (dashboardType) => {
  switch (dashboardType) {
    case 'user':
      return normalUserList;
    case 'directory':
      return DirectoryUserList;
    default:
      return [];
  }
};
