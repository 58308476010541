import { Label, PageHeader, RadioGroup } from 'app/components';
import { Table } from './components/table/table';
import styles from './dashboard.module.scss';
import { getHeaders, getData, getUserList } from './helper';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const Dashboard = () => {
  const [dashboardType, setDashBoardType] = useState('user');
  const [activeTab, setActiveTab] = useState(0);
  const [userType, setUserType] = useState('normalUsers');
  const [status, setStatus] = useState('Published');
  const showStatus =
    dashboardType === 'directory' && userType === 'directoryView';
  const data = getData(dashboardType, userType, showStatus, status);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setDashBoardType((prev) => (newValue === 0 ? 'user' : 'directory'));
    setUserType((rev) => (newValue === 0 ? 'normalUsers' : 'normalView'));
  };
  return (
    <div>
      <PageHeader
        heading={'Dashboard'}
        subHeading={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
        }
      />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor={'secondary'}
        sx={{
          '& .Mui-selected': { color: '#ff9801 !important' },
          '& .MuiTabs-indicator': { background: '#ff9801' },
        }}>
        <Tab label='User' />
        <Tab label='Directory' />
      </Tabs>

      {/* <div className={styles['radio-container-1']}>
        <RadioGroup
          list={[
            {
              name: 'User',
              id: 'user',
            },
            {
              name: 'Directory',
              id: 'directory',
            },
          ]}
          isRadioButton={false}
          selected={dashboardType}
          onChange={(value) => {
            const userType = value === 'user' ? 'normalUsers' : 'normalView';
            setUserType(userType);
            setDashBoardType(value);
          }}
        />
      </div> */}
      <div className={styles['main-content-container']}>
        <RadioGroup
          list={getUserList(dashboardType)}
          selected={userType}
          onChange={(value) => {
            setUserType(value);
          }}
        />
        {showStatus && (
          <div className={styles['radio-container']}>
            <RadioGroup
              list={[
                {
                  name: 'Published',
                  id: 'Published',
                  textType: 'bold',
                },
                {
                  name: 'Pending Approval',
                  id: 'Pending Approval',
                  textType: 'bold',
                },
              ]}
              selected={status}
              onChange={(value) => setStatus(value)}
            />
          </div>
        )}
        <Label textlabel>
          Total {dashboardType === 'user' ? 'Users' : 'Directories'}:{' '}
          {data?.total || ''}
        </Label>
        <Table
          header={getHeaders(dashboardType, userType)}
          data={data?.data || []}
        />
      </div>
    </div>
  );
};
