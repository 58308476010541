import { Card, CardHeader } from 'app/components';
import { ManageActivityForm } from './components';
export const ManageActivity = ({ disabled, label }) => {
  return (
    <div>
      <CardHeader
        heading={'Manage Activity'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <ManageActivityForm hideHider />
      </Card>
    </div>
  );
};
export default ManageActivity;
