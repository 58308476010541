import { Grid } from '@mui/material';
import { Button, ImageUpload, Modal, TextInput } from 'app/components';
import styles from './edit-profile.module.scss';
import { useState } from 'react';

export const EditProfileModal = ({ open, handleClose }) => {
  const [files, setFiles] = useState([]);
  return (
    <Modal open={open} handleClose={handleClose} label='Edit Profile'>
      <Grid container columnSpacing={{ md: 4 }}>
        <Grid item md={6}>
          <TextInput
            label={'First Name'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'Last Name'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'Mobile Number'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'Email Address'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={12}>
          <TextInput
            label={'Address Line 1'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={12}>
          <TextInput
            label={'Address Line 2'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'Pincode'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ md: 4 }}>
        <Grid item md={6}>
          <TextInput
            label={'Country'}
            disabled
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'State'}
            disabled
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'City'}
            disabled
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            label={'Ward'}
            subContent={'Lorem ipsum dolor sit amet, '}
          />
        </Grid>
        <Grid item md={12}>
          <ImageUpload
            label={'Media'}
            subContent={'Lorem ipsum dolor sit amet, '}
            onUpload={(e) => {
              if (e?.target?.files?.[0]) {
                const file = e?.target?.files?.[0];
                setFiles((prev) => [...prev, file]);
              }
            }}
            hideUpload={files.length === 1}
            imageList={files}
            onDelete={(index) => {
              setFiles((prev) => [
                ...[...prev].slice(0, index),
                ...[...prev].slice(index + 1),
              ]);
            }}
          />
        </Grid>
      </Grid>
      <div className={styles['btn-container']}>
        <Button btnType='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button>Save</Button>
      </div>
    </Modal>
  );
};
