import { Label, TextArea, Button } from 'app/components';
import styles from './comment-section.module.scss';
import { Comments } from '../';

export const CommentSection = () => {
  const commentList = [
    {
      name: 'Rakesh Kumar Sharma',
      date: '20th April, 2022',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation`,
    },
    {
      name: 'Apurba Ghosh',
      date: '19th April, 2022',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation`,
    },
    {
      name: 'Sunil Kumar Karmakar',
      date: '10th April, 2022',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation`,
    },
    {
      name: 'Apurba Ghosh',
      date: '2nd April, 2022',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation`,
    },
  ];
  return (
    <div className={styles['container']}>
      <div className={styles['header-container']}>
        <Label>Comments</Label>
        <p className={styles['sub-content']}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
        </p>
      </div>
      <TextArea
        label={'Your Comment'}
        subContent={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
        }
        value={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aut.`}
      />
      <div className={styles['btn-container']}>
        <Button>Post Comment</Button>
      </div>
      <section>
        {commentList.map((list) => {
          return (
            <Comments
              name={list.name}
              date={list.date}
              content={list.content}
            />
          );
        })}
      </section>
    </div>
  );
};
