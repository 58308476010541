import styles from './image-upload.module.scss';
import { Label, TextError } from '..';
import AddIcon from 'app/libs/assests/add-icon.svg';
export const ImageUpload = ({
  onUpload,
  hideUpload = false,
  disabled,
  onDelete,
  imageList = [],
  multiple = false,
  error,
  errorMessage,
  label,
  required,
  subContent,
  keyName,
  fileType,
  gridSize = 4,
  ...props
}) => {
  return (
    <div className={styles['container']}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <div
        className={styles['grid-layout']}
        style={{ '--layout-size': gridSize }}>
        {imageList.map((file, index) => {
          if (file.value) {
            const image = URL.createObjectURL(file.value);
            return (
              <section className={styles['image-container']} key={file.id}>
                <img src={image} alt='kite-1' />
                {!disabled && (
                  <i
                    className='material-icons'
                    onClick={() => onDelete(keyName, file.id)}>
                    delete
                  </i>
                )}
              </section>
            );
          } else return <></>;
        })}
        {!hideUpload && (
          <div className={styles['file-area']} data-disabled={`${disabled}`}>
            <input
              type='file'
              value={''}
              onChange={onUpload}
              multiple={multiple}
              disabled={disabled}
              accept={fileType}
            />
            <section>
              <img src={AddIcon} alt={'add'} />
            </section>
          </div>
        )}
      </div>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
};