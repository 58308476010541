import styles from './customer-review.module.scss';
import UserIcon from 'app/libs/assests/shivani-thakur.png';
import { Grid } from '@mui/material';

export const CustomerReview = () => {
  const dummyData = [1, 2, 3];
  return (
    <div className={styles['container']}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <section className={styles['left-container']}>
            <span>our testimonails</span>
            <p>Happy Customers</p>
            <div className={styles['user-container']}>
              {dummyData.map((el) => {
                return (
                  <div data-active={`${el === 1}`}>
                    <section className={styles['image-container']}>
                      <img src={UserIcon} alt='user' />
                      <span>“</span>
                    </section>
                    <p>Shirley Smith</p>
                    <span>Director</span>
                  </div>
                );
              })}
            </div>
          </section>
        </Grid>
        <Grid item md={6}>
          <div className={styles['right-container']}>
            <div>
              <span data-active='true'></span>
              <span></span>
              <span></span>
            </div>
            “This is due to their excellent service competitive pricing and
            customer support. It’s throughly refresing to get such a personal
            touch. There are many variations of passages of available, but the
            majority have suffered alteration in some form by injected humour.”
            25 Nov, 2019
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
