import { createSelector } from '@reduxjs/toolkit';
import { initialState } from '../model';
const selectDomain = (state) => state.directory || initialState;

export const selectDirectoryBasic = createSelector(
  [selectDomain],
  (directory) => directory.directoryBasic
);
export const selectDirectoryController = createSelector(
  [selectDomain],
  (directory) => directory.directoryController
);
export const selectDirectoryImage = createSelector(
  [selectDomain],
  (directory) => directory.directoryImage
);
export const selectDirectoryImageController = createSelector(
  [selectDomain],
  (directory) => directory.directoryImageController
);
export const selectDirectoryServices = createSelector(
  [selectDomain],
  (directory) => directory.directoryServices
);

export const selectDirectoryProducts = createSelector(
  [selectDomain],
  (directory) => directory.directoryProducts
);
