import { directoryActions as actions } from './directory.slice';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getDirectoryData,
  getDirectoryImage,
  getDirectoryServices,
  getDirectoryProductList,
} from '../services';
import { snackbarActions } from 'app/shared/feature-snackbar/slice/feature-snackbar.slice';

export function* loadDirectory(action) {
  try {
    const response = yield call(getDirectoryData, action.payload);

    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      yield put(actions.loadedDirectoryBasic(response.data_block?.[0]));
      yield put(actions.loadDirectorySecondaryData(action.payload));
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* loadDirectoryImage(action) {
  try {
    const response = yield call(getDirectoryImage, action.payload);

    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      yield put(actions.loadedDirectoryImageData(response.data_block?.[0]));
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}
export function* loadDirectoryServices(action) {
  try {
    const response = yield call(getDirectoryServices, action.payload);

    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      yield put(actions.loadedDirectoryServices(response?.data_block));
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}
export function* loadDirectoryProducts(action) {
  try {
    const response = yield call(getDirectoryProductList, action.payload);

    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      yield put(actions.loadedDirectoryProducts(response?.data_block));
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}

export function* directorySaga() {
  yield takeLatest(actions.loadDirectoryBasic.type, loadDirectory);
  yield takeLatest(actions.loadDirectorySecondaryData.type, loadDirectoryImage);
  yield takeLatest(
    actions.loadDirectorySecondaryData.type,
    loadDirectoryProducts
  );
  yield takeLatest(
    actions.loadDirectorySecondaryData.type,
    loadDirectoryServices
  );
}
