import { Card, CardHeader } from 'app/components';
import { ManageInitiativeForm } from './components';
export const ManageInitiative = () => {
  return (
    <div>
      <CardHeader
        heading={'Manage Initiative'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <ManageInitiativeForm hideHeader />
      </Card>
    </div>
  );
};
export default ManageInitiative;
