import { StatusChip } from 'app/components';
import styles from './directory-row.module.scss';

export const DirectoryRow = ({ data = {}, accessLabel = '', ...props }) => {
  return (
    <>
      {data?.[accessLabel].map((directory) => {
        return (
          <div className={styles['container']}>
            {accessLabel === 'status' ? (
              <StatusChip label={directory} type={directory} />
            ) : (
              directory
            )}
          </div>
        );
      })}
    </>
  );
};
