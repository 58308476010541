import styles from './terms.module.scss';
export const Terms = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['header-container']}>
        <h3>Terms and Conditions</h3>
        <span className={styles['term-date']}>Updated on 21 July 2022</span>
      </div>
      <div>
        <p>
          Welcome to Urjacity! www.urjacity.com ("the Site") is owned and
          managed by Urjacity Education Services India Pvt. Ltd ("Urjacity") in
          Bangalore, India with registered office at B1/S1 Ganga Chelston,
          Silver Spring Road, Varthur Road, Munnekolala, Bangalore – 560 037,
          Karnataka, INDIA. By registering and accessing the Site you form a
          legally binding agreement with Urjacity based on the following terms
          and conditions. Urjacity reserves the right to change the terms and
          conditions under which the Site is offered without prior notice. As a
          user of the Site you would be bound by the terms and conditions
          prevailing at that time set forth in this Terms of Use. Urjacity makes
          no representation or warranties as to the completeness, accuracy or
          reliability, of any information on the Site. Urjacity cannot guarantee
          that access to the Site will be uninterrupted or error-free. Urjacity
          may restrict use of, terminate or suspend the Site, or any part of the
          Site, at any time and without notice to any user. Any content on the
          Site is subject to change without notice.
        </p>
      </div>
      <div>
        <h4>1. Your personal information</h4>
        <p>
          Any personal information you supply to Urjacity when you use the Site
          will be used in accordance with Urjacity's Privacy Policy.
        </p>
      </div>
      <div>
        <h4>2. Proprietary rights</h4>
        <p>
          All trade marks, contents and design of the Site, database rights and
          other intellectual property rights in the materials on the Site
          together with the underlying software code are owned either directly
          by Urjacity or by its licensors. Without Urjacity's prior written
          permission, you may not use or reproduce or allow anyone to use or
          reproduce any trade marks (such as "Urjacity" name and logo or other
          trade names appearing on the Site). You may not copy, modify, reverse
          engineer or otherwise deal with the underlying software code whether
          in whole or in part. You may not sell or transfer any material on the
          Site whether in whole or in part. However, you may download, print or
          copy contents of this web site for your personal non-commercial use..
        </p>
      </div>
      <div>
        <h4>3. Acceptable Use of Urjacity content</h4>
        <p>
          You may only use the Site in accordance with these Terms of Use and,
          in any event, for lawful and proper purposes which includes complying
          with all applicable laws, regulations and codes of practice within
          India or other jurisdiction from which you are accessing the Site.
        </p>
      </div>
      <div>
        <h4>4. User-created content</h4>
        <p>
          The Site may provide feature that allows users to share their opinion
          with other users and Urjacity representatives in publicly accessible
          areas of the Site ("Forums"). You agree that you are solely
          responsible for any content that you post in Forums. Urjacity does not
          control the information/materials posted to Forums by users. If
          Urjacity believes that the information provided by you is
          inappropriate for the Site, may create liability for Urjacity, or
          other suppliers or partners, Urjacity reserves the right (which it may
          exercise at its sole discretion without notice) to delete, move or
          edit the information/material and/or to deny you access to the Forum
        </p>
      </div>
      <div>
        <h4>5. Confidentiality and Indemnification</h4>
        <p>
          All trade marks, contents and design of the Site, database rights and
          other intellectual property rights in the materials on the Site
          together with the underlying software code are owned either directly
          by Urjacity or by its licensors. Without Urjacity's prior written
          permission, you may not use or reproduce or allow anyone to use or
          reproduce any trade marks (such as "Urjacity" name and logo or other
          trade names appearing on the Site). You may not copy, modify, reverse
          engineer or otherwise deal with the underlying software code whether
          in whole or in part. You may not sell or transfer any material on the
          Site whether in whole or in part. However, you may download, print or
          copy contents of this web site for your personal non-commercial use..
        </p>
      </div>
    </div>
  );
};
