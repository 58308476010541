import { Grid } from '@mui/material';
import { DirectoryCard, ConsultantCard } from './components';
import { ActivitiesCard, Pagination, Label, Button } from 'app/components';
import garden from 'app/libs/assests/gardening-pic.png';
import styles from './directory-list.module.scss';
import { ReactComponent as LocationIcon } from 'app/libs/assests/location-icon.svg';
import { ReactComponent as FilterIcon } from 'app/libs/assests/filter-icon.svg';

export const DirectoryList = () => {
  const dummyData = [1, 2, 3];

  return (
    <div style={{ padding: '50px 30px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <section className={styles['left-container']}>
            <div className={styles['header-container']}>
              <Label>32 Entites</Label>
              <Label>10 Kms</Label>
              <div>
                <Button Component={FilterIcon}>Filter</Button>
                <Button Component={LocationIcon}>Map View</Button>
              </div>
            </div>
            <div className={styles['list-container']}>
              {dummyData.map((el, index) => {
                return (
                  <DirectoryCard
                    directoryType={index % 2 === 0 ? 'Business' : 'NGO'}
                  />
                );
              })}
            </div>
            <Pagination count={10} />
          </section>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={styles['list-container']}>
            <Label>Recent Articles</Label>
            {dummyData.map((el) => {
              return (
                <ActivitiesCard
                  isBlog
                  imgSrc={garden}
                  commentCounts={3}
                  author={'Shivani Thakur'}
                  date={'17 Aug, 2021'}
                  heading='The passage experienced a surge in popularity during the 1960s.'
                  label='Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
                />
              );
            })}
          </div>
          <div
            className={styles['list-container']}
            style={{ marginTop: '20px' }}>
            <Label>Consultants</Label>
            {dummyData.map((el) => {
              return <ConsultantCard />;
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
