import { addProductActions as actions } from './product.slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addProductApi } from '../services';
import { DIRECTORY, LOGIN } from 'app/libs/constants/tab-routes';

export function* addProducts(action) {
  try {
    const response = yield call(addProductApi, {
      data: action.payload.data,
      directory_id: action.payload.directory_id,
    });
    //if message block status is true and there's no message type then we are throwing an error
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));

        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    const controlKeysAvailable =
      'status' in response.control_block &&
      'login_needed' in response.control_block;

    if (controlKeysAvailable) {
      if (response?.control_block?.status) {
        //navigate to directory
        action.payload.navigate(`${DIRECTORY}/${action.payload.directory_id}`);
      } else if (response.control_block.login_needed) {
        //navigate to login
        action.payload.navigate(LOGIN);
      } else {
        throw new Error('Something Went wrong');
      }
    } else if (!controlKeysAvailable) {
      throw new Error('Something went wrong');
    }
  } catch (error) {}
}

export function* addProductFromSaga() {
  yield takeLatest(actions.addProduct.type, addProducts);
}
