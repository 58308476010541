import { Label } from 'app/components';
import styles from './page-header.module.scss';

export const PageHeader = ({ heading, subHeading }) => {
  return (
    <div className={styles['container']}>
      <Label>{heading}</Label>
      <span>{subHeading}</span>
    </div>
  );
};
