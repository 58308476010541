import { useState } from 'react';
import styles from './manage-service.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  TextArea,
  CardHeader,
  ImageUpload,
  ServiceHeader,
} from 'app/components';
import { ManageServiceForm } from './components/manage-service-form';
export const ManageService = () => {
  const [files, setFiles] = useState([]);
  const [specifications, setSpecifications] = useState([1]);
  const [services, setServices] = useState([1]);
  const [isDisabled, setIsDisabled] = useState(true);
  const list = ['Services', 'Environmetal', 'climate change', 'outdoor-plant'];
  const onDelete = (index) => {
    setFiles((prev) => {
      const newFiles = [
        ...[...prev].slice(0, index),
        ...[...prev].slice(index + 1),
      ];
      return newFiles;
    });
  };
  const AddMore = ({ addMore, label }) => {
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          addMore((prev) => {
            return [...prev, prev.length + 1];
          });
        }}>
        + {label}
      </p>
    );
  };
  return (
    <div>
      <CardHeader
        heading={'Manage Service'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card>
        <ManageServiceForm hideHeader />
      </Card>
    </div>
  );
};
export default ManageService;
