import styles from './service-header.module.scss';
import { StepperList, Button } from '../';
import { useNavigate } from 'react-router-dom';
import { ADD_SERVICE } from 'app/libs/constants/tab-routes';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';

export const ServiceHeader = ({ list = [], disabled = false }) => {
  const navigate = useNavigate();
  return (
    <div className={styles['stepper-container']}>
      <StepperList list={list} itemName={'name'} itemKey='id' />
      <section>
        <Button
          btnType='red'
          Component={EditIcon}
          onClick={() => {
            !disabled && navigate(ADD_SERVICE);
          }}
          style={{
            opacity: disabled ? 0 : 1,
            cursor: disabled ? 'default' : 'pointer',
          }}>
          Edit
        </Button>
      </section>
    </div>
  );
};
