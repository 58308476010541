import { homeActions as actions } from './home.slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchCategory } from '../services/home-services';

export function* getCategories(action) {
  try {
    const response = yield call(fetchCategory);
    if (response.status && response.response_type === 'data') {
      yield put(actions.loadedCategory(response.data));
    }
  } catch (error) {}
}

export function* homeFromSaga() {
  yield takeLatest(actions.loadCategory.type, getCategories);
}
