import { Button } from 'app/components';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import styles from './article-container.module.scss';
import { ReactComponent as CalenderIcon } from 'app/libs/assests/calender-icon.svg';
import { ReactComponent as CommentIcon } from 'app/libs/assests/comment-icon.svg';
import { ReactComponent as ShareIcon } from 'app/libs/assests/share-icon.svg';

export const ArticleContainer = () => {
  return (
    <div className={styles['container']}>
      <section className={styles['header-container']}>
        <h3>The passage experienced a surge in popularity during the 1960s</h3>
        <div>
          <Button btnType='red' Component={EditIcon}>
            Manage
          </Button>
        </div>
      </section>
      <section className={styles['author-details']}>
        <span className={styles['author-name']}>By: Shivani Thakur</span>
        <div>
          <span className={styles['article-date']}>
            <CalenderIcon /> 28th May, 2022
          </span>
          <span className={styles['article-comment']}>
            <CommentIcon /> 120 Comments
          </span>
        </div>
      </section>
      <section className={styles['article-content']}>
        The passage experienced a surge in popularity during the 1960s when
        Letraset used it on their dry-transfer sheets, and again during the 90s
        as desktop publishers bundled the text with their software. Today it's
        seen all around the web; on templates, websites, and stock designs. Use
        our generator to get your own, or read on for the authoritative history
        of lorem ipsum
        <br />
        <br />
        Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs. The passage is attributed to
        an unknown typesetter in the 15th century who is thought to have
        scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a
        type specimen book. It usually begins with
      </section>
      <section className={styles['btn-container']}>
        <Button Component={ShareIcon}>Share</Button>
      </section>
    </div>
  );
};
