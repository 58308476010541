import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../model';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { directorySaga } from './directory.saga';

const slice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    loadDirectoryBasic(state, action) {
      state.directoryBasic = {};
      state.directoryController = {};
      state.directoryImage = {};
      state.directoryImageController = {};
      state.directoryServices = { serviceCounter: 0, data: [] };
      state.directoryProducts = { product_counter: 0, data: [] };
    },
    loadedDirectoryBasic(state, action) {
      state.directoryBasic = action.payload.data;
      state.directoryController = action.payload.data_control;
    },
    loadedDirectoryProducts(state, action) {
      state.directoryProducts = action.payload;
    },
    loadDirectorySecondaryData(state, action) {},
    loadedDirectoryImageData(state, action) {
      state.directoryImage = action.payload.data;
      state.directoryImageController = action.payload.data_control;
    },
    loadedDirectoryServices(state, action) {
      state.directoryServices = action.payload;
    },
  },
});
export const { actions: directoryActions, reducer } = slice;

export const useDirectorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: directorySaga });
  return { actions: slice.actions };
};
