import UnicefPic from 'app/libs/assests/unicef-logo.svg';
import styles from './description-card.module.scss';
import React from 'react';

export const DescriptionCard = React.forwardRef(
  (
    { heading = 'About Business', subHeading = 'DESCRIPTION', contnent },
    ref
  ) => {
    return (
      <div className={styles['container']} ref={ref}>
        <section className={styles['header-container']}>
          <img src={UnicefPic} alt='description' />
          <div>
            <p>{subHeading}</p>
            <h3>{heading}</h3>
            <div>
              The passage experienced a surge in popularity during the 1960s
              when Letraset used it on their dry-transfer sheets, and again
              during the 90s as desktop publishers bundled the text with their
              software. Today it's seen all around the web; on templates,
              websites, and stock designs. Use our generator to get your own, or
              read on for the authoritative history of lorem ipsum.. The purpose
              of lorem ipsum is to create a natural looking block of text
              (sentence, paragraph, page, etc.) that doesn't distract from the
              layout. A practice not without controversy, laying out pages with
              meaningless filler text can be very useful when the focus is meant
              to be on design, not content. Until recently, the prevailing view
              assumed lorem ipsum was born as a nonsense text. “It's not Latin,
              though it looks like it, and it actually says nothing,” Before &
              After magazine answered a curious reader, “Its ‘words’ loosely
              approximate the frequency with which letters occur in English,
              which is why at a glance it looks pretty real.” A practice not
              without controversy, laying out pages with meaningless filler text
              can be very useful when the focus is meant to be on design, not
              content. Until recently, the prevailing view assumed lorem ipsum
              was born as a nonsense text. “It's not Latin, though it looks like
              it, and it actually says nothing,” Before & After magazine
              answered a curious reader, “Its ‘words’ loosely approximate the
              frequency with which letters occur in English, which is why at a
              glance it looks pretty real.”
            </div>
          </div>
        </section>
      </div>
    );
  }
);
