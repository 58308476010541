import styles from './text-area.module.scss';
import { TextError, Label } from '../';
import Delete from 'app/libs/assests/delete.svg';
export function TextArea({
  label,
  errorMessage,
  error = false,
  placeholder = 'Please enter the field',
  iconName,
  onIconClick,
  disabled = false,
  Component,
  onDelete,
  rows = 2,
  maxRows = 10,
  subContent,
  required,
  ...props
}) {
  return (
    <div className={`${styles['container']}`}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label} {required && <span>*</span>}
        </Label>
      )}
      <div
        className={`${styles['input-container']}  ${
          disabled ? styles['container-disabled'] : ''
        }`}>
        <textarea placeholder={placeholder} {...props} disabled={disabled} />
        {iconName && (
          <i className='material-icons' onClick={onIconClick}>
            {iconName}
          </i>
        )}
        {Component && <Component />}
        {onDelete && !disabled && (
          <div
            className={styles['delete-icon']}
            onClick={() => {
              onDelete?.();
            }}>
            <img src={Delete} alt='delete' />
          </div>
        )}
      </div>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
}
