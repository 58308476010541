import React, { useState } from 'react';
import styles from './rich-text.module.scss';
import { HtmlEditor, Toolbar, Editor } from '@aeaton/react-prosemirror';
import {
  plugins,
  schema,
  toolbar,
} from '@aeaton/react-prosemirror-config-default';
import { TextError, Label } from '../';

export const RichTextEditor = ({
  label,
  required,
  error,
  errorMessage,
  subContent,
  disabled,
  editorState,
  setEditorState,
}) => {
  return (
    <div className={styles['container']}>
      {label && (
        <Label textlabel subContent={subContent}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <div className={styles['input-container']}>
        <HtmlEditor
          schema={schema}
          plugins={plugins}
          value={editorState}
          handleChange={setEditorState}
          debounce={250}
          placeholder='hello'>
          <Toolbar toolbar={toolbar} />
          <Editor autoFocus />
        </HtmlEditor>
      </div>
      <TextError errorMessage={errorMessage} error={error} />
    </div>
  );
};
