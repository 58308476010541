import { Checkbox as MuiCheckBox } from '@mui/material';
import styles from './checkbox.module.scss';
export const Checkbox = ({
  isChecked,
  onChange,
  label,
  disabled,
  ...props
}) => {
  return (
    <div className={styles['container']}>
      <MuiCheckBox
        checked={isChecked}
        style={{ color: disabled ? '' : '#FF9801' }}
        onChange={onChange}
        sx={{
          '&.MuiCheckbox-root': {
            padding: '0',
          },
        }}
        disabled={disabled}
        {...props}
      />
      <label>{label}</label>
    </div>
  );
};
