import { useState } from 'react';
import styles from './initiative.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  Label,
  TextArea,
  CardHeader,
  ImageUpload,
  StepperList,
  Checkbox,
  TextError,
  TextInputChip,
} from 'app/components';
import { ADD_SERVICE } from 'app/libs/constants/tab-routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector, useDispatch } from 'react-redux';
import { LocationInformation } from 'app/pages/basic-information/components/location-info';
import { selectLocationForm } from '../basic-information/slice/basic-info.selector';
import { useBasicInfoSlice } from 'app/pages/basic-information/slice/basic-info.slice';
import ApiService from 'app/api/api-service';
import { useEffect } from 'react';
export const AddInitiative = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actions = useBasicInfoSlice().actions;
  const { search } = useLocation();
  const addressFormData = useSelector(selectLocationForm);
  const [form, setForm] = useState({
    serviceName: { value: '', required: true, error: false },
    specifications: [{ value: '', id: 1, required: true, error: false }],
    files: [],
    services: [],
  });
  const [checked, setChecked] = useState(false);
  const [needVolunteerError, setNeedVolunteerError] = useState(false);
  const list = useSelector(selectDirectoryNames);
  const onDelete = (key, id) => {
    setForm((prev) => {
      const form = { ...prev };
      const List = [...form[key]];
      const newList = List.filter((item) => {
        return item.id !== id;
      });
      form[key] = newList;
      return form;
    });
  };
  useEffect(() => {
    dispatch(actions.resetLocationForm());
  }, []);
  const handleChange = (value, id, key) => {
    setForm((prev) => {
      const form = { ...prev };
      const specificationList = [...form[key]];
      const newList = specificationList.map((item) => {
        if (item.id === id) {
          return { ...item, value, error: !value };
        }
        return item;
      });
      form[key] = newList;
      return form;
    });
  };
  const AddMore = ({ label, keyName }) => {
    const requiredValues = ['specifications'];
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          setForm((prev) => {
            const form = { ...prev };
            const newSpecification = {
              value: '',
              id: (form[keyName]?.[form[keyName].length - 1]?.id || 1) + 1,
              required: requiredValues.includes(keyName),
              error: false,
            };
            const List = [...form[keyName], newSpecification];
            form[keyName] = List;
            return form;
          });
        }}>
        + {label}
      </p>
    );
  };
  const validate = () => {
    let isValid = true;
    const newForm = { ...form };
    Object.entries(newForm).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newData = [...value].map((value) => {
          const data = { ...value };
          if (data.required) {
            data.error = !data.value || data.value.split(' ').length < 5;
            console.log(data.error, data.value.split(' ').length < 5);
            isValid &&= !data.error;
          }
          return data;
        });
        newForm[key] = newData;
      } else {
        const data = { ...value };
        if (data.required) {
          data.error = !data.value || data.value.split(' ').length < 5;
          console.log(data.error);
          newForm[key] = data;
          isValid &&= !newForm[key].error;
        }
      }
    });
    setForm(newForm);
    return isValid;
  };
  const getArrayValue = (data) => {
    return data.map((el) => el.value);
  };

  const validateAddressForm = () => {
    let isValid = true;
    const form = [...addressFormData];
    const newForm = form.map((data) => {
      const form = { ...data };
      Object.entries(form).forEach(([key, value]) => {
        const subFields = { ...value };
        if (
          typeof subFields === 'object' &&
          subFields.hasOwnProperty('required')
        ) {
          subFields.error = !subFields?.required || !subFields.value;
          if (key === 'pincode') {
            subFields.error = subFields.value.length !== 6;
          }
          form[key] = subFields;
          isValid &&= !subFields.error;
        }
      });
      return form;
    });
    dispatch(actions.updateLocationForm(newForm));
    return isValid;
  };
  const onSubmit = () => {
    const isValid = validate();
    const isAddressValid = validateAddressForm();
    setNeedVolunteerError((prev) => !checked);
    if (isValid && isAddressValid && checked) {
      const addressFormKeys = [
        'address_line_1',
        'address_line_2',
        'country',
        'state',
        'city',
        'Ward',
        'Landmark',
        'pincode',
        'latitude',
        'longitude',
      ];
      const params = new URLSearchParams(search);
      const address = addressFormData.map((data) => {
        const form = {};
        addressFormKeys.forEach((key) => {
          form[key] = data[key]?.value || '';
        });
        return form;
      });
      const formData = new FormData();
      formData.append('initiative_name', form.serviceName.value);
      formData.append(
        'description',
        JSON.stringify(getArrayValue(form.specifications))
      );
      formData.append('category_path_id', params.get('category_id'));
      formData.append('address', address);
      formData.append('search_tag', JSON.stringify(form.services));
      formData.append('need_volunteer', checked);
      // dispatch(actions.addServices({ data: formData }));
      ApiService.post(
        'https://urjacityapi.logistack.com/api/add/directory/initiative/45c70f9b3388dffc3c2afdb668cd8d085629749b',
        formData
      )
        .then((res) => {})
        .catch((err) => {});
    }
  };
  return (
    <div>
      <CardHeader
        heading={'Add Initiative'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <div className={styles['stepper-container']}>
            <StepperList list={list} itemName={'name'} itemKey='id' />
            <section>
              <Button
                btnType='red'
                Component={EditIcon}
                onClick={() => {
                  navigate(ADD_SERVICE);
                }}>
                Edit
              </Button>
            </section>
          </div>

          <section style={{ paddingRight: '45px' }}>
            <TextInput
              value={form.serviceName.value}
              error={form.serviceName.error}
              errorMessage={'Please enter minimum 5 characters'}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'serviceName';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              placeholder='Here can be Service Name'></TextInput>
            <section>
              {form.specifications.map((el, index) => {
                const key = 'specifications';
                const props = {
                  value: el.value,
                  placeholder: 'Enter Specifications',
                  errorMessage: 'Please enter minimum 5 characters',
                  error: el.error,
                  onChange: (event) => {
                    handleChange(event.target.value, el.id, key);
                  },
                };
                if (el.id === 1) {
                  props.label = 'Specifications';
                } else {
                  props.onDelete = () => {
                    onDelete(key, el.id);
                  };
                }
                return <TextArea key={el.id} {...props} />;
              })}
              {form.specifications.length < 9 && (
                <AddMore
                  label={'Add Specifications'}
                  keyName='specifications'
                />
              )}
            </section>
            <ImageUpload
              label={'Media'}
              onDelete={onDelete}
              imageList={form.files}
              keyName='files'
              hideUpload={form.files.length >= 4}
              fileType='image/*'
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const key = 'files';
                  const file = e.target.files[0];
                  const id =
                    (form?.files?.[form.files.length - 1]?.id || 0) + 1;
                  setForm((prev) => {
                    const newData = { ...prev };
                    const fileData = { value: file, id };
                    const newFlies = [...newData[key]];
                    newFlies.push(fileData);
                    newData[key] = newFlies;
                    return newData;
                  });
                }
              }}
            />
            <Label textlabel>Location</Label>
            <LocationInformation onlyLocation />
            <Label textlabel>Volunteering</Label>
            <section className={styles['form-container']}>
              <Checkbox
                label='Need Volunteer'
                isChecked={checked}
                onChange={(event, checked) => setChecked(checked)}
              />
            </section>
            <TextError
              error={needVolunteerError}
              errorMessage='Please check Need Volunteer'
            />
            <div style={{ marginBottom: '5px' }}></div>
            <section>
              <TextInputChip
                placeholder='Here can be Search Tag'
                label={'Search Tags'}
                list={form.services}
                onChange={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
                onDelete={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
              />
            </section>
          </section>
        </CardBody>
        <CardActions>
          <Button btnType='secondary' style={{ marginRight: '14px' }}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Save</Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddInitiative;
