import styles from './product-header.module.scss';
import { ReactComponent as AddIcon } from 'app/libs/assests/add-icon-circle.svg';
import { Button } from '../';

export const ProductHeader = ({
  label,
  count,
  buttonName,
  onClick,
  subHeader,
  align,
  isCarousel = false,
  ...props
}) => {
  return (
    <>
      {subHeader && (
        <span
          className={styles['sub-header-style']}
          data-align={align}
          data-carousel={`${isCarousel}`}>
          {subHeader}
        </span>
      )}
      <div
        className={styles['container']}
        data-align={align}
        data-carousel={`${isCarousel}`}>
        <span>
          {label} {count && <span>({count})</span>}
        </span>
        {buttonName && (
          <Button
            btnType='red'
            Component={AddIcon}
            onClick={onClick}
            style={{ marginLeft: '15px' }}>
            {buttonName}
          </Button>
        )}
      </div>
    </>
  );
};
