import ApiService from 'app/api/api-service';

export const addServiceApi = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/add/directory/serv/${args.directory_id}`;
  const response = await ApiService.post(url, args.data);
  return response.data;
};
export const fetchCategories = async () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/all/main_category`;
  const response = await ApiService.get(url);
  return response.data;
};

export const fetchSubCategories = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get/sub_category/${args}`;
  const response = await ApiService.get(url);
  return response.data;
};
