import styles from './location-form.module.scss';
import { TextInput, Select, AutoCompleteText } from 'app/components';
import { Grid } from '@mui/material';
import Delete from 'app/libs/assests/delete.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import { useBasicInfoSlice } from '../../slice/basic-info.slice';
import { selectPlaceSuggestionlist } from '../../slice/basic-info.selector';
import { useDispatch, useSelector } from 'react-redux';
export const LocationForm = ({
  onDelete,
  onChange = () => {},
  formData,
  onAddressBlur,
  getAddressInfo,
  getWardList,
  id,
  disabled = false,
  error,
}) => {
  const placeSuggestionList = useSelector(selectPlaceSuggestionlist);
  const [focused, setIsFocused] = useState([]);
  const actions = useBasicInfoSlice().actions;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      formData.pincode?.value &&
      formData.pincode?.value.length === 6 &&
      !formData.ward.value
    ) {
      getWardList(formData.pincode?.value, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.pincode?.value, getWardList, id]);

  useEffect(() => {
    const value = formData?.address_line_1?.value || '';
    if (value.length >= 5 && focused) {
      dispatch(actions.loadPlaceSuggestion(value));
    }
  }, [actions, dispatch, focused, formData?.address_line_1?.value]);
  return (
    <div className={styles['form-container']}>
      <section className={styles['section-heading']}>
        <AutoCompleteText
          label={'Address Line 1'}
          placeholder={'Enter address'}
          subContent={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
          }
          errorMessage={
            error.address_line_1 || error.latitude || error.longitude
          }
          error={
            !!error?.address_line_1 || !!error?.latitude || !!error?.longitude
          }
          value={formData.address_line_1?.value || ''}
          onChange={(event) => {
            const value = event.target.value;
            onChange(value, 'address_line_1');
            if (formData.pincode?.value || formData.ward?.value) {
              console.log('calling', id);
              dispatch(actions.resetOnAddressChange(id));
            }
          }}
          onSelect={(item) => {
            onAddressBlur?.(item);
            setIsFocused(false);
          }}
          disabled={disabled}
          options={placeSuggestionList}
          itemName={'description'}
          itemValue={'place_id'}
          required
          onFocus={() => {
            setIsFocused(true);
          }}
        />
        {!formData?.hideAddressDetails?.value && (
          <>
            {formData.hasOwnProperty('address_line_2') && (
              <div style={{ marginTop: '5px' }}>
                <TextInput
                  label={'Address Line 2'}
                  placeholder={'Enter address'}
                  value={formData?.address_line_2?.value || ''}
                  errorMessage={error?.address_line_2}
                  error={!!error?.address_line_2}
                  disabled={disabled}
                  onChange={(event) => {
                    const value = event.target.value;
                    onChange(value, 'address_line_2');
                  }}
                  required
                  subContent={
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                  }
                />
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <TextInput
                  label={'Pincode'}
                  placeholder={'Enter Pincode'}
                  errorMessage={error?.pincode}
                  error={!!error?.pincode}
                  disabled={formData?.pincode?.disable || disabled}
                  value={formData.pincode?.value || ''}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.length <= 6) onChange(value, 'pincode');
                  }}
                  required
                  subContent={
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                  }
                />
              </Grid>
            </Grid>
            {(formData.pincode?.value || '').length === 6 && (
              <>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6}>
                    <TextInput
                      label={'Country'}
                      placeholder={'Enter country'}
                      error={!!error?.country}
                      errorMessage={error?.country}
                      value={formData.country_name?.value || ''}
                      required
                      disabled
                      readOnly
                    />
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <TextInput
                      label={'City'}
                      placeholder={'Enter city'}
                      error={!!error?.city}
                      errorMessage={error?.city}
                      value={formData.city_name?.value || ''}
                      required
                      disabled
                      readOnly
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6}>
                    <TextInput
                      label={'State'}
                      placeholder={'Enter state'}
                      error={!!error?.state}
                      errorMessage={error?.state}
                      disabled
                      value={formData.state_name?.value || ''}
                      required
                      readOnly
                    />
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <Select
                      label={'Ward'}
                      value={formData.ward?.value || ''}
                      onChange={(event) => {
                        const value = event.target.value;
                        onChange(value, 'ward');
                      }}
                      disable={formData.ward.disable || disabled}
                      options={formData.wardOptions}
                      placeholder={'Enter ward'}
                      error={!!error?.ward}
                      errorMessage={error?.ward}
                      required
                      itemKey='ward_code'
                      itemName='ward'
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Grid container spacing={1}>
                  <Grid item md={3}>
                    <TextInput
                      label={'Code'}
                      required
                      value={formData?.countryCode?.value || ''}
                    />
                  </Grid>
                  <Grid item md={9}>
                    <TextInput
                      label={'Mobile Number'}
                      required
                      placeholder={'Enter mobile number'}
                      value={formData?.mobile?.value || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          formData?.mobile?.regex.test(value) &&
                          value.length <= 10
                        ) {
                          onChange(value, 'mobile');
                        }
                        // onHandleChange(e.target.value, 'mobile');
                      }}
                      error={!!error.mobile}
                      errorMessage={error.mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <TextInput
                  label={'Email'}
                  placeholder={'Enter email'}
                  required
                  value={formData?.email?.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange(value, 'email');
                  }}
                  error={!!error?.email}
                  errorMessage={error?.email}
                />
              </Grid>
            </Grid>
            <TextInput
              label={'Landmark'}
              placeholder={'Enter landmark'}
              disabled={disabled}
              value={formData.landmark?.value || ''}
              error={!!error?.landmark}
              errorMessage={error?.landmark}
              onChange={(event) => {
                const value = event.target.value;
                onChange(value, 'landmark');
              }}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
            />
          </>
        )}
      </section>
      {!disabled && onDelete && (
        <div
          className={styles['delete-icon']}
          onClick={() => {
            onDelete?.(id);
          }}>
          <img src={Delete} alt='delete' />
        </div>
      )}
    </div>
  );
};
