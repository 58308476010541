import { useState } from 'react';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  TextArea,
  CardHeader,
  ImageUpload,
  ServiceHeader,
  TextInputChip,
} from 'app/components';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector } from 'react-redux';
import ApiService from 'app/api/api-service';
import { useLocation } from 'react-router-dom';
export const AddActivity = () => {
  const { search } = useLocation();
  const [form, setForm] = useState({
    activityName: { value: '', required: true, error: false },
    activityType: { value: '', required: true, error: false },
    activityUrl: { value: '', required: true, error: false },
    activityDescription: { value: '', required: true, error: false },
    files: [],
    services: [],
  });
  const list = useSelector(selectDirectoryNames);
  const onDelete = (key, id) => {
    setForm((prev) => {
      const form = { ...prev };
      const List = [...form[key]];
      const newList = List.filter((item) => {
        return item.id !== id;
      });
      form[key] = newList;
      return form;
    });
  };
  const validate = () => {
    let isValid = true;
    const newForm = { ...form };
    Object.entries(newForm).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newData = [...value].map((value) => {
          const data = { ...value };
          if (data.required) {
            data.error = !data.value;
            isValid &&= !data.error;
          }
          return data;
        });
        newForm[key] = newData;
      } else {
        const data = { ...value };
        if (data.required) {
          data.error = !data.value;
          console.log(data.error);
          newForm[key] = data;
          isValid &&= !newForm[key].error;
        }
      }
    });
    setForm(newForm);
    return isValid;
  };
  const onSubmit = () => {
    const isValid = validate();
    if (isValid) {
      const params = new URLSearchParams(search);
      const formData = new FormData();
      formData.append('activity_name', form.activityName.value);
      formData.append('activity_description', form.activityDescription.value);
      formData.append('activity_url', form.activityUrl.value);
      formData.append('activity_type', form.activityType.value);
      formData.append('category_path_id', params.get('category_id'));
      formData.append('search_tag', JSON.stringify(form.services));
      ApiService.post(
        'https://urjacityapi.logistack.com/api/add/directory/activity/45c70f9b3388dffc3c2afdb668cd8d085629749b',
        formData
      )
        .then((res) => {})
        .catch((err) => {});
    }
  };
  return (
    <div>
      <CardHeader
        heading={'Add Activity'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <ServiceHeader list={list} itemName={'name'} itemKey='id' />

          <section style={{ paddingRight: '45px' }}>
            <TextInput
              value={form.activityName.value}
              error={form.activityName.error}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityName';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              errorMessage={'Please enter activity name'}
              label={'Activity Name'}
              placeholder='Here can be Activity Name'
            />
            <TextInput
              value={form.activityType.value}
              error={form.activityType.error}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityType';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              errorMessage={'Please enter activity type'}
              label={'Activity Type'}
              placeholder='Here can be Activity Type'
            />
            <TextInput
              value={form.activityUrl.value}
              error={form.activityUrl.error}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityUrl';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              errorMessage={'Please enter activity url'}
              label={'Activity Url'}
              placeholder='Here can be Activity Url'
            />
            <TextArea
              value={form.activityDescription.value}
              error={form.activityDescription.error}
              errorMessage={'Please enter description'}
              required
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'activityDescription';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              label={'Activity Description'}
              placeholder='Enter Description'
            />
            <ImageUpload
              label={'Media'}
              onDelete={onDelete}
              imageList={form.files}
              keyName='files'
              hideUpload={form.files.length >= 4}
              fileType='image/*'
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const key = 'files';
                  const file = e.target.files[0];
                  const id =
                    (form?.files?.[form.files.length - 1]?.id || 0) + 1;
                  setForm((prev) => {
                    const newData = { ...prev };
                    const fileData = { value: file, id };
                    const newFlies = [...newData[key]];
                    newFlies.push(fileData);
                    newData[key] = newFlies;
                    return newData;
                  });
                }
              }}
            />

            <TextInputChip
              placeholder='Here can be Search Tag'
              label={'Search Tags'}
              list={form.services}
              onChange={(value) => {
                setForm((prev) => {
                  const key = 'services';
                  return {
                    ...prev,
                    [key]: value,
                  };
                });
              }}
              onDelete={(value) => {
                setForm((prev) => {
                  const key = 'services';
                  return {
                    ...prev,
                    [key]: value,
                  };
                });
              }}
            />
          </section>
        </CardBody>
        <CardActions>
          <Button btnType='secondary' style={{ marginRight: '14px' }}>
            Cancel
          </Button>
          <Button style={{ marginRight: '14px' }} onClick={onSubmit}>
            Save
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddActivity;
