import styles from './stepper.module.scss';
import checkIcon from 'app/libs/assests/check-icon.svg';
export const Stepper = ({ list, activeTab, onClick, ...props }) => {
  const getStyle = (index) => {
    return activeTab === index
      ? 'active-tab'
      : activeTab > index
      ? 'visited-tab'
      : '';
  };
  return (
    <>
      <section className={styles['tab-container']}>
        {list.map((name, index) => {
          return (
            <p
              className={`${styles[getStyle(index)]}`}
              key={name}
              onClick={() => {
                onClick?.(index);
              }}>
              <span>
                {activeTab <= index ? (
                  index + 1
                ) : (
                  <img src={checkIcon} alt={1} />
                )}
              </span>{' '}
              {name}
            </p>
          );
        })}
      </section>
    </>
  );
};

export const StepperList = ({ list, itemName, itemKey, ...props }) => {
  return (
    <div className={styles['stepper-list-container']}>
      {list.map((item) => {
        return (
          <span className={styles['stepper']} key={item?.[itemKey] || item}>
            {item?.[itemName] || item}
          </span>
        );
      })}
    </div>
  );
};
