import styles from './resource-card.module.scss';
import NutritionalDiet from 'app/libs/assests/nutritional-diet.png';
import WasteResourceIcon from 'app/libs/assests/waste-resource-icon.svg';
import ResourceBag from 'app/libs/assests/resource-bag.svg';

export const ResourceCard = ({
  isEven = false,
  title = '',
  shortDesc = '',
  cataColor = '',
}) => {
  return (
    <div className={styles['container']}>
      <section
        className={styles['image-container']}
        style={{
          // backgroundImage: `url(${NutritionalDiet})`,
          backgroundColor: cataColor,
        }}>
        <div className={styles['directives-card']}>
          <p>12</p>
          directories
        </div>
        <div className={styles['resource-icon']} data-even={`${isEven}`}>
          <img src={isEven ? ResourceBag : WasteResourceIcon} alt='akala' />
        </div>
      </section>
      <div className={styles['footer-card']}>
        <h5>{title}</h5>
        <p>{shortDesc}</p>
        <p></p>
      </div>
    </div>
  );
};
