import * as React from 'react';

import styles from './button.module.scss';

export function Button({
  iconName,
  fullWidth = false,
  className,
  btnType = 'primary',
  imageSrc = '',
  Component,
  rounded,
  ...props
}) {
  return (
    <button
      className={`${styles['btn']} ${fullWidth && styles['full-width']} ${
        styles[`btn-${btnType}`]
      } ${className}`}
      {...props}
      data-rounded={`${rounded}`}>
      {imageSrc && <img src={imageSrc} alt='btn-icon' />}
      {iconName && <i className='material-icons'>{iconName}</i>}
      {Component && <Component />}
      {props.children}
    </button>
  );
}
