import { basicInfoActions as actions } from './basic-info.slice';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  postSaveDirectory,
  fetchWardList,
  fetchGeocodeLocation,
  fetchPlaceSuggestion,
} from '../services/basic-info-service';
import { DIRECTORY, LOGIN } from 'app/libs/constants/tab-routes';
import { snackbarActions } from 'app/shared/feature-snackbar/slice/feature-snackbar.slice';

export function* saveDirectory(action) {
  try {
    const response = yield call(postSaveDirectory, action.payload.form);

    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        yield put(actions.updateErrors(response.message_block.message || {}));
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    const controlKeysAvailable =
      'status' in response.control_block &&
      'login_needed' in response.control_block;

    if (controlKeysAvailable) {
      if (response?.control_block?.status) {
        const directory_id =
          response?.data_block?.data?.[0]?.value?.directory_id || '';
        //navigate to directory

        if (directory_id)
          action.payload.navigate(`${DIRECTORY}/${directory_id}`);
        else throw new Error('Something Went wrong');
      } else if (response.control_block.login_needed) {
        //navigate to login
        action.payload.navigate(LOGIN);
      } else {
        throw new Error('Something Went wrong');
      }
    } else if (!controlKeysAvailable) {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}
export function* getWardList(action) {
  try {
    const args = { pincode: action.payload.value };
    const response = yield call(fetchWardList, args);
    if (response.control_block.message_block_status) {
      if (response.message_block.message_type === 'inline') {
        //showing inline error logic will be changed with respective of the state.
        return;
      } else if (
        response.message_block.message_type === 'main' &&
        !response.control_block.status
      ) {
        return;
      } else if (response.message_block.message_type !== 'main') {
        throw new Error('something went wrong (message type)');
      }
    }
    if (response.control_block.data_block_status) {
      //checking for token in data_block, if it's available then storing it, otherwise we are throwing an error.
      const responseData = response.data_block.map((ward) => ward.data);
      if (responseData) {
        action.payload.setAddressForm((prev) => {
          const newLocationForm = [...prev].map((address) => {
            const data = { ...address };
            if (data.address_sequence_no === action.payload.id) {
              data.wardOptions = responseData;
              data.state_name = {
                ...data.state_name,
                value: responseData[0].state,
                error: !responseData[0].state,
              };
              data.country_name = {
                ...data.country_name,
                value: responseData[0].country,
                error: !responseData[0].country,
              };
              data.city_name = {
                ...data.city_name,
                value: responseData[0].city,
                error: !responseData[0].city,
              };
              data.city = { value: responseData[0].city_code };
              data.state = { value: responseData[0].state_code };
              data.country = { value: responseData[0].country_code };
              data.Ward = { ...data.Ward, value: '', disbale: false };
              if (responseData.length === 1) {
                data.Ward = {
                  ...data.Ward,
                  value: responseData[0].ward_code,
                  error: !responseData[0].ward_code,
                  disable: true,
                };
              } else {
                data.Ward = { ...data.Ward, value: '', disable: false };
              }
            }
            return data;
          });
          return newLocationForm;
        });
      } else {
        throw new Error('Something Went Wrong');
      }
    }
  } catch (error) {
    yield put(
      snackbarActions.loadSnackbar({
        type: 'error',
        open: true,
        message: error.message || 'Something went wrong',
      })
    );
  }
}
export function* getLocationList(action) {
  try {
    const response = yield call(fetchGeocodeLocation, action.payload.value);
    if (response.results[0]) {
      const { lat = '', lng = '' } = response?.results[0]?.geometry?.location;

      action.payload.setAddressForm((prev) => {
        const newLocationForm = [...prev].map((address) => {
          const data = { ...address };
          if (data.address_sequence_no === action.payload.id) {
            data.latitude = { ...data.latitude, value: lat, error: !lat };
            data.longitude = { ...data.longitude, value: lng, error: !lng };
            const pincode = response.results[0].address_components.reduce(
              (pincode, item) => {
                if (item.types.includes('postal_code'))
                  return pincode + item.long_name;
                return pincode;
              },
              ''
            );

            if (pincode) {
              data.pincode = {
                ...data.pincode,
                value: pincode,
                error: !pincode,
                disable: true,
              };
            } else {
              data.pincode = { ...data.pincode, value: '', disable: false };
            }
          }
          return data;
        });
        return newLocationForm;
      });
    }
  } catch (error) {}
}

export function* getPlaceSuggestion(action) {
  try {
    const response = yield call(fetchPlaceSuggestion, action.payload);
    if (response.predictions) {
      yield put(actions.loadedPlaceSuggestion(response.predictions));
    }
  } catch (error) {}
}

export function* basicInfoFromSaga() {
  yield takeLatest(actions.submitDirectory.type, saveDirectory);
  yield takeLatest(actions.loadLocationDetails.type, getLocationList);
  yield takeLatest(actions.loadWardList.type, getWardList);
  yield takeLatest(actions.loadPlaceSuggestion.type, getPlaceSuggestion);
}
