import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../models';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { homeFromSaga } from './home.saga';

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    loadCategory(state, action) {},
    loadedCategory(state, action) {
      state.category = action.payload;
    },
  },
});
export const { actions: homeActions, reducer } = slice;

export const useHomeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: homeFromSaga });
  return { actions: slice.actions };
};
