import { useState } from 'react';
import styles from './manage-announcement-form.module.scss';
import { Grid } from '@mui/material';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import {
  Button,
  TextArea,
  DateTextInput,
  CardActions,
  CardHeader,
  CardBody,
  Select,
} from 'app/components';

export const ManageAnnouncementForm = ({ hideHeader = false }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <>
      <div>
        {!hideHeader && (
          <CardHeader
            heading={'Manage Announcement'}
            subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
            helperText={''}
            mainHeader
          />
        )}
        <CardHeader heading={'Organic Mandiya'} helperText={''} />
        <CardBody>
          <div className={styles['message-container']}>
            <Button btnType='green'>Approved</Button>
            <div className={styles['status-style']}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's
            </div>
          </div>
          <Select
            label={'Type'}
            placeholder={'Select announcement type'}
            subContent={'Lorem ipsum dolor sit amet'}
            disable={isDisabled}
          />
          <TextArea
            disabled={isDisabled}
            label={'Announcement'}
            subContent={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
            }
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateTextInput
                value={startDate}
                disabled={isDisabled}
                onChange={(value) => {
                  setStartDate(value);
                }}
                label={'Start Date'}
                subContent={'Lorem ipsum dolor sit amet'}
              />
            </Grid>
            <Grid item md={6}>
              <DateTextInput
                value={endDate}
                disabled={isDisabled}
                onChange={(value) => {
                  setEndDate(value);
                }}
                label={'End Date'}
                subContent={'Lorem ipsum dolor sit amet'}
              />
            </Grid>
          </Grid>
        </CardBody>
        <CardActions>
          <Button
            Component={EditIcon}
            style={{ marginRight: '14px' }}
            onClick={() => {
              setIsDisabled((prev) => !prev);
            }}>
            Edit
          </Button>
          <Button
            btnType='secondary'
            Component={DeleteIcon}
            style={{ marginRight: '14px' }}>
            Delete
          </Button>
          <Button btnType='secondary' Component={ReviewIcon}>
            Submit for Review
          </Button>
        </CardActions>
      </div>
    </>
  );
};
