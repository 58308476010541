import styles from './consultant-card.module.scss';
import UserImage from 'app/libs/assests/shivani-thakur.png';

export const ConsultantCard = () => {
  const tags = ['Waste Management', 'Cliemate Change'];
  return (
    <div className={styles['container']}>
      <section className={styles['image-container']}>
        <img src={UserImage} alt='user' />
        <aside className={styles['right-container']}>
          <h3>Shivani Thakur</h3>
          <div>
            {tags.map((tag) => (
              <span>{tag}</span>
            ))}
          </div>
        </aside>
      </section>
      <p>
        Lorem ipsum is simply free text used by copytyping refreshing. Neque
        porro est qui dolorem ipsum quia quaed inventore veritatis et quasi
        architecto beatae vitae dicta sunt
      </p>
    </div>
  );
};
