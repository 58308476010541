import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export const MapContainer = (props) => {
  return (
    <Map
      google={props.google}
      zoom={14}
      initialCenter={{
        lat: 40.854885,
        lng: -88.081807,
      }}
      onClick={(e) => {
        console.log(e);
      }}>
      <Marker
        title={'The marker`s title will appear as a tooltip.'}
        name={'SOMA'}
        position={{ lat: 40.854885, lng: -88.081807 }}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB1ESjaJmYygcyf5kR2EMN0pdpGIXRoRVo',
})(MapContainer);
