import { Grid } from '@mui/material';
import { InitiativeDetail } from '../initiative-detail/initiative-detail';
import { ImageCarousel } from 'app/components';
import styles from './initiative-card.module.scss';

export const InitiativeCard = ({ media, ...props }) => {
  return (
    <div className={styles['container']}>
      <Grid container spacing={3}>
        <Grid item md={5}>
          <ImageCarousel images={media} />
        </Grid>
        <Grid item md={7}>
          <InitiativeDetail {...props} />
        </Grid>
      </Grid>
    </div>
  );
};
