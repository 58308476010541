import { Pagination } from 'app/components';
import styles from './table.module.scss';

export const Table = ({ header = [], data = [] }) => {
  return (
    <div className={styles['container']}>
      <table className={styles['table-container']}>
        <thead>
          <tr className={styles['table-header']}>
            {header.map((header) => {
              return <th key={header.key}>{header.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((list, index) => {
            return (
              <tr className={styles['table-row']} key={`row-${index}`}>
                {header.map((key, index) => {
                  const label = list?.[key?.key];
                  return (
                    <td key={`column-${index}`}>
                      {key.Component ? (
                        <key.Component data={list} accessLabel={key?.key} />
                      ) : (
                        label
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination count={3} />
    </div>
  );
};
