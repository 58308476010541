import { createSelector } from '@reduxjs/toolkit';
import { initialState } from '../model';
const selectDomain = (state) => state.addService || initialState;

export const selectDirectoryNames = createSelector(
  [selectDomain],
  (addServices) => addServices.directoryNames
);

export const selectDirectories = createSelector(
  [selectDomain],
  (addServices) => addServices.directories
);

export const selectError = createSelector(
  [selectDomain],
  (addServices) => addServices.error
);
