import { Label, StatusChip } from 'app/components';
import { PropertyCard } from '../';
import styles from './property-container.module.scss';

export const PropertyContainer = ({ list }) => {
  return (
    <div className={styles['container']}>
      <section className={styles['header-container']}>
        <Label>Organic Mandya Farmers Market</Label>
        <div>
          <span>Views(15)</span>
          <span>Calls(7)</span>
          <span>Email(13)</span>
          <span>Enquiry(32)</span>
          <StatusChip label='Published' type='Published' />
        </div>
      </section>
      {list.map((el) => {
        return <PropertyCard heading={el.name} list={el.list} />;
      })}
    </div>
  );
};
