import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from './add-service.module.scss';
import { Button, CardHeader, StepperList } from 'app/components';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectDirectoryNames,
  selectDirectories,
} from './slice/add-service.selector';
import { useAddServiceSlice } from './slice/add-service.slice';
import { useSelector, useDispatch } from 'react-redux';
import {
  ADD_ACTIVITY,
  ADD_INITIATIVE,
  ADD_PRODUCT,
  ADD_SERVICE_MAIN,
} from 'app/libs/constants/tab-routes';
import { useMemo } from 'react';

export const AddService = React.memo(({ ...props }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const directory_id = query.get('directory_id');
  const path = query.get('path');
  const labels = {
    [ADD_PRODUCT]: 'Add Product',
    [ADD_SERVICE_MAIN]: 'Add Services',
    [ADD_INITIATIVE]: 'Add Initiative',
    [ADD_ACTIVITY]: 'Add Activity',
  };
  const label = useMemo(() => {
    return labels[path];
  }, [path]);
  const dispatch = useDispatch();
  const actions = useAddServiceSlice().actions;
  const directoryNames = useSelector(selectDirectoryNames);
  const directories = useSelector(selectDirectories);
  const number = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    setActiveIndex((prev) => {
      const index = number - directories.length;
      return -index < 0 ? 0 : index;
    });
  }, [directories, number]);

  useEffect(() => {
    dispatch(actions.loadCategories());
  }, []);
  const style = {
    '--numberOfCards': number,
    '--activeIndex': activeIndex,
    color: '',
  };

  return (
    <div>
      <CardHeader
        heading={label}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      {directories.length !== 0 && (
        <div className={styles['container']}>
          <StepperList list={directoryNames} itemName={'name'} itemKey='id' />
          <div className={`${styles['list-container']}`}>
            <section
              className={styles['pointer']}
              onClick={() => {
                setActiveIndex((prev) => (prev < 0 ? ++prev : 0));
              }}>
              <i className='material-icons'>arrow_back_ios</i>
            </section>
            {directories.map((listData, index) => {
              return listData && listData.length === 0 ? (
                <div
                  className={styles['select-card']}
                  style={{ ...style }}
                  key={directoryNames.at(-1)?.id}>
                  {directoryNames.at(-1)?.name || ''}
                  <div>
                    <Button
                      onClick={() => {
                        navigate(
                          `${path}?category_id=${
                            directoryNames.at(-1)?.id
                          }&directory_id=${directory_id}`
                        );
                      }}>
                      Select
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className={styles['card-container']}
                  style={{ ...style }}
                  key={`category-${index}`}>
                  {listData.map((category) => {
                    return (
                      <p
                        className={styles['label-style']}
                        key={category?.value?.catsubcat_path_id || ''}
                        data-activetab={`${[
                          category?.value?.catsubcat_path_id || '',
                        ].includes(directoryNames?.[index]?.id)}`}
                        onClick={() => {
                          const newDirectoryNames = [...directoryNames].slice(
                            0,
                            index
                          );
                          const newList = {
                            name: [
                              ...newDirectoryNames,
                              {
                                name: category?.value?.catsubcat_name,
                                id: category?.value?.catsubcat_path_id || '',
                              },
                            ],
                            categoryid: category?.value?.catsubcat_id || '',
                            index,
                          };
                          if (category.control.is_last_node) {
                            const newDirectory = [...directories].slice(
                              0,
                              index + 1
                            );
                            dispatch(
                              actions.loadedSubcategory({
                                directory: [...newDirectory, []],
                                name: newList.name,
                              })
                            );
                          } else {
                            dispatch(actions.loadSubcategory(newList));
                          }
                        }}>
                        <span>{category?.value?.catsubcat_name}</span>
                        <i className='material-icons'>arrow_right</i>
                      </p>
                    );
                  })}
                </div>
              );
            })}
            <section
              className={styles['pointer']}
              onClick={() => {
                setActiveIndex((prev) =>
                  prev > number - directories.length ? --prev : prev
                );
              }}>
              <i className='material-icons'>arrow_forward_ios</i>
            </section>
          </div>
        </div>
      )}
    </div>
  );
});

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return Math.floor(size[0] / 360) || 1;
};
