import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../models';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { authFromSaga } from './auth.saga';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    postLogin(state, action) {
      state.errors = {};
    },
    postSignUp(state, action) {
      state.errors = {};
    },
    loadWardList(state, action) {
      state.wardList = [];
    },
    loadedWardList(state, action) {
      state.wardList = action.payload;
    },
    updateToken(state, action) {
      state.token = action.payload;
    },
    updateErrors(state, action) {
      state.errors = { ...action.payload };
    },
    postResetPassword(state, action) {
      state.errors = {};
    },
    postForgotPassword(state, action) {
      state.errors = {};
    },
    validateEmail(state, action) {},
    successValidatedEmail(state, action) {
      state.emailValidated = action.payload;
    },
  },
});
export const { actions: authActions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authFromSaga });
  return { actions: slice.actions };
};
