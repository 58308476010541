import { AnnouncementCard, Button } from 'app/components';
import styles from './announcement-container.module.scss';
import CarouselArrow from 'app/libs/assests/carousel-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { ANNOUNCEMENT_LIST } from 'app/libs/constants/tab-routes';

export const AnnouncementContainer = () => {
  const a = [1, 2, 3, 4, 5, 6];
  const navigate = useNavigate();
  return (
    <div className={styles['container']}>
      <section className={styles['head-container']}>
        <h3>Announcements</h3>
        <div>
          <Button
            onClick={() => {
              navigate(ANNOUNCEMENT_LIST);
            }}>
            View All Announcements (100)
          </Button>
        </div>
      </section>
      <div>
        <span>
          <img src={CarouselArrow} alt='carousel arrow' />
        </span>
        <section className={styles['card-inner-container']}>
          {a.map((el) => (
            <AnnouncementCard />
          ))}
        </section>
        <span>
          <img src={CarouselArrow} alt='carousel arrow' />
        </span>
      </div>
    </div>
  );
};
