import { Grid } from '@mui/material';
import {
  ActivitiesCard,
  Button,
  Label,
  Pagination,
  PageBanner,
  AnnouncementCard,
} from 'app/components';
import GlobalKidsHand from 'app/libs/assests/global-kids-hands.png';
import ArticleBackground from 'app/libs/assests/article-background.png';
import { useNavigate } from 'react-router-dom';
import styles from './announcement-list.module.scss';
import { ReactComponent as FilterIcon } from 'app/libs/assests/filter-icon.svg';
import { ARTICLE_LISTING } from 'app/libs/constants/tab-routes';

export const AnnouncementList = () => {
  const dummyData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const dummyData1 = [1, 2];
  const stepper = ['pages', 'Announcements'];
  const navigate = useNavigate();
  return (
    <div className={styles['container']}>
      <PageBanner
        imageSrc={ArticleBackground}
        heading={'Announcements'}
        stepperList={stepper}
      />
      <div className={styles['section-container']}>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <div className={styles['header-container']}>
              <Label>100 Announcements</Label>
              <Button Component={FilterIcon}>Search Filter</Button>
            </div>
            <Grid container spacing={2}>
              {dummyData.map((el) => {
                return (
                  <Grid item md={6}>
                    <AnnouncementCard active={el === 2} />
                  </Grid>
                );
              })}
            </Grid>
            <Pagination count={3} />
          </Grid>
          <Grid item md={3}>
            <div className={styles['header-container']}>
              <Label>Recent Article</Label>
            </div>
            <Grid container rowGap={2}>
              {dummyData1.map((el) => {
                return (
                  <Grid item md={12}>
                    <ActivitiesCard
                      imgSrc={GlobalKidsHand}
                      author={'Shivani Thakur'}
                      onClick={() => {
                        console.log('clicking');
                        navigate(ARTICLE_LISTING);
                      }}
                      isBlog
                      date={'17 Aug, 2021'}
                      commentCounts={'3'}
                      heading={
                        'The passage experienced a surge in popularity during the 1960s.'
                      }
                      label={
                        'Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
