import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { CardBody, CardActions, Button } from 'app/components';
import { LocationInformation } from '../location-info';
import { BasicInformation } from '../basic-info';
import { ContactInformation } from '../contact-info';
import { Description } from '../description';
import { emailRegex, numberRegex } from 'app/libs/constants/regex';
import { useDispatch, useSelector } from 'react-redux';
import { useBasicInfoSlice } from '../../slice/basic-info.slice';
import { addressForm1Schema } from '../../model';
import { selectError } from '../../slice/basic-info.selector';

const initialValue = '<p></p><p></p><p></p><p></p><p></p>';
export const InfoContainer = ({ activeTab, setActiveTab, containerRefs }) => {
  const navigate = useNavigate();
  const error = useSelector(selectError);
  const [basicForm, setBasicForm] = useState({
    directory_type: { value: '', required: true, error: false },
    directory_name: { value: '', required: true, error: false },
  });
  const [description, setDescription] = useState(initialValue);
  const [excerpt, setExcerpt] = useState(initialValue);
  const [contactForm, setContactForm] = useState({
    countryCode: { value: '+91' },
    mobile: { value: '', error: false, required: true, regex: numberRegex },
    email: {
      value: '',
      error: false,
      required: true,
      pattern: emailRegex,
    },
    org_url: { value: '' },
    facebook_profile: { value: '' },
    linkedin_profile: { value: '' },
    youtube_profile: { value: '' },
  });
  const [addressForm, setAddressForm] = useState([{ ...addressForm1Schema }]);
  const containerRef = useRef();
  const actions = useBasicInfoSlice().actions;
  const dispatch = useDispatch();

  const onSubmit = () => {
    // const isBasicFormValid = validateBasicForm();
    // const isContactFormValid = contactFormValidate();
    // const isAddressFormVald = validateAddressForm();
    // if (isBasicFormValid && isContactFormValid && isAddressFormVald) {
    const addressFormKeys = [
      'address_line_1',
      'address_line_2',
      'country',
      'state',
      'city',
      'ward',
      'landmark',
      'pincode',
      'latitude',
      'longitude',
      'mobile',
      'email',
      'countryCode',
    ];
    const address = addressForm.map((data) => {
      const form = { address_sequence_no: data.address_sequence_no };
      addressFormKeys.forEach((key) => {
        form[key] = data[key]?.value || '';
      });
      return form;
    });
    const form = new FormData();
    form.append('directory_name', basicForm.directory_name.value);
    form.append('org_url', contactForm.org_url.value);
    form.append('description', description);
    form.append('excerpt', excerpt);
    form.append('directory_type', basicForm.directory_type.value);
    form.append('linkedin_profile', contactForm.linkedin_profile.value);
    form.append('facebook_profile', contactForm.facebook_profile.value);
    form.append('youtube_profile', contactForm.youtube_profile.value);
    form.append('address', JSON.stringify(address));
    dispatch(actions.submitDirectory({ form, navigate }));
    // } else {
    //   if (containerRef?.current) {
    //     containerRef?.current?.scrollIntoView();
    //   }
    // }
  };
  useEffect(() => {
    if (activeTab === 0) {
      dispatch(actions.resetLocationForm());
    }
  }, [actions, activeTab, dispatch]);
  const contactFormValidate = () => {
    const newForm = { ...contactForm };
    let validated = true;
    Object.entries(contactForm).forEach(([key, value]) => {
      newForm[key].error = !(
        !value.required ||
        (!!value.value && (!value.pattern || value.pattern.test(value.value)))
      );

      validated &&= !newForm[key].error;
    });
    setContactForm(newForm);
    return validated;
  };
  const validateBasicForm = () => {
    const newForm = { ...basicForm };
    let validated = true;
    Object.entries(basicForm).forEach(([key, value]) => {
      newForm[key].error = !value.required || !value.value;
      validated &&= !newForm[key].error;
    });
    setBasicForm(newForm);
    return validated;
  };
  const validateAddressForm = () => {
    let isValid = true;
    const form = [...addressForm];
    const newForm = form.map((data) => {
      const form = { ...data };
      Object.entries(form).forEach(([key, value]) => {
        const subFields = { ...value };
        if (
          typeof subFields === 'object' &&
          subFields.hasOwnProperty('required')
        ) {
          subFields.error = !subFields?.required || !subFields.value;
          if (key === 'pincode') {
            subFields.error = subFields.value.length !== 6;
          }
          form[key] = subFields;
          isValid &&= !subFields.error;
        }
      });
      return form;
    });
    setAddressForm(newForm);
    return isValid;
  };

  return (
    <div ref={containerRef}>
      <CardBody>
        <div ref={containerRefs.basic}>
          <BasicInformation
            form={basicForm}
            setForm={setBasicForm}
            changeTab={setActiveTab}
            error={error}
          />
        </div>
        <div ref={containerRefs.address}>
          <LocationInformation
            changeTab={setActiveTab}
            addressForm={addressForm}
            setAddressForm={setAddressForm}
            error={error?.address || []}
          />
        </div>
        <div ref={containerRefs.contact}>
          <ContactInformation
            form={contactForm}
            setForm={setContactForm}
            error={error}
          />
        </div>
        <div ref={containerRefs.description}>
          <Description
            description={description}
            setDescription={setDescription}
            excerpt={excerpt}
            setExcerpt={setExcerpt}
            error={error}
          />
        </div>
      </CardBody>
      <CardActions>
        <Button
          btnType='secondary'
          style={{ marginRight: '14px' }}
          onClick={() => {
            navigate(-1);
          }}>
          Back
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </CardActions>
    </div>
  );
};
