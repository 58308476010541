import styles from './property-card.module.scss';
export const PropertyCard = ({ list, heading }) => {
  return (
    <main className={styles['container']}>
      <h3>{heading}</h3>
      <section className={styles['list-container']}>
        {list.map((el, index) => {
          return (
            <section key={index} className={styles['list-item']}>
              <span>{index + 1}</span>
              <div>{el.content}</div>
              {el.list.map((el) => {
                return <p>{el}</p>;
              })}
            </section>
          );
        })}
      </section>
    </main>
  );
};
