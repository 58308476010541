import { createSelector } from '@reduxjs/toolkit';
import { initialState } from '../model';
const selectDomain = (state) => state.basicInfo || initialState;

export const selectLocationForm = createSelector(
  [selectDomain],
  (basicInfo) => basicInfo.locationForm
);

export const selectPlaceSuggestionlist = createSelector(
  [selectDomain],
  (basicInfo) => basicInfo.placeSuggestionlist
);

export const selectError = createSelector(
  [selectDomain],
  (basicInfo) => basicInfo.error
);
