export const LOCATION_INFO = '/location-information';
export const DESCRIPTION = '/description';
export const SIGN_UP = '/signup';
export const FORGOT_PASSWORD = '/forgot-password';
export const HOME = '/';
export const BASIC_INFORMATION = '/basic-information';
export const CONTACT_INFORMATION = '/contact-information';
export const MANAGE_ANNOUNCEMENT = '/manage-announcement';
export const ADD_ANNOUNCEMENT = '/add-announcement';
export const ADD_SERVICE = '/add-service';
export const ADD_SERVICE_MAIN = '/add-service-main';
export const MANAGE_SERVICE = '/manage-service';
export const ADD_ACTIVITY = '/add-activity';
export const MANAGE_ACTIVITY = '/manage-activity';
export const ADD_PRODUCT = '/add-product';
export const MANAGE_PRODUCT = '/manage-product';
export const ADD_INITIATIVE = '/add-initiative';
export const MANAGE_INITIATIVE = '/manage-initiative';
export const DIRECTORY = '/directory';
export const LOGIN = '/login';
export const DIRECTORY_LIST = '/directory-list';
export const DIRECTORY_MAP = '/directory-map';
export const PROFILE = '/profile';
export const DASHBOARD = '/dashboard';
export const MANAGE_ARTICLE = '/manage-article';
export const ADD_ARTICLE = '/add-article';
export const ARTICLE_LISTING = '/article-listing';
export const ANNOUNCEMENT_LIST = '/announcement-list';
export const ARTICLE_DETAIL = '/article-detail';
export const TERMS = '/terms';
export const SET_PASSWORD = '/set-password';
