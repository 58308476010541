import styles from './page-banner.module.scss';

export const PageBanner = ({ imageSrc, stepperList = [], heading }) => {
  return (
    <div
      className={styles['banner-container']}
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}>
      <div className={styles['image-overlay']}>
        <h3>{heading}</h3>
        <div className={styles['stepper-container']}>
          {stepperList.map((el) => {
            return <p key={el}>{el}</p>;
          })}
        </div>
      </div>
    </div>
  );
};
