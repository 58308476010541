import ApiService from 'app/api/api-service';

export const postLogin = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/login`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const fetchWardList = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/get_pincode_details/${args.pincode}`;
  const response = await ApiService.get(url);
  return response.data;
};

export const postSignUp = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/register/user`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const postResetPassword = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/resetpassword`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const postForgotPassword = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/users/forgotpass`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const postValidateEmail = async (args) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/api/validate/email`;
  const response = await ApiService.post(url, args);
  return response.data;
};
