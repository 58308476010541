import { useState } from 'react';
import styles from './article.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  Card,
  CardBody,
  CardHeader,
  ImageUpload,
  StepperList,
  RichTextEditor,
} from 'app/components';
import { useNavigate } from 'react-router-dom';
import { ADD_SERVICE } from 'app/libs/constants/tab-routes';
export const AddArticle = () => {
  const [files, setFiles] = useState([]);
  const [services, setServices] = useState([1]);
  const list = ['Services', 'Environmetal', 'climate change', 'outdoor-plant'];
  const navigate = useNavigate();
  const onDelete = (index) => {
    setFiles((prev) => {
      const newFiles = [
        ...[...prev].slice(0, index),
        ...[...prev].slice(index + 1),
      ];
      return newFiles;
    });
  };
  const AddMore = ({ addMore, label }) => {
    return (
      <p
        className={styles['helper-style']}
        onClick={() => {
          addMore((prev) => {
            return [...prev, prev.length + 1];
          });
        }}>
        + {label}
      </p>
    );
  };
  return (
    <div>
      <CardHeader
        heading={'Add Article'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <Card style={{ marginTop: '40px' }}>
        <CardBody>
          <div className={styles['stepper-container']}>
            <StepperList list={list} />
            <section>
              <Button
                btnType='red'
                Component={EditIcon}
                onClick={() => {
                  navigate(ADD_SERVICE);
                }}>
                Edit
              </Button>
            </section>
          </div>

          <section style={{ paddingRight: '45px' }}>
            <TextInput
              label={'Title'}
              subContent='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor '
              placeholder='Here can be Article Name'></TextInput>
            <RichTextEditor
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              label={'Content'}
              placeholder='Here can be Service Name'></RichTextEditor>
            <RichTextEditor
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              label={'Expert'}
              value={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`}
              placeholder='Enter Specifications'
            />
            <ImageUpload
              label={'Media'}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              onDelete={onDelete}
              imageList={files}
              hideUpload={files.length >= 1}
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const file = e.target.files[0];
                  setFiles((prev) => [...prev, file]);
                }
              }}
            />
            <section>
              <TextInput
                label={'Search Tags'}
                placeholder='Here can be Search Tag'
                subContent={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                }
              />
              {services.map((el, index) => {
                return (
                  <TextInput
                    placeholder='Here can be Search Tag'
                    onDelete={() => {}}
                  />
                );
              })}
              {services.length < 2 && (
                <AddMore label={'Add More'} addMore={setServices} />
              )}
            </section>
            <TextInput
              label={'Transfer To'}
              placeholder='Here can be Transfer To'
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
            />
          </section>
        </CardBody>
        <CardActions>
          <Button btnType='secondary' style={{ marginRight: '14px' }}>
            Cancel
          </Button>
          <Button style={{ marginRight: '14px' }}>Save</Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default AddArticle;
