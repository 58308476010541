import { useState, useEffect } from 'react';
import styles from './manage-initiative-form.module.scss';
import { ReactComponent as EditIcon } from 'app/libs/assests/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/libs/assests/delete.svg';
import { ReactComponent as ReviewIcon } from 'app/libs/assests/Review-icon.svg';
import {
  Button,
  TextInput,
  CardActions,
  CardBody,
  Label,
  TextArea,
  ImageUpload,
  Checkbox,
  TextInputChip,
  TextError,
  ServiceHeader,
  CardHeader,
} from 'app/components';
import { selectDirectoryNames } from 'app/pages/add-service/slice/add-service.selector';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useBasicInfoSlice } from 'app/pages/basic-information/slice/basic-info.slice';
import { LocationInformation } from 'app/pages/basic-information/components/location-info';
import { addressForm1Schema } from 'app/pages/basic-information/model';

export const ManageInitiativeForm = ({ hideHeader = false }) => {
  const dispatch = useDispatch();
  const actions = useBasicInfoSlice().actions;
  const { search } = useLocation();
  const [addressForm, setAddressForm] = useState([{ ...addressForm1Schema }]);
  const [form, setForm] = useState({
    serviceName: { value: '', required: true, error: false },
    specifications: [{ value: '', id: 1, required: true, error: false }],
    files: [],
    services: [],
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [needVolunteerError, setNeedVolunteerError] = useState(false);
  const list = useSelector(selectDirectoryNames);
  const onDelete = (key, id) => {
    setForm((prev) => {
      const form = { ...prev };
      const List = [...form[key]];
      const newList = List.filter((item) => {
        return item.id !== id;
      });
      form[key] = newList;
      return form;
    });
  };
  useEffect(() => {
    dispatch(actions.resetLocationForm());
  }, [actions, dispatch]);
  const handleChange = (value, id, key) => {
    setForm((prev) => {
      const form = { ...prev };
      const specificationList = [...form[key]];
      const newList = specificationList.map((item) => {
        if (item.id === id) {
          return { ...item, value, error: !value };
        }
        return item;
      });
      form[key] = newList;
      return form;
    });
  };
  const AddMore = ({ label, keyName }) => {
    const requiredValues = ['specifications'];
    return (
      <p
        className={styles['helper-style']}
        style={{
          opacity: isDisabled ? 0 : 1,
          cursor: isDisabled ? 'default' : 'pointer',
        }}
        onClick={() => {
          setForm((prev) => {
            const form = { ...prev };
            const newSpecification = {
              value: '',
              id: (form[keyName]?.[form[keyName].length - 1]?.id || 1) + 1,
              required: requiredValues.includes(keyName),
              error: false,
            };
            const List = [...form[keyName], newSpecification];
            form[keyName] = List;
            return form;
          });
        }}>
        + {label}
      </p>
    );
  };
  const validate = () => {
    let isValid = true;
    const newForm = { ...form };
    Object.entries(newForm).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const newData = [...value].map((value) => {
          const data = { ...value };
          if (data.required) {
            data.error = !data.value || data.value.split(' ').length < 5;
            console.log(data.error, data.value.split(' ').length < 5);
            isValid &&= !data.error;
          }
          return data;
        });
        newForm[key] = newData;
      } else {
        const data = { ...value };
        if (data.required) {
          data.error = !data.value || data.value.split(' ').length < 5;
          console.log(data.error);
          newForm[key] = data;
          isValid &&= !newForm[key].error;
        }
      }
    });
    setForm(newForm);
    return isValid;
  };
  const getArrayValue = (data) => {
    return data.map((el) => el.value);
  };

  const validateAddressForm = () => {
    let isValid = true;
    const form = [...addressForm];
    const newForm = form.map((data) => {
      const form = { ...data };
      Object.entries(form).forEach(([key, value]) => {
        const subFields = { ...value };
        if (
          typeof subFields === 'object' &&
          subFields.hasOwnProperty('required')
        ) {
          subFields.error = !subFields?.required || !subFields.value;
          if (key === 'pincode') {
            subFields.error = subFields.value.length !== 6;
          }
          form[key] = subFields;
          isValid &&= !subFields.error;
        }
      });
      return form;
    });
    dispatch(actions.updateLocationForm(newForm));
    return isValid;
  };
  const onSubmit = () => {
    const isValid = validate();
    const isAddressValid = validateAddressForm();
    setNeedVolunteerError((prev) => !checked);
    if (isValid && isAddressValid && checked) {
      const addressFormKeys = [
        'address_line_1',
        'address_line_2',
        'country',
        'state',
        'city',
        'Ward',
        'Landmark',
        'pincode',
        'latitude',
        'longitude',
      ];
      const params = new URLSearchParams(search);
      const address = addressForm.map((data) => {
        const form = {};
        addressFormKeys.forEach((key) => {
          form[key] = data[key]?.value || '';
        });
        return form;
      });
      const formData = new FormData();
      formData.append('initiative_name', form.serviceName.value);
      formData.append(
        'description',
        JSON.stringify(getArrayValue(form.specifications))
      );
      formData.append('category_path_id', params.get('category_id'));
      formData.append('address', address);
      formData.append('search_tag', JSON.stringify(form.services));
      formData.append('need_volunteer', checked);
      // dispatch(actions.addServices({ data: formData }));
    }
  };
  return (
    <>
      <CardHeader
        heading={'Manage Initiative'}
        subHeading={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        helperText={''}
        mainHeader
      />
      <div className={styles['initiative-container']}>
        <CardBody>
          <div className={styles['message-container']}>
            <Button btnType='green'>Approved</Button>
            <div className={styles['status-style']}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's
            </div>
          </div>
          <ServiceHeader list={list} itemName={'name'} itemKey='id' />
          <section style={{ paddingRight: isDisabled ? '0' : '45px' }}>
            <TextInput
              value={form.serviceName.value}
              error={form.serviceName.error}
              errorMessage={'Please enter minimum 5 characters'}
              disabled={isDisabled}
              required
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
              onChange={(event) => {
                setForm((prev) => {
                  const key = 'serviceName';
                  const value = event.target.value;
                  return {
                    ...prev,
                    [key]: { ...prev[key], value, error: !value },
                  };
                });
              }}
              placeholder='Here can be Service Name'></TextInput>
            <section>
              {form.specifications.map((el, index) => {
                const key = 'specifications';
                const props = {
                  value: el.value,
                  placeholder: 'Enter Specifications',
                  errorMessage: 'Please enter minimum 5 characters',
                  error: el.error,
                  onChange: (event) => {
                    handleChange(event.target.value, el.id, key);
                  },
                };
                if (el.id === 1) {
                  props.label = 'Specifications';
                } else {
                  props.onDelete = () => {
                    onDelete(key, el.id);
                  };
                }
                return (
                  <TextArea
                    key={el.id}
                    {...props}
                    disabled={isDisabled}
                    subContent={
                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                    }
                  />
                );
              })}
              {form.specifications.length < 9 && (
                <AddMore
                  label={'Add Specifications'}
                  keyName='specifications'
                />
              )}
            </section>
            <ImageUpload
              disabled={isDisabled}
              label={'Media'}
              onDelete={onDelete}
              imageList={form.files}
              keyName='files'
              hideUpload={form.files.length >= 4}
              fileType='image/*'
              onUpload={(e) => {
                if (e.target?.files?.[0]) {
                  const key = 'files';
                  const file = e.target.files[0];
                  const id =
                    (form?.files?.[form.files.length - 1]?.id || 0) + 1;
                  setForm((prev) => {
                    const newData = { ...prev };
                    const fileData = { value: file, id };
                    const newFlies = [...newData[key]];
                    newFlies.push(fileData);
                    newData[key] = newFlies;
                    return newData;
                  });
                }
              }}
              subContent={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
              }
            />
            <Label textlabel>Location</Label>
            <LocationInformation
              onlyLocation
              disabled={isDisabled}
              addressForm={addressForm}
              setAddressForm={setAddressForm}
              error={[]}
            />
            <Label textlabel>Volunteering</Label>
            <section className={styles['form-container']}>
              <Checkbox
                disabled={isDisabled}
                label='Need Volunteer'
                isChecked={checked}
                onChange={(event, checked) => setChecked(checked)}
              />
            </section>
            <TextError
              error={needVolunteerError}
              errorMessage='Please check Need Volunteer'
            />
            <div style={{ marginBottom: '5px' }}></div>
            <section>
              <TextInputChip
                disabled={isDisabled}
                placeholder='Here can be Search Tag'
                label={'Search Tags'}
                list={form.services}
                onChange={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
                onDelete={(value) => {
                  setForm((prev) => {
                    const key = 'services';
                    return {
                      ...prev,
                      [key]: value,
                    };
                  });
                }}
                subContent={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
                }
              />
            </section>
          </section>
        </CardBody>
        <CardActions>
          <Button
            btnType='secondary'
            onClick={() => {
              setIsDisabled((prev) => !prev);
            }}
            Component={EditIcon}
            style={{ marginRight: '14px' }}>
            Edit
          </Button>
          <Button
            btnType='secondary'
            Component={DeleteIcon}
            style={{ marginRight: '14px' }}>
            Delete
          </Button>
          <Button
            btnType='secondary'
            Component={ReviewIcon}
            onSubmit={onSubmit}>
            Submit For Review
          </Button>
        </CardActions>
      </div>
    </>
  );
};
