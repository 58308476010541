import styles from './announcement.module.scss';
import { ReactComponent as AnnouncementIcon } from 'app/libs/assests/announcement.svg';

export const AnnouncementCard = ({
  heading = 'Sales',
  active,
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
}) => {
  return (
    <div className={styles['container']} data-active={`${active}`}>
      <span>
        <AnnouncementIcon />
      </span>
      <section>
        <h3>{heading}</h3>
        <p>{content}</p>
      </section>
    </div>
  );
};
